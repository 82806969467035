import CardService from "../Shared/CardService";
import ImageVPN from "src/assets/images/services/vpnforex.png";
import ImageWeltrade from "src/assets/images/services/weltrade.jpg";
import ImageNequi from "src/assets/images/services/nequi.jpg";
import ImageDsf from "src/assets/images/services/dsfweb.jpg";
import ImageAcademy from "src/assets/images/services/coursetrading.jpg";
import ImageExchange from "src/assets/images/services/exchange.jpg";
import ImageP2p from "src/assets/images/services/p2p.png";
import ImageTradingChannel from "src/assets/images/services/tradingchannel.png";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { alternativeVisitWhatsapp } from "../Shared/Whatsapp";
/* import { BiSupport } from "react-icons/bi";
import { SiTelegram } from "react-icons/si";
import { visitWhatsapp } from "../Shared/Whatsapp";
import whatsapp from "../../assets/images/whatsapp.png"; */

const OurServices: React.FC = () => {
  /* const { t } = useTranslation(); */
  const {pathname} = useLocation()
  return (
    <div>
      {/*       <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-4 py-2 w-11/12 mx-auto">
        <div className="text-center rounded-md py-8">
          <div className="flex justify-center items-center ">
            <BiSupport className="text-gray-600" size={30} />
          </div>
          <div className="font-normal text-xl mb-7">{t("telegramjoin")}</div>
          <a
            target="_blank"
            href="https://t.me/ISFcambiosadmin"
            className="border border-stone-400 text-black px-4 py-1 font-medium hover:bg-dark-green hover:text-white rounded-sm transition-none text-base"
            rel="noreferrer"
          >
            {t("enter")}
          </a>
        </div>
        <div className="text-center rounded-md py-8">
          <div className="flex justify-center items-center text-sky-500">
            <SiTelegram size={30} />
          </div>
          <div className="font-normal text-xl mb-7"> {t("forexjoin")} </div>
          <a
            target="_blank"
            href="https://t.me/KForexDeriv"
            className="border border-stone-400 text-black px-4 py-1 font-medium hover:bg-dark-green hover:text-white rounded-sm transition-none text-base"
            rel="noreferrer"
          >
            {t("enter")}
          </a>
        </div>
        <div className="text-center rounded-md py-8">
          <div className="flex justify-center items-center">
            <img src={whatsapp} className="w-8" alt="whatsapp"/>
          </div>
          <div className="font-normal text-xl mb-7 cursor-pointer">
            {t("comercialasesor")}
          </div>
          <span
            onClick={() => visitWhatsapp()}
            className="border border-stone-400 text-black px-4 py-1 font-medium hover:bg-dark-green hover:text-white rounded-sm transition-none text-base cursor-pointer"
          >
            {t("chatwithasesor")}
          </span>
        </div>
      </div> */}
      <div className="text-center mt-10 text-lg md:text-3xl font-semibold">
        Nuestros servicios
      </div>
      <div className="mt-12 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  gap-y-4 gap-2 py-2">
        <CardService
          title={"Exchange saldos digitales"}
          image={ImageExchange}
          list={
            <ul className="text-gray-700 text-sm font-medium">
              <li>
                ✔️ <b>Transacciones seguras</b> gracias a tecnología de cifrado avanzada.
              </li>
              <li>
                ✔️ <b>Pagos rápidos y eficientes</b> para que tus fondos estén disponibles en minutos.
              </li>
              <li>✔️<b>Amplia variedad de opciones:</b> tarjetas, cuentas, servicios en línea y más.</li>
              <li>✔️<b>Tasas de cambio competitivas</b> actualizadas con el mercado.</li>
            </ul>
          }
        />

        <CardService
          title={"Academia de trading"}
          image={ImageAcademy}
          list={
            <ul className="text-gray-700 text-sm font-medium">
              <li>
                ✔️ <b>Perfecciona tus habilidades</b> en análisis técnico y fundamental.  
              </li>
              <li>
                ✔️  <b>Domina la gestión de riesgos</b> y fortalece tu mentalidad con técnicas de psicotrading.  
              </li>
              <li>
                ✔️  <b>Aprende directamente de expertos</b> con años de experiencia en el mercado.  
              </li>              
              <li className="mb-4">
                ✔️  <b>Accede a recursos exclusivos y actualizados</b> diseñados para potenciar tu éxito.
              </li>

              <li>
                <Link
                  to={ pathname.includes('/nuestros-servicios') ? "/aprender" : "/academia"}
                  className="bg-red-700 text-white hover:text-white mt-2 font-medium w-fit px-4 py-2 rounded-md "
                >
                  Entrar
                </Link>
              </li>
            </ul>
          }
        />

        <CardService
          title={"Crear cuenta indices sintenticos"}
          image={ImageWeltrade}
          list={
            <ul className="text-gray-700 flex flex-col gap-2 text-sm font-medium">
              <li>✔️ +17 años de experiencia.</li>
              <li>✔️ Broker de Forex e índices Sintéticos.</li>
              <li>
                ✔️ Empieza tu viaje financiero! 📈 ¡Inscríbete y maximiza ganancias!
              </li>
              <li className="mb-8">✔️ El spreads mas mas bajo del mercado   y en el boton registrarse.</li>

              <li>
                <Link
                  to="https://es.gowt.net/ib61380"
                  target="_blank"
                  className="bg-red-700 text-white hover:text-white mt-2 font-medium w-fit px-4 py-2 rounded-md "
                >
                  Entrar
                </Link>
              </li>
            </ul>
          }
        />

        <CardService
          title={"VPN Privada Forex oficial"}
          image={ImageVPN}
          list={
            <ul className="text-gray-700 flex flex-col gap-4 text-sm font-medium">
              <li>
                ✔️ Protege tu identidad y datos personales de hackers y
                terceros.
              </li>
              <li>✔️ Accede a contenido restringido en tu país o región.</li>
              <li className="mb-3">✔️ Navega sin límites y sin rastreo.</li>

              <li>
                <Link
                  to="https://vpnforex.com/"
                  target="_blank"
                  className="bg-red-700 text-white hover:text-white mt-2 font-medium w-fit px-4 py-2 rounded-md "
                >
                  Entrar
                </Link>
              </li>
            </ul>
          }
        />

        <CardService
          title={"DERIV Recarga automática"}
          image={ImageNequi}
          list={
            <ul className="text-gray-700 flex flex-col gap-4 text-sm font-medium">
              <li>✔️ Ahora tus recargas a Deriv son instantáneas.</li>
              <li>✔️ Recarga automáticamente con nuestro bot telegram.</li>
              <li className="mb-3">
                ✔️ Funciona 24/7 , sigue los pasos y paga desde app Nequi.
              </li>
              <li>
                <Link
                  to="https://t.me/tepagoya_bot"
                  target="_blank"
                  className="bg-red-700 text-white hover:text-white mt-2 font-medium w-fit px-4 py-2 rounded-md "
                >
                  Entrar
                </Link>
              </li>
            </ul>
          }
        />

        <CardService
          title={"Desarrolla tu software con nosotros"}
          image={ImageDsf}
          list={
            <ul className="text-gray-700 text-sm font-medium">
              <li>
                ✔️ <b>Soluciones tecnológicas personalizadas</b> para impulsar el crecimiento de tu negocio.  
              </li>
              
              <li>
              ✔️  <b>Aplicaciones móviles</b> creadas por un equipo de expertos en desarrollo de software.  
              </li>
              <li>
              ✔️  <b>Apps web y backoffices en tiempo real</b>, con soporte y acompañamiento en cada etapa.  
              </li>
              
              <li className="mb-3">✔️ <b>Contenido digital completo</b>: creación de videos, reels, diseños y más, para fortalecer tu presencia en línea.</li>

              <li>
                <Link
                  to="https://www.desarrollosinfronteras.com"
                  target="_blank"
                  className="bg-red-700 text-white hover:text-white mt-2 font-medium w-fit px-4 py-2 rounded-md "
                >
                  Entrar
                </Link>
              </li>
            </ul>
          }
        />

        <CardService
          title={
            "Recarga Dolares P2P Deriv con Nuestros perfiles verificados"
          }
          image={ImageP2p}
          list={
            <ul className="text-gray-700 text-sm font-medium">
              <li>
                ✔️ Atencion personalizada
              </li>
              <li>
                ✔️ Los mejores precios del mercado 
              </li>
              <li>
                ✔️ Todos los bancos locales
              </li>
              <li className="mb-3">
                ✔️ Seguridad y confianza
              </li>
              <li>
                <div
                  onClick={() => alternativeVisitWhatsapp() }
                  className="bg-red-700 text-white cursor-pointer hover:text-white mt-2 font-medium w-fit px-4 py-2 rounded-md "
                >
                  Entrar
                </div>
              </li>
            </ul>
          }
        />

        <CardService
          title={"Únete a nuestro canal privado de señales de trading"}
          image={ImageTradingChannel}
          list={
            <ul className="text-gray-700 text-sm font-medium">
              <li>
                ✔️ <b>Rentabilidad optimizada</b> para ayudarte a alcanzar tus metas.
              </li>
              <li>
                ✔️ <b>Gestión de riesgo inteligente</b> para proteger tu inversión.  
              </li>
              <li>
                ✔️ <b>Sesiones en vivo</b> donde aprenderás en tiempo real.  
              </li>
              <li className="mb-3">
                ✔️ <b>Equipo de expertos dedicados</b> a apoyarte en cada paso.
              </li>                            
              <li>
                <Link
                  to="https://t.me/+yBpSO1oLEWo0MzBh"
                  target="_blank"
                  className="bg-red-700 text-white hover:text-white mt-2 font-medium w-fit px-4 py-2 rounded-md "
                >
                  ¡Unirme ahora!
                </Link>
              </li>
            </ul>
          }
        />
      </div>
    </div>
  );
};

export default OurServices;
