
interface AvatarProps {
  name: string | undefined
  styles: string
}
const Avatar = ({name,styles}:AvatarProps) => {
  return (
    <div>
      <div className={`${styles} flex items-center justify-center uppercase bg-dark-green text-white font-bold text-center rounded-4xl`}>
        {name?.split(' ')[0]?.substring(0,1)}{name?.split(' ')[1]?.substring(0,1)}
      </div>
    </div >
  )
}

export default Avatar