import BodyLanding from "../components/Landing/BodyLanding";
import Navbar from "../components/Landing/Navbar";

const Landing = () => {
  return (
    <div>
      <Navbar />
      <BodyLanding />
    </div>
  );
};

export default Landing;
