import { gql } from '@apollo/client';

const FETCH_DEPOSIT_LIST = gql`
  query DepositList($userId: String, $skip: Int, $type: String) {
    depositList(userId: $userId, skip: $skip, type: $type) {
      id
      money
      motive
      paymentPhoto
      percenttodeposit
      destinyProcessor {
        createdAt
        enablewithdraw
        id
        name
        paymentmethod
        photoUrl
        status
        type
        updatedAt
      }       
      systemProcessor {
        createdAt
        enablewithdraw
        id
        name
        paymentmethod
        photoUrl
        status
        type
        updatedAt
      }     
      accountsendstringify
      currency
      createdAt
      comment
      code
      timeinhour
      totaltopay
      updatedAt
      responsePhoto
      status
      usedAcceleration
      user {
        id
        fullname
        email
      }
    }
}
`;

export default FETCH_DEPOSIT_LIST;
