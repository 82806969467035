import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { BsCheckCircle } from "react-icons/bs"
import IconWarning from "src/assets/images/warning.png"
type TParams = {
  response: (r: boolean) => void
}
const AccountCreateConfirm: React.FC<TParams> = ({ response }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative" style={{zIndex:"99999"}} onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
            


                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                  <div className="flex flex-col items-center gap-4">
                    <div className="w-full flex flex-col justify-center items-center ">
                      <img src={IconWarning} className="w-16" alt="alerta" />
                      <div className="font-semibold text-center pb-3 text-xl border-b border-[#ccc]  w-full">
                        {t('important')}
                      </div>
                    </div>
                    <div className="text-center w-full font-medium px-2 mt-4">
                      {t('createaccountquestion1')}
                      <div className="mt-4">
                        {t('createaccountquestion2')}
                      </div>                    
                    </div>
                  </div>

                  <div className="mt-4 flex flex-col items-center gap-3">
                    <button
                      type="button"
                      className="font-semibold inline-flex justify-center rounded-md w-full bg-white  text-dark-green border border-dark-green py-2"
                      onClick={() => response(false)}
                    >
                      {t('cancel')}
                    </button>
                    <button
                      type="button"
                      className="font-semibold inline-flex flex items-center gap-2 justify-center rounded-md w-full bg-dark-green text-white border border-dark-green py-2"
                      onClick={() => response(true)}
                    >
                      <BsCheckCircle size={22} />
                      {t('createmyaccount')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default AccountCreateConfirm