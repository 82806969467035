import React, { useCallback } from "react";
/*import Cookies from "universal-cookie";*/
import APIService from "../services/Api";
import { IAccountForm, IBlog, IDeposit, INotification, Proccesor, UserOnline } from "../types";
import { UseAuth } from "./AuthContext";
import constate from 'constate'
/*const cookies = new Cookies();*/

type AppState = {
  deposits: IDeposit[]
  bankAccounts: IAccountForm[]
  processors: Proccesor[]
  loading: boolean
  usersOnline: UserOnline[]
  currentDollar: string | undefined
  notifications: INotification[]
  adminNotifications: INotification[]
  blogs: IBlog[]
  news: IBlog[]
  paginationAN: number
  paginationUserTransfer: number
}
const initialState: AppState = {
  deposits: [],
  processors: [],
  bankAccounts: [],
  usersOnline: [],
  notifications:[],
  adminNotifications: [],
  blogs: [],
  news: [],
  currentDollar: undefined,
  loading: false,
  paginationAN: 0,
  paginationUserTransfer: 0
}
type Action =
  | {
    type: 'SET_DEPOSITS'
    payload: IDeposit[]
  }
  | {
    type: 'SET_BANK_ACCOUNTS'
    payload: IAccountForm[]
  }
  | {
    type: 'SET_PROCESSORS'
    payload: Proccesor[]
  }
  | {
    type: 'SET_USERS_ONLINE'
    payload: UserOnline[]
  }  
  | {
    type: 'SET_NOTIFICATIONS'
    payload: INotification[]
  }  
  | {
    type: 'SET_ADMIN_NOTIFICATIONS'
    payload: INotification[]
  }      
  | {
    type: 'SET_LOADING'
    payload: boolean
  }
  | {
    type: 'SET_CURRENT_DOLLAR'
    payload: string
  }
  | {
    type: 'SET_BLOGS'
    payload: IBlog[]
  }
  | {
    type: 'SET_NEWS'
    payload: IBlog[]
  }  
  | {
    type: 'SET_PAGINATIONAN'
    payload: number
  }    
  | {
    type: 'SET_PAGINATION_USER_TRANSFER'
    payload: number
  }      



const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case 'SET_DEPOSITS':
      return { ...state, deposits: action.payload }
    case 'SET_BANK_ACCOUNTS':
      return { ...state, bankAccounts: action.payload }
      case 'SET_PROCESSORS':
        return { ...state, processors: action.payload }
        case 'SET_NOTIFICATIONS':
          return { ...state, notifications: action.payload }
          case 'SET_ADMIN_NOTIFICATIONS':
            return { ...state, adminNotifications: action.payload }          
            case 'SET_USERS_ONLINE':
              return { ...state, usersOnline: action.payload }      
              case 'SET_CURRENT_DOLLAR':
                return { ...state, currentDollar: action.payload }
                case 'SET_BLOGS':
                  return { ...state, blogs: action.payload }  
                  case 'SET_NEWS':
                  return { ...state, news: action.payload }    
                  case 'SET_PAGINATIONAN':
                    return { ...state, paginationAN: action.payload }    
                    case 'SET_PAGINATION_USER_TRANSFER':
                      return { ...state, paginationUserTransfer: action.payload }                                              
                    case 'SET_LOADING':
                      return { ...state, loading: action.payload }
  }
}

const useAppState = () => {
  const { user } = UseAuth()
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchBankAccounts = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    const list = await APIService.accountList({ user: user?.id || "" });
    const response = list?.data?.data?.accounts as IAccountForm[];
    dispatch({ type: "SET_BANK_ACCOUNTS", payload: response })
    dispatch({ type: 'SET_LOADING', payload: false })
    //setLoadingList(false);
  }

  const fetchDeposits = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    const list = await APIService.derivListDeposit({ user: user?.id || "" });
    const response = list?.data?.data?.accounts
    dispatch({ type: "SET_DEPOSITS", payload: response })
    dispatch({ type: 'SET_LOADING', payload: false })
  };

  const fetchBlogs = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    const service = await APIService.webBlogList({ type:'blog' })
    
    if(service.data.status) {
      dispatch({ type: "SET_BLOGS", payload: service.data.data.post })
    }
    dispatch({ type: 'SET_LOADING', payload: false })
  };
  const fetchNews = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    const service = await APIService.webBlogList({ type:'notice' })
    
    if(service.data.status) {
      dispatch({ type: "SET_NEWS", payload: service.data.data.post })
    }
    dispatch({ type: 'SET_LOADING', payload: false })
  };  


  const fetchProccesors = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    const list = await APIService.processorList({});
    const response = (list?.data?.data?.list as Proccesor[])
    dispatch({ type: "SET_PROCESSORS", payload: response })
    dispatch({ type: 'SET_LOADING', payload: false })
  }, [dispatch]);

  const adminFetchUsersOnline = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    const list = await APIService.listUserChat({});
    const response = (list?.data?.data?.lastChats as UserOnline[])
    dispatch({ type: "SET_USERS_ONLINE", payload: response })
    dispatch({ type: 'SET_LOADING', payload: false })
  }, [dispatch]);  

  const updateBankAccounts = useCallback(async (bankAccounts: IAccountForm[]) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    dispatch({ type: 'SET_BANK_ACCOUNTS', payload: bankAccounts })
    dispatch({ type: 'SET_LOADING', payload: false })
  }, [dispatch])

  const updateDeposits = useCallback(async (deposits: IDeposit[]) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    dispatch({ type: 'SET_DEPOSITS', payload: deposits })
    dispatch({ type: 'SET_LOADING', payload: false })
  }, [dispatch])

  const updateProcessors = useCallback(async (processors: Proccesor[]) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    dispatch({ type: 'SET_PROCESSORS', payload: processors })
    dispatch({ type: 'SET_LOADING', payload: false })
  }, [dispatch]) 
  
  const fetchCurrentDollar = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    const dolar = await APIService.fetchCurrentDollar({});
    const response = (dolar?.data?.data?.usd?.priceday || 'no disponible')
    dispatch({ type: "SET_CURRENT_DOLLAR", payload: response })
    dispatch({ type: 'SET_LOADING', payload: false })
  }, [dispatch]);

  const setNotifications = useCallback(async (notifications:INotification[]) => {
    dispatch({ type: "SET_NOTIFICATIONS", payload: notifications })
  }, [dispatch]);

  const setAdminNotifications = useCallback(async (adminNotifications:INotification[]) => {
    dispatch({ type: "SET_ADMIN_NOTIFICATIONS", payload: adminNotifications })
  }, [dispatch]);  

  const setPaginationAN = useCallback(async (pag:number) => {
    dispatch({ type: "SET_PAGINATIONAN", payload: pag })
  }, [dispatch]);  


  const setPaginationUserTransfer = useCallback(async (pag:number) => {
    dispatch({ type: "SET_PAGINATION_USER_TRANSFER", payload: pag })
  }, [dispatch]);  

  return {
    ...state,
    fetchBankAccounts,
    fetchDeposits,
    fetchProccesors,
    fetchCurrentDollar,
    adminFetchUsersOnline,
    fetchBlogs,
    fetchNews,
    updateBankAccounts,
    updateProcessors,
    updateDeposits,
    setNotifications,
    setAdminNotifications,
    setPaginationAN,
    setPaginationUserTransfer
  }
}

const [AppStateProvider, useAppStateContext] = constate(useAppState)

export { AppStateProvider, useAppStateContext }
