//
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type depositStatusProps = {
  currentStatus: string
}


const STATUS_CURRENT: any = {
  waiting: "bg-[#2196f3] text-white",
  waitpay: "bg-[#2196f3] text-white",
  checking: "bg-amber-500 text-white",
  cancel: "bg-red-700 text-white",
  completed: "bg-emerald-700 text-white",
  process: "bg-violet-400 text-white",
  refused: "bg-black text-white",
  review: "bg-amber-500 text-white"
}

const ViewDepositStatus = ({ currentStatus }: depositStatusProps) => {
  const { t } = useTranslation();
  const [showStatus, setShowStatus] = useState(currentStatus)

  useEffect(() => {
    setShowStatus(currentStatus)
  }, [currentStatus])
  
  const styleclass = STATUS_CURRENT[showStatus] ? STATUS_CURRENT[showStatus] : 'bg-[#2196f3] text-white'
  return (
    <div className={`${styleclass} rounded-xl font-semibold text-center w-full text-xs md:text-sm px-1`}>
      {t(`depositstatus.${showStatus}`)}
    </div>
  )
}

export default ViewDepositStatus