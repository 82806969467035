import React from "react"
type TParams = {
  name: String
}
const MyNameBubble: React.FC<TParams> = ({ name }) => {
  return (
    <div className={`flex top-1 -left-2 w-5 h-5 items-center justify-center uppercase bg-dark-green text-white font-bold text-center rounded-4xl absolute`}
      style={{ fontSize: ".576rem" }}>
      {name?.split(' ')[0]?.substring(0, 1)}{name?.split(' ')[1]?.substring(0, 1)}
    </div>
  )
}
export default MyNameBubble