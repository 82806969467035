import { useEffect } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { useAppStateContext } from "src/auth/AppStateContext";
import { BsBox } from "react-icons/bs";
import { money } from "src/helpers/mixins";
/*type IMainCarrousel = {
  photoUrl: string;
  title: string;
  description: string;
  link: string;
};*/

const ProcessorCarousel = () => {
  const { t } = useTranslation();
  const { processors, fetchProccesors } = useAppStateContext();

  useEffect(() => {
    if ((processors?.length || 0) === 0) {
      fetchProccesors();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processors]);

  const getRandomMoney = () => {
    let ranMoney = (Math.random() * (1000 - 10) + 10).toString();
    ranMoney = ranMoney.split(".")[0];
    return money(ranMoney);
  };
  const settings = {
    dots: false,
    arrows: true,
    edgeFriction: 0.35,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1014,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <section className="w-full border-t border-dashed px-0 md:px-10  border-gray-400 pt-4">
      <div className="text-center my-6 text-lg font-bold text-black text-base w-fit mx-auto">
        {t("lastmovementusd")}
      </div>
      <div>
        <Slider className="w-11/12 mx-auto" {...settings}>
          {processors
            .filter((p) => p.status === "active")
            .map((p, key) => (
              <div className="px-4" key={key.toString()}>
                <div className="w-full bg-white rounded-lg overflow-hidden shadow-lg">
                  {!p?.photoUrl?.includes("empty") ? (
                    <img
                      alt="procesador"
                      className="w-auto my-8 h-20 mx-auto rounded-md"
                      src={`${process.env.REACT_APP_PROCESSOR_IMAGE}${p.photoUrl}`}
                    />
                  ) : (
                    <BsBox className="w-6 h-6 text-dark-green" />
                  )}

                  <div className="px-6 py-4">
                    <div className="font-medium text-base mb-2 text-center ">
                    {p?.photoUrl?.includes("empty") && (p.name)}  
                    </div>
                    <p className="text-gray-700 mb-5 font-semibold text-center my-10 text-base">
                      ${getRandomMoney()},00 USD
                    </p>
                  </div>
                </div>

                {/*<div className="bgbanner-processor  mt-4 text-lg"
                key={key}
                >
                  <div className="bg-decorate  w-full mx-auto flex flex-col items-center justify-between py-6 px-2">
                    <div className="w-fit mx-auto text-white font-medium text-xl text-center">
                      {p.name}
                    </div>
                    {!p?.photoUrl?.includes("empty") ?
                      <img
                        alt="photourl"
                        className="w-auto h-16 rounded-md" src={`${process.env.REACT_APP_PROCESSOR_IMAGE}${p.photoUrl}`} />
                      :
                      <BsBox className="w-6 h-6 text-dark-green" />
                    }  
                    <div className="moneyusdprocessor">
                      ${getRandomMoney()} USD 
                    </div>

                  </div>
              </div>*/}
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default ProcessorCarousel;
