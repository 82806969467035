import { gql } from '@apollo/client'


const FETCH_CHAT_USER_ONLINE = gql`
  query ChatUserOnline {
    ChatUserOnline {
      id
      fullname
      email
      isOnline
    }
  }
`

export default FETCH_CHAT_USER_ONLINE