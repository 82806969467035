import { useLocation, useNavigate } from 'react-router-dom';
import React, {  useEffect, useState } from "react"
import { UseAuth } from "../auth/AuthContext"
import NavUser from "../components/System/NavUser"
import Sidebar from "../components/System/Sidebar"
import { GoHome } from 'react-icons/go';
import { RiMenu3Fill, RiCloseLine } from "react-icons/ri";
import ChatClient from 'src/components/Chat/ChatClient';
import { GiPayMoney, GiReceiveMoney,  } from 'react-icons/gi'
import { FaExchangeAlt } from 'react-icons/fa';
import ChatAdmin from 'src/components/Admin/ChatAdmin';
import { getLogout } from 'src/helpers/mixins';
import process from "process"
import TermsnConditionUpdate from 'src/components/System/TermsnNConditionUpdate';

//@ts-ignore-comment

interface LayoutOptions {
  children: React.ReactElement
}

const Layout = ({ children }: LayoutOptions) => {
  const { user, setUserData } = UseAuth()
  //const token = localStorage.getItem('authToken')
  const navigate = useNavigate()
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const {pathname} = useLocation()
  useEffect(() => {
    setShowMenu(false)
  }, [pathname])
  useEffect(() => {
    if(showMenu){
      window.scrollTo(0, 0);
    }
  }, [showMenu])
  /*useEffect(() => {
    if (!isAuthenticated || !token) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, [isAuthenticated])*/


  useEffect(() => {
    // 60 seg * 10 = 600 seg => 10 min
    /*60 * 10*/
    const TIME_INACTIVO = 2400
    let n = TIME_INACTIVO
    const interval = setInterval(() => {
      window.onmousemove = function () {
        n = TIME_INACTIVO
      }
      n--
      if (n < 0) { 
        getLogout()
        navigate('/');
        return () => clearInterval(interval);
      }
    }, 1000)
    //console.log('n', n)
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
      
    const version = process.env.REACT_APP_VERSION
    if(version){
      if(localStorage.getItem('isf_version')?.toString() !== version.toString()){
        localStorage.setItem('isf_version',version)
        window.location.reload()
      }
    }
  }, [])
  

  return (
    <div className="min-h-screen flex-col bg-gray-100 text-gray-800">
      <NavUser />
      <div className="w-full md:w-11/12 md:mx-auto flex flex-row md:gap-4 flex-grow overflow-hidden mt-5">
        <div className='w-full absolute md:w-fit md:relative' style={{zIndex:""}}>
          <Sidebar closeMenu={() => setShowMenu(false)} showMenu={showMenu} /></div>
        {/*Movil*/}
        <nav 
        style={{background:'linear-gradient(181deg, #009055, #023b24)',zIndex: 901}}
        className=" border-gray-100 md:hidden text-white fixed w-full bottom-0 left-0 text-2xl text-green-logo px-8 flex items-center justify-between">
          <button className="p-2 text-white cursor-pointer">
            <GoHome onClick={() => navigate('/inicio')} />
          </button>
          <button className="p-2 text-white cursor-pointer">
            <FaExchangeAlt onClick={() => navigate('/transferir')} />
          </button>
          <button onClick={() => setShowMenu(!showMenu)} className="cursor-pointer p-2 text-white">
            {showMenu ? <RiCloseLine /> : <RiMenu3Fill />}
          </button>
        </nav>
        {!showMenu &&
          <div className="w-full md:w-11/12 h-full px-2 md:px-6 py-2 overflow-auto mx-auto mt-10 mb-32 rounded-md">
            {children}
          </div>
        }
        {/*!user?.termsAndCondition && <TermsnConditionUpdate />*/}
      </div>
        {(user?.role || 'client' ) === 'admin'
          ?
          <ChatAdmin />      
          :
          <ChatClient />
        }
    </div>
  )
}

export default Layout
