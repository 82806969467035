import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { IoIosSend } from "react-icons/io";
import useQueryMessageList from "src/hooks/chat/useQueryMessageList";
import { classNames, getHourDate } from "../../helpers/mixins";
import APIService from "../../services/Api";
import Avatar from "../System/Avatar";
import MyNameBubble from "./MyNameBubble";
import { useNavigate } from "react-router-dom";

const ChatPersonMessage = ({ selectedPerson, setShowChat }: any) => {
  const navigation = useNavigate()
  const { messageList, loading } = useQueryMessageList(
    get(selectedPerson, "id", "0"),
    "admin"
  );
  const divRef = useRef<null | HTMLDivElement>(null);
  const { t } = useTranslation();
  const [inputMessage, setInputMessage] = useState<string>("");
  const [localLoading, setLocalLoading] = useState<boolean>(false);

  useEffect(() => {
    divRef?.current?.scrollIntoView({ behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageList]);

  const sendMessage = async () => {
    if (inputMessage && inputMessage.trim().length > 0) {
      setInputMessage("");
      setLocalLoading(true);
      await APIService.chatCreateMessage({
        userorigen: "admin",
        userdestiny: get(selectedPerson, "id", "0"),
        message: inputMessage,
      });
      setLocalLoading(false);
      divRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleVisitProfile = () => {
    const x = get(selectedPerson, "id", "0")
    console.log('selectedPerson ',x)
    navigation(`/admin/usuario/detalle/${x}`)
  }
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputMessage(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  return (
    <>
      <div className="chatcontainer-person z-10">
        <div
        
          className="chatcontainer-head font-semibold bg-emerald-600 text-white flex items-center justify-between px-4"
        >
          <div>
            <div className="flex items-center gap-x-1">
              <Avatar
                name={get(selectedPerson, "fullname", "")}
                styles={"w-6 h-6 text-xs cursor-pointer"}
              />
              <span onClick={() => handleVisitProfile()} className="cursor-pointer hover:underline">
                {get(selectedPerson, 'fullname', '').toString().split('|*|*|*|')[0].substring(0,20)}
              </span>
            </div>
          </div>
          <span   onClick={() => setShowChat(false)}
           className="font-semibold cursor-pointer">X</span>
        </div>
        <div className="chatcontainer-body text-sm">
          {!loading ? (
            messageList.map((m: any, key: number) => (
              <div
                key={key}
                className={classNames(
                  m.userOrigenId !== "admin"
                    ? "msg-leader"
                    : "msg-user items-end ",
                  "pb-1 flex flex-col text-left justify-start "
                )}
              >
                <div
                  className={classNames(
                    "pl-4 msg bg-gray-300 px-1 py-1 rounded-lg relative"
                  )}
                >
                  {m.userOrigenId === "admin" ? (
                    <MyNameBubble name="AGENTE PAGOS" />
                  ) : (
                    <MyNameBubble name={get(selectedPerson, "fullname", "")} />
                  )}
                  {m.message}
                </div>
                {key+1 === messageList.length &&
                  <span className="msg-date">
                    {moment
                      .utc(parseInt(m.createdAt, 10))
                      .local()
                      .format("YYYY-MM-DD")}{" "}
                      {getHourDate(m.createdAt)}
                    {/*moment
                      .utc(parseInt(m.createdAt, 10))
                      .local()
                    .format("HH:MM:SS")*/}
                  </span>
                }
              </div>
            ))
          ) : (
            <Fragment>
              <div className="msg-leader w-full  pb-1 flex flex-col text-left justify-start  animate-pulse">
                {Array.from(new Array(Math.floor(Math.random() * 4))).map(
                  (i) => (
                    <div className="px-1 py-1 rounded-lg w-full">
                      <div className="h-7 bg-gray-200 rounded-lg dark:bg-gray-400 w-8/12"></div>
                    </div>
                  )
                )}
              </div>
              <div className="msg-user w-full pb-1 flex flex-col text-left justify-end  animate-pulse">
                {Array.from(new Array(Math.floor(Math.random() * 4))).map(
                  (i) => (
                    <div className="px-1 flex justify-end py-1 rounded-lg">
                      <div className="h-7 bg-gray-200 rounded-lg dark:bg-gray-400 w-8/12"></div>
                    </div>
                  )
                )}
              </div>
              <div className="msg-leader w-full  pb-1 flex flex-col text-left justify-start  animate-pulse">
                {Array.from(new Array(Math.floor(Math.random() * 4))).map(
                  (i) => (
                    <div className="px-1 py-1 rounded-lg">
                      <div className="h-7 bg-gray-200 rounded-lg dark:bg-gray-400 w-8/12"></div>
                    </div>
                  )
                )}
              </div>
            </Fragment>
          )}
          <div ref={divRef} />
          {/*<div className='msg-user pb-1 flex text-left justify-end '>
            <div className="msg bg-gray-300 px-1 py-1 rounded-lg">
              Gracias por la respuesta
            </div>
          </div>*/}
        </div>
        <div className="chatcontainer-input flex items-center">
          <input
            type="text"
            onChange={handleInput}
            onKeyDown={handleKeyDown}
            value={inputMessage}
            placeholder={t("chatplaceholder")}
          />
          <button
            onClick={!localLoading ? () => sendMessage() : () => null}
            className="bg-gray-300 cursor-pointer flex items-center justify-center"
          >
            <IoIosSend size={27} />
          </button>
          {/*!localLoading ?
            <button onClick={() => sendMessage()} className='bg-gray-300 cursor-pointer flex items-center justify-center'>
              <IoIosSend size={27} />
            </button>
            :
            <button className='bg-gray-300 cursor-pointer flex items-center justify-center'>
              <CgSpinner size={27} className="animate-spin" />
            </button>
          */}
        </div>
      </div>
    </>
  );
};

export default ChatPersonMessage;
