import { useState } from "react"
import es from "../assets/images/es.png"
import en from "../assets/images/en.png"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
const Language = () => {
  const location = useLocation();
 
  const [show, setShow] = useState<boolean>(false)
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState<string>(localStorage.getItem('lang') || 'es')
  const updateLang = (lang: string) => {
    localStorage.setItem('lang',lang)
    setCurrentLang(lang)
    i18n.changeLanguage(lang)
    if(location.pathname==='/'){
      window.location.reload();
    }
    
  }

  window.addEventListener("click", (e:MouseEvent) => {
    const event = e.target  as HTMLElement
    if(!event?.id.includes('dropdown-lang-trigger')){
      setShow(false)
    }
  });
  return (

    <div className="text-xl relative" >
      <button
        id="dropdown-lang-trigger1"
        onClick={() => setShow(!show)}
        data-dropdown-toggle="dropdown"
        className="text-gray-dark button-no-outline hover:bg-green-logo focus:ring-2  font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
        type="button"
      >
        {currentLang === 'es' &&
          <img alt="flag" className="w-8 h-8" id="dropdown-lang-trigger2" src={es} />
        }
        {currentLang === 'en' &&
          <img alt="flag" className="w-8 h-8" id="dropdown-lang-trigger2" src={en} />
        }
        <svg
          className="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          id="dropdown-lang-trigger3"
        >
          <path
            stroke-line-cap="round"
            stroke-line-join="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>
      {show && 
        <div
          className="dropdown-lang z-10 bg-white divide-y divide-gray-100 rounded shadow w-fit "
        >
          <ul
            className="py-1 text-sm text-gray-dark"
            aria-labelledby="dropdownDefault"
          >
            <li
              onClick={() => updateLang('es')}
              className="flex aling-center cursor-pointer hover:bg-green-logo"
            >
              <img alt="flag" className="w-8 h-8 mr-2" src={es} /> ESP
            </li>
            <li
              onClick={() => updateLang('en')}
              className="flex aling-center cursor-pointer hover:bg-green-logo"
            >
              <img alt="flag" className="w-8 h-8 mr-2" src={en} /> ENG
            </li>
          </ul>
        </div>
      }
    </div>
  )
}

export default Language