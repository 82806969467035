import React, { useContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { IUserFields } from "../types";
const cookies = new Cookies();

export interface AuthContextInterface {
  token: string | null;
  expiresAt: number | null;
  isAuthenticated: boolean;
  user: IUserFields | null;
  activateAccount: () => void;
  setUserData: (
    id: string,
    email: string,
    fullname: string,
    phone: string,
    country: string,
    birthday: string,
    verifyAccount: boolean,
    role: string,
    verifiedIdentity: boolean,
    termsAndCondition: boolean
  ) => void;
  logout: () => void;
}

export const authContextDefaults: AuthContextInterface = {
  expiresAt: null,
  token: null,
  isAuthenticated: cookies.get("isAuthenticated") || false,
  user: cookies.get("userdata") || null,
  setUserData: () => null,
  logout: () => null,
  activateAccount: () => null
};
interface AuthProviderOptions {
  children: React.ReactElement;
}

export const AuthProvider = ({ children }: AuthProviderOptions) => {
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [expiresAt, setExpiresAt] = useState(null);
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<IUserFields | null>(
    cookies.get("userdata") || null
  );
  
  const logout = () => {
    cookies.remove("userdata");
    cookies.remove("isAuthenticated");
    localStorage.removeItem("authToken");
    window.setTimeout(() => {
      cookies.remove("userdata");
      cookies.remove("isAuthenticated");
      localStorage.removeItem("authToken");
      window.location.href='/login'
      window.location.href='/login'
      window.location.replace("/login");
    },1000)
    window.setTimeout(() => {
      cookies.remove("userdata");
      cookies.remove("isAuthenticated");
      localStorage.removeItem("authToken");
      window.location.href='/login'
      window.location.href='/login'
      window.location.replace("/login");
    },1500)
  };

  useEffect(() => {
    if(localStorage.getItem("authToken") && cookies.get("isAuthenticated") ){
      const token = localStorage.getItem("authToken");
      const isAuth = cookies.get("isAuthenticated")
      setisAuthenticated(token && isAuth)
    }
  }, [])

  const setUserData = (
    id: string,
    email: string,
    fullname: string,
    phone: string,
    country: string,
    birthday: string,
    verifyAccount: boolean,
    role: string,
    verifiedIdentity: boolean,
    termsAndCondition: boolean
  ) => {
    cookies.set("userdata", {
      id,
      email,
      fullname,
      phone,
      country,
      birthday,
      verifyAccount,
      role,
      verifiedIdentity,
      termsAndCondition
    },{path: '/'});

    cookies.set("isAuthenticated", true, {path: '/'} );
  
    setisAuthenticated(true);
    setToken('');
    setUser({
      id,
      email,
      fullname,
      phone,
      country,
      birthday,
      verifyAccount,
      role,
      verifiedIdentity,
      termsAndCondition
    });
  };
  const activateAccount = () => {
    if(isAuthenticated){
      setUserData(
        user?.id || '',
        user?.email || '',
        user?.fullname || '',
        user?.phone || '',
        user?.country || '',
        user?.birthday || '',
        true,
        user?.role || 'user',
        user?.verifiedIdentity || false,
        user?.termsAndCondition || false
      )
    }
  };
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated:cookies.get('isAuthenticated') || false,
        user,
        expiresAt,
        token,
        setUserData,
        logout,
        activateAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const AuthContext =
  React.createContext<AuthContextInterface>(authContextDefaults);

export const UseAuth = () => useContext(AuthContext);
