//
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import FETCH_DEPOSIT_LIST from "src/graphql/query/depositList";



const useQueryListDeposit = (userId: string, skip: number, type: string) => {
  const { data = { }, loading } = useQuery(FETCH_DEPOSIT_LIST, {
    variables: {
      userId,
      skip: skip*10,
      type
    },
    fetchPolicy: "no-cache",
    pollInterval: 10000
  }); 
  
  const depositList = useMemo(() => {
        const { depositList = null } = data
        return depositList ? depositList : []
      }, [data])
 
  return { depositList, loading }
}

export default useQueryListDeposit
