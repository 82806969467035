
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaExchangeAlt } from 'react-icons/fa';
import { GoHome } from 'react-icons/go';
import { AiOutlineUser } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MdOutlineLogout, MdOutlineSecurity, MdOutlineSendToMobile } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { UseAuth } from 'src/auth/AuthContext';
import Confirmation from '../Shared/Confirmation';
import { BsFillKeyFill } from 'react-icons/bs';
import { RiBankCardFill } from 'react-icons/ri';
import { classNames, validateRouteRole } from 'src/helpers/mixins';
import { GiPodiumWinner } from 'react-icons/gi';
import { IoPlayCircleSharp } from 'react-icons/io5';
import { SiSololearn } from 'react-icons/si';
import { BiTransfer } from 'react-icons/bi';

interface Props {
  showMenu: boolean;
  closeMenu:() => void
}


const SideBar = ({ showMenu, closeMenu }: Props) => {

  const { logout, user } = UseAuth()
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const location = useLocation();
  const pathName = location.pathname.toString()

  const role = user?.role || "user";
  const enableRoutes = validateRouteRole(role);
  
  const handleLogout = () => {
    logout()
  }
  return (
    <div className={`${!showMenu && 'hidden md:block'} w-full md:w-64 flex-shrink items-center flex-grow-0 min-h-screen`} style={{ zIndex: 900 }}>
      <div className={'relative   rounded-2xl shadow-md h-full top-0 md:my-4 transition-all'} >
        <ul className='flex flex-col overflow-hidden content-center bg-[#fff] md:fixed mt-6 cursor-pointer md:mt-14 justify-between text-center'>    
        <li className='block md:hidden'>
          <div onClick={() => closeMenu()}  className=' bg-[#f3f4f6] flex flex-col items-center justify-center w-full text-black border-b border-[#2f463f] py-2 px-1 w-64 font-light'  >
            <div className='flex flex-wrap items-center text-xs font-semibold text-lg text-center gap-6'>
              <FaArrowLeft size={27} /> {t('exitmenu')}
            </div>
          </div>
        </li>        
          <LiDev 
            name={t('sidebar.home')} 
            to="/inicio"
            pathName={pathName}
            Icon={<><GoHome size={20} className='text-stone-500' /></>}
          />
   <LiDev 
            name={t('academytext')} 
            to="/aprender"
            pathName={pathName}
            Icon={<><SiSololearn size={20} className='text-stone-500' /></>}
          />     
          

        <LiDev 
            name={t('transfer')} 
            to="/transferir"
            pathName={pathName}
            Icon={<><BiTransfer size={20} className='text-stone-500' /></>}
          />     
          
                                       
          <LiDev 
            name={t('sidebar.profile')} 
            to="/perfil"
            pathName={pathName}
            Icon={<><AiOutlineUser size={20} className='text-stone-500' /></>}
          />
    
        
          <LiDev 
            name={t('sidebar.account')} 
            to="/mis-cuentas"
            pathName={pathName}
            Icon={<><RiBankCardFill size={20} className='text-stone-500' /></>}
          />                   
          {/*<LiDev 
            name={t('sidebar.academy')} 
            to="/aprender"
            pathName={pathName}
            Icon={<><FaGraduationCap size={20} className='text-stone-500' /></>}
          /> */}     
        
        <LiDev 
            name={'Servicios'} 
            to="/nuestros-servicios"
            pathName={pathName}
            Icon={<><MdOutlineSendToMobile size={20} className='text-stone-500' /></>}
          />        
          <LiDev 
            name={t('tournament')} 
            to="https://torneosinfronteras.com?origen=isf"
            target="_blank"
            pathName={pathName}
            Icon={<><GiPodiumWinner size={20} className='text-stone-500' /></>}
          />  
 
        <LiDev 
            name={t('sidebar.security')} 
            to="/seguridad"
            pathName={pathName}
            Icon={<><MdOutlineSecurity size={20} className='text-stone-500' /></>}
          />
                   
{/*           <LiDev 
            name={t('sidebar.support')} 
            to="/atencion-al-cliente"
            pathName={pathName}
            Icon={<><IoLogoWhatsapp size={20} className='text-stone-500' /></>}
          /> */}
          {(enableRoutes.includes("*") || enableRoutes.includes("/admin")) && (
            <LiDev 
              name={t('sidebar.admin')} 
              to="/admin"
              pathName={pathName}
              Icon={<><BsFillKeyFill size={20} className='text-stone-500' /></>}
            />
           )}

          <li>
            <Link  onClick={() => setShowConfirmation(true)}  to={''}
            className={
               classNames(
                 (pathName.toString()).includes('/0000') ? 
                 'bg-[#f3f4f6] border-l-green-admin-light' :
                  'transition-all bg-transparent border-l-transparent',
                  'border-l-8 flex md:flex-row items-center justify-start text-black py-4 px-8 cursor-pointer font-semibold w-full md:w-64'
                )}
             >
              <div className='flex flex-wrap flex-col text-black items-center text-xs text-center'>
                <MdOutlineLogout size={20} className='text-stone-500' />
              </div>
              <span className="text-base tracking-widest  px-4	md:tracking-normal md:text-sm text-black">{t('exit')}</span>
            </Link>
          </li>
        </ul>
      </div>
        <Confirmation show={showConfirmation} cancel={()=>setShowConfirmation(false)} confirm={() => handleLogout()} />
    </div>
  )
}
export default SideBar

const LiDev = ({ name, to, Icon,pathName,target }: any) => {

  return (
    <li>
      <Link 
      target={target ? target : null}
      className={ classNames( (pathName.toString()).includes(to) ? 'bg-[#f3f4f6] border-l-green-admin-light' : 'transition-all md:bg-transparent border-l-transparent','border-l-8 flex md:flex-row items-center justify-start  text-black py-4 px-8 cursor-pointer font-semibold w-full md:w-64')}
       to={to} >
        <div className='flex flex-wrap flex-col text-black items-center text-xs text-center'>
          {Icon} 
        </div>
        <span className="text-base tracking-widest  px-4	md:tracking-normal md:text-sm text-black">{name}</span>
      </Link>
    </li>
  )
}