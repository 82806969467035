import { get } from "lodash"
import React, { useState } from "react"
import ChatPersonMessage from "./ChatPersonMessage"

type IChatPerson = {
  selectedPerson: any
  unselectPerson: () => void
}
const ChatPerson: React.FC<IChatPerson> = ({ selectedPerson, unselectPerson }) => {
    const [showChat, setShowChat] = useState(true)

    /*useEffect(() => {
      if(!showChat){
        unselectPerson()
      }
    }, [showChat])*/
    
    return (
      <div>
        {!showChat ?
          <div
            onClick={() => setShowChat(true)}
            className='chatbox-person flex justify-between  w-full font-bold flex items-center gap-x-1 z-10'>
              {get(selectedPerson, 'fullname', '').toString().split('|*|*|*|')[0].substring(0,20)}
            <div title="Cerrar" className="cursor-pointer bg-gray-300 px-2 py-0.5 font-semibold rounded-sm "
            onClick={() => unselectPerson()}>X</div>
          </div>
          : (
            <>
              {showChat && (
                <ChatPersonMessage selectedPerson={selectedPerson} setShowChat={setShowChat} />
              )}
            </>
          )
        }
      </div>
    )
  }
export default ChatPerson