//
import { useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react"
import { HiOutlineStatusOnline } from 'react-icons/hi';
import IS_ONLINE_UPDATED from "src/graphql/mutation/isOnlineUpdated";
import Line from "./Line";
import { useTranslation } from "react-i18next";

const ChatClient = () => {
  const divRef = useRef<null | HTMLDivElement>(null)
  const [showChat, setShowChat] = useState<boolean>(false)
  const [IsOnlineUpdated] = useMutation(IS_ONLINE_UPDATED)
  const { t } = useTranslation()
  useEffect(() => {
    IsOnlineUpdated({ variables: { status: showChat }})
  }, [showChat])
  
  return (
    <div className="relative" style={{ zIndex: 902}}>
    {!showChat ?
      <div
        onClick={() => setShowChat(true)}
        className='chatbox bottom-[2.5rem] right-5 md:bottom-0 flex items-center gap-x-1'>
        <HiOutlineStatusOnline size={24} className="text-green-logo" />
        {t('online')}
      </div>
      :
     (
      <>
        <Line divRef={divRef} setClose={() => setShowChat(false)} />
      </>
     )
    }
  </div>
  )
}

export default ChatClient