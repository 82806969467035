//
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useAppStateContext } from 'src/auth/AppStateContext';
import FETCH_NOTIFICATIONS_ADMIN_LIST from 'src/graphql/query/adminNotificationList';
import { INotification } from 'src/types';

const useQueryListAdminNotification = (skip: number) => {
  const { setAdminNotifications } = useAppStateContext()
  const { data = { }, loading } = useQuery(FETCH_NOTIFICATIONS_ADMIN_LIST, {
    variables: {
      skip: skip*10
    },
    fetchPolicy: "no-cache",
    pollInterval: 10000
  }); 
  const notificationAdminList = useMemo(() => {
      if(data && (data?.adminNotificationList?.length || 0) > 0){
        setAdminNotifications(data.adminNotificationList)
      }
      const { notificationAdminList = null } = data
      return (notificationAdminList ? notificationAdminList : [] )as INotification[]
    }, [data])
  return { notificationAdminList, loading }
}

export default useQueryListAdminNotification
