import { Skeleton } from "antd";

const SkeletonDepositDetail: React.FC = () => {
  return (
    <div className="mt-10 w-full">
      <div className="bg-white rounded-md px-4 py-8 w-full md:w-10/12 mx-auto relative -top-24 rounded-t-xl">
        <div className="text-lg pb-2 border-b border-gray-300 border-dashed mb-2 font-medium flex items-center gap-x-2">
          <div className="w-full">
            <Skeleton active={true} paragraph={{ rows: 1 }} />
          </div>
        </div>

        <div className="grid grid-cols-2 justify-between gap-x-14 gap-y-2 items-center border-b border-gray-300 pb-3">
          <div className="w-full grid-col-1">
            <Skeleton active={true} paragraph={{ rows: 2 }} />
          </div>
          <div className="font-semibold text-base flex items-center mt-2 gap-2">
            <div className="w-full grid-col-1">
              <Skeleton active={true} paragraph={{ rows: 1 }} />
            </div>
          </div>
        </div>

 
        <div className="grid grid-cols-2  justify-between gap-x-14 gap-y-2 items-center border-b border-gray-300 pb-3">
          <div className="w-full grid-col-1">
            <Skeleton active={true} paragraph={{ rows: 1 }} />
          </div>
          <div className="font-semibold text-base flex items-center mt-2 gap-2">
            <div className="w-full grid-col-1">
              <Skeleton active={true} paragraph={{ rows: 2 }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SkeletonDepositDetail;
