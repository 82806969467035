import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useAppStateContext } from "src/auth/AppStateContext";
import { DepositForm, IAccountForm, IDeposit } from "src/types";
import { useForm } from "react-hook-form";
import process from "process";
import APIService from "src/services/Api";
import { UseAuth } from "src/auth/AuthContext";
import FormRequired from "src/components/Shared/FormRequired";
import { money, selectStyle } from "src/helpers/mixins";
import DepositConfirm from "src/components/Deposit/DepositConfirm";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import NeedVerifyAccount from "src/components/Shared/NeedVerifyAccount";
import { ToastContainer } from "react-toastify";
import Notify from "src/toast/Notify";
import ProcessorCarousel from "src/components/Shared/ProcessorCarousel";
import { BiDollar } from "react-icons/bi";
import NeedVerifyIdentitied from "src/components/Shared/NeedVerifyIdentitied";
import DialogCreateAccount from "src/components/Accounts/DialogCreateAccount";
import { IoAlertCircleOutline } from "react-icons/io5";
import DialogInfoGeneral from "src/components/Shared/DialogInfoGeneral";
type ISelect = {
  value: string | undefined;
  label: string;
  image: string;
};

const DepositNew = () => {
  const { t } = useTranslation();
  const { user } = UseAuth();
  const navigate = useNavigate();
  const selectRef = React.useRef<any>(null);
  const formRef = React.useRef<HTMLFormElement>(null);
  const {
    deposits,
    updateDeposits,
    fetchProccesors,
    processors,
    currentDollar,
  } = useAppStateContext();

  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [userAccountList, setUserAccountList] = useState<IAccountForm[]>([]);
  const [totalToPay, setTotalToPay] = useState<string>();
  const [notRange, setNotRange] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [inactiveUser, setInactiveUser] = useState<boolean>(false);
  const [formIsSent, setFormIsSent] = useState<boolean>(false);
  const [loadingCalculate, setLoadingCalculate] = useState<boolean>(false);
  const [enableAccountSend, setEnableAccountSend] = useState<boolean>(false);
  const [accountFind, setAccountFind] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [viewConfirm, setViewConfirm] = useState<boolean>(false);
  const [needValidate, setNeedValidate] = useState<boolean>(false);
  const [needReCalculate, setNeedReCalculate] = useState<boolean>(false);
  const [optionsDestiny, setOptionsDestiny] = useState<ISelect[]>([]);
  const [useDestiny, setUseDestiny] = useState<string>("");
  const [isEnableOrigen, setIsEnableOrigen] = useState<boolean>(true);
  const [isEnableDestiny, setIsEnableDestiny] = useState<boolean>(true);
  const [previewValue, setPreviewValue] = useState<number>();
  const [maxTransferReached, setMaxTransferReached] = useState<boolean>(false)
  const [canGo, setCanGo] = useState(true);
  const [isOpenDialogAccount, setIsOpenDialogAccount] = useState<boolean>(false)
  const callChecked = async () => {
    const res = await APIService.userCheckVerifiedIdentity({
      userId: user?.id || "-",
    });
    setCanGo(res?.data?.data?.canGo || false);
  };

  useEffect(() => {
      setInterval(async () => {
        await callChecked();
      }, 9000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    handleSubmit,
    setValue,
    resetField,
    getValues,
    formState: { errors },
  } = useForm<DepositForm>();

  useEffect(() => {
    fetchProccesors();
    fetchListAccounts();
    const optionsDestiny2 = processors
      .filter((p) => p.status === "active")
      .map((p) => ({
        value: p.id,
        label: p.name,
        image: `${process.env.REACT_APP_PROCESSOR_IMAGE}${p.photoUrl}`,
      }));
    setOptionsDestiny(optionsDestiny2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  }, [needReCalculate]);

  const findIsEnable = (processorId: string, type: string) => {
    const find = processors.find((p) => p.id === processorId);
    if (type === "origen") {
      if (find && find.paymentmethod) {
        return true;
      } else {
        return false;
      }
    } else {
      if (find && find.enablewithdraw) {
        return true;
      } else {
        return false;
      }
    }
  };
//#region	diablo

  const validateDestinyAndOrigen = () => {
    const validateOrigen = findIsEnable(getValues("processor"), "origen");
    const validateDestiny = findIsEnable(getValues("destiny"), "destiny");
    if (!validateOrigen) {
      setIsEnableOrigen(false);
    } else {
      setIsEnableOrigen(true);
    }
    if (!validateDestiny) {
      setIsEnableDestiny(false);
    } else {
      setIsEnableDestiny(true);
    }
  };
//#endregion

  /*listar cuentas bancarias del usuario */
  const fetchListAccounts = async () => {
    setFetchLoading(true);
    const list = await APIService.accountList({ user: user?.id || "" });
    const userAccounts = list?.data?.data?.accounts as IAccountForm[];
    setUserAccountList(userAccounts);
    setFetchLoading(false);
  };
  /*const getTypeAccountSend = () => {
    console.log('print accountsendstringify')
    console.log(processors.find(p => p.id === (getValues('accountsend' || '')))?.id || undefined)
    return processors.find(p => p.id === (getValues('accountsend' || '')))?.id
  }*/

  const getFiatName = (id: any) => {
    return (
      processors?.find((p) => p?.id === id)?.systemProcessorRange![0]
        ?.systemFiat?.name || ""
    );
  };

  const optionsPay = () => {
    //return processors?.filter(p => p.id !== getValues('destiny')).map(p => ({
    return processors
      ?.filter((p) => p.status === "active")
      ?.map((p) => ({
        value: p.id,
        label: p.name,
        image: `${process.env.REACT_APP_PROCESSOR_IMAGE}${p.photoUrl}`,
      }));
  };

  const optionsAccount = () => {
    return userAccountList
      ?.filter((t) => t?.systemProcessor?.id === getValues("destiny"))
      .map((account) => ({
        value: account.id,
        label:
          account.systemProcessor.name +
          " " +
          ((account?.accounttype?.length || 0) > 1
            ? account.accounttype === "saving"
              ? t("accounts.saved")
              : t("accounts.checking")
            : "") +
          " " +
          account.number,
        image: `${process.env.REACT_APP_PROCESSOR_IMAGE}${account.systemProcessor.photoUrl}`,
      }));
  };

  const handleCalculate = async (data: DepositForm) => {
    validateDestinyAndOrigen();
    if (validateSendCalculate()) {
      setNeedReCalculate(false);
      setLoadingCalculate(true);
      const calculate = await APIService.derivCalculate({
        ...data,
      });
      setNotRange("");
      if (calculate.data.status) {
        setFormIsSent(true);
        setTotalToPay(calculate.data.data.totalToPay);
        //setTypeMovement(calculate.data.data.rangePrices.systemProcessor.type);
        //agregar deposito al contexto
        setSuccess(true);
      } else {
        setNotRange("failed");
        setTotalToPay("0");
        setSuccess(false);
      }
      setLoadingCalculate(false);
    }
  };

  const handleSelect = async (type: string, e: any) => {
    try{
      /*  console.log('handle select is ',e)
      console.log('type is ',type) */
      setFormIsSent(false);
      switch (type) {
        /*eCASE DESTINY SE REFIERE A RECIBIRÉ EN E.VALUE 
        HACE REFERENCIA AL ID DEL PROCESADOR */
        case "destiny":
          setIsEnableDestiny(true);
          setIsEnableOrigen(true);
          setValue("destiny", e.value);
          setUseDestiny(e.value);
          setTotalToPay(undefined);
          setEnableAccountSend(false);
          const x = userAccountList?.filter(
            /*(t) => t?.systemProcessor?.id === getValues("destiny")*/
            (t) => (t?.systemProcessor?.id || 0).toString() === (e.value).toString()
          );
          if ((x?.length || 0) === 0) {
            setAccountFind(false);
          } else {
            setAccountFind(true);
          }
          resetField("accountsend");
          setNeedReCalculate(true);
          //@ts-ignore
          //selectRef.current.select.clearValue();
          //selectRef.select.clearValue();
          break;
          /* CASE PROCESSOR SE REFIERE A PAGARÉ CON  */
        case "processor":
          setIsEnableDestiny(true);
          setIsEnableOrigen(true);
          setValue("processor", e.value);
          setValue("destiny", "");
          setUseDestiny("");
          setTotalToPay(undefined);
          const origenFiat = getFiatName(e.value);
          let tempProcessors = [...processors];
          //para cualquier Fiat distinto a USD no está permitido q el origen y el destino contengan mismo tipo de Fiat
          if ((origenFiat || "").toUpperCase() !== "USD") {
            //processors?.find(p => p?.id === id)?.systemProcessorRange![0]?.systemFiat?.name
            tempProcessors = tempProcessors
              .filter((p) => p.status === "active")
              .filter(
                (p) =>
                  p.systemProcessorRange![0]?.systemFiat?.name !==
                  (origenFiat || "")
              );
          }
          const optionsDestiny2 = tempProcessors
            .filter((p) => p.id !== e.value)
            .filter((p) => p.status === "active")
            .map((p) => ({
              value: p.id,
              label: p.name,
              image: `${process.env.REACT_APP_PROCESSOR_IMAGE}${p.photoUrl}`,
            }));
  
          setOptionsDestiny(optionsDestiny2);
          setNeedReCalculate(true);
          break;
          /* CASE ACCOUNTSEND SE REFIERE A
          LA CUENTA QUE ELIGE EL USUARIO PARA RECIBIR EL DINERO. */
        case "accountsend":
          if(e?.value){
            setValue("accountsend", e?.value);
            //setTotalToPay(undefined);
            setEnableAccountSend(true);
          }
          //volver a enviar
          /*formRef.current?.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );*/
          //@ts-ignore
          //selectRef.select.clearValue();
          break;
      }
    }catch(e){
      Notify("Ocurrio un error, estamos trabajando para resolverlo.")
      const data = {
        message: `Error eligiendo cuenta ${JSON.stringify(e)}`,
        userId: '3dcd571c-d064-4a3a-b036-5e704f8cbe8a',
        type:'deposit',
        typeId: '',
        link: `'`
      }
      await APIService.notificationCreate(data)
    }
    //console.log(e)
    //
  };
  /*const calculate = () => {
    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }*/
  const responseConfirm = (r: boolean) => {
    setViewConfirm(false);
    if (r) {
      handleCreateDeposit();
    }
  };

  const handleCreateDeposit = async () => {
    try{
      setInactiveUser(false);
      setSuccess(false);
      setLoading(true);
      const data = {
        //money: getValues("money"),
        money: previewValue,
        percenttodeposit: "",
        totaltopay: totalToPay,
        currency: "COP",
        paymentPhoto: undefined,
        accountsendstringify: JSON.stringify(
          userAccountList?.find((p) => p.id === getValues("accountsend")) || ""
        ),
        //code: generateCode(),
        systemProcessor: getValues("processor") || '',
        destinyProcessor: getValues("destiny") || '', 
      } as any;
  
      const createDeposit = await APIService.derivCreateDeposit({
        ...data,
        user: user?.id || "",
      });
  
      //
      setLoading(false);
      if (createDeposit?.data?.status) {
        setSuccess(true);
        const newDeposit = createDeposit?.data?.data?.create as any;
        if(processors && newDeposit){
          newDeposit.systemProcessor = processors?.find(
            (p) => Number(p.id) === Number(newDeposit?.systemProcessor?.id || '')
          );
          delete newDeposit.user;
          const tempDeposits = [...deposits];
          tempDeposits.unshift(newDeposit as IDeposit);
          updateDeposits([...(tempDeposits as IDeposit[])]);
          navigate(`/transferencia/${createDeposit.data.data.create.id}`);
        }else{
          Notify("No fue posible realizar la transferencia, intente nuevamente.")
        }
      } else {
        if (createDeposit.data.message === "inactive user") {
          setInactiveUser(true);
          Notify(t("youraccountwasdisable"));
        }else if(createDeposit.data.message === "max waiting list"){
          setMaxTransferReached(true)
        }
      }

    }catch (e){
      alert('Ha ocurrido un error, estamos trabajando para solucionarlo.')
      console.log(e)
      const data = {
        message: `Error creando transferencia ${JSON.stringify(e)}`,
        userId: '3dcd571c-d064-4a3a-b036-5e704f8cbe8a',
        type:'deposit',
        typeId: '',
        link: `/transferencia/''`
      }
      await APIService.notificationCreate(data)
      window.setTimeout(() => {
        window.location.reload()
      },3000)
    }

  };

/*   const handleConfirm = () => {
    setNeedValidate(true);
    if (success && validateSendCalculate()) {
      setViewConfirm(true);
    }
  }; */

  const validateSendCalculate = () => {
    const x =
      getValues("money") && getValues("destiny") && getValues("processor");
    if (!x) {
      setNeedValidate(true);
    } else {
      setNeedValidate(false);
    }
    return x;
  };

  /* const applyFilter = (data:x[]) => {
     
     const origenFiat = getFiatName(getValues('processor'))
     const destinyFiat = getFiatName(getValues('destiny'))
     return optionsDestiny.filter(d => d.value !== getValues('processor'))
     //Ok pero para cualquier Fiat distinto a USD no está permitido q el origen y el destino contengan mismo tipo de Fiat
     //Solo se permite mismo tipo de Fiat entre origen y destino solo cuando sea USD y el procesador sea diferente origen y destino
     
   }*/
  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormIsSent(false);
    let { value } = e.target;
    value = value.replaceAll(".", "");
    /*const regex = /^[0-9]+$/;*/
    setValue("money", value.replace(/\D/g, ""));
    setTotalToPay(undefined);
    setPreviewValue(Number(value.replace(/\D/g, "")));
  };

  /*const blockInvalidChar = (e: any) => {
    ["e", "E", "+", ".", "-", ",", "`"].includes(e.key) && e.preventDefault();
  }*/

  const handleCloseDialogCreateAccount = async(action:string,e: any) => {
    setIsOpenDialogAccount(false)
    if(action==='refetch_success_saved'){
      await fetchListAccounts()
      setValue("destiny", e.value);
      setUseDestiny(e.value);
      setTotalToPay(undefined);
      setEnableAccountSend(false);
      setAccountFind(true);
      resetField("accountsend");
      setNeedReCalculate(true);
    }
  }
  return (
    <section style={{ minHeight: "100vh" }}>
      {<DialogCreateAccount
        preSelected={useDestiny} 
        isOpen={isOpenDialogAccount} 
        handleClose={handleCloseDialogCreateAccount} 
      />
      }
      <ToastContainer
        position="bottom-right"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="w-full lg:w-11/12 mx-auto">
        <div className="font-semibold mt-6 md:mt-10 text-center md:text-left text-3xl w-fit mx-auto border-b border-green-logo">
          {t("createtransfer")}
        </div>
        <div className="w-full flex lg:hidden mt-4 text-center justify-center items-center ">
          {t("changetoday")}{" "}
          <BiDollar className="text-green-admin-light" size={22} /> USD $
          {money(currentDollar)}
        </div>
        <div className=" text-center text-base mt-6 md:mt-4 font-normal mb-12 tracking-wide">
          {t("transferready")}
        </div>

        {inactiveUser && (
          <div className="w-full  mb-10  py-4 px-2 text-center mt-10 bg-green-admin-light text-white rounded-md ">
            <div className="text-xl font-bold">
              {t("youraccountwasdisable")}
            </div>
            <div>{t("youraccountwasdisableInfo")}</div>
          </div>
        )}
        {!canGo && <NeedVerifyIdentitied />}

        {!fetchLoading && (userAccountList?.length || 0) === 0 && (
          <div className="w-fit mx-auto rounded-md py-6 mb-4 px-2 bg-green-admin text-white">
            <div className="mb-3 text-center">{t("transferinvitation")}</div>
            <div className="w-full w-full leading-6 button-amber-two mx-auto px-4 py-2 text-black font-semibold text-center text-sm ">
              {/*<Link
                to="/mis-cuentas"
                className="text-black text-center"
              >
                {t("bankaccountlink")}
              </Link>*/}
              <div className="text-black text-center cursor-pointer" onClick={() => setIsOpenDialogAccount(true)}>
                {t("bankaccountlink")}
              </div>
            </div>
          </div>
        )}
        {user && <NeedVerifyAccount userId={user?.id || ""} />}
      </div>

      <form
        ref={formRef}
        onSubmit={handleSubmit(handleCalculate)}
        className="w-full md:w-11/12 mx-auto"
      >
        <div className="grid grid-cols-2 gap-x-6 gap-y-5 h-auto">
          <div className="col-span-2 md:col-span-1 text-black">
            <div className="text-sm font-semibold">
              {t("paymentmethodtext")}{" "}
            </div>
            <Select
              isSearchable={true}
              options={optionsPay()}
              components={{ Option: SelectWithdrawOptions }}
              styles={selectStyle}
              onChange={(e: any) => handleSelect("processor", e)}
              placeholder={`${t("selectlist")}`}
            />
            {needValidate && !getValues("processor") && <FormRequired />}
          </div>
          <div className="col-span-2 md:col-span-1 text-black">
            <div className="text-sm font-semibold">
             {t("needgetdestinytext")}
            </div>
            <Select
              isSearchable={true}
              options={optionsDestiny}
              components={{ Option: SelectWithdrawOptions }}
              styles={selectStyle}
              value={{
                label:
                  processors.find((p) => p.id === useDestiny)?.name ||
                  undefined,
                value:
                  processors.find((p) => p.id === useDestiny)?.id || undefined,
              }}
              onChange={(e: any) => handleSelect("destiny", e)}
              placeholder={`${t("selectlist")}`}
            />
            {needValidate && !getValues("destiny") && <FormRequired />}
          </div>
          <div className=" col-span-2 ">
            {!fetchLoading &&
              (optionsAccount()?.length || 0) === 0 &&
              optionsDestiny.some((d) => d.value === getValues("destiny")) && (
                <div className="w-full">
                  <div className="flex flex-col sm:flex-row items-center gap-2 px-2 py-3 border-2 border-dashed border-amber-400 w-full  lg:w-10/12 mx-auto mb-8 text-base-sm rounded-md">
                    <div className="font-medium grid grid-cols-2 items-center gap-6 justify-between text-left">
                      <div className="text-center">
                        <div className="text-center">
                          <img
                            src={optionsDestiny.find( (d) => d.value === getValues("destiny"))?.image || ""} alt="procesador"
                            className="w-80 mx-auto"
                          />
                        </div>
                        {t("bankaccountneedcreate")}{" "}
                        <span className="font-semibold uppercase">
                          {
                            optionsDestiny.find(
                              (d) => d.value === getValues("destiny")
                            )?.label
                          }
                        </span>{' '}{t("bankaccountneedcreate2")}
                      </div>
                      <div className="w-fit text-center mx-auto">
                        {/* funcionando <Link
                          to={`/mis-cuentas/${getValues("destiny")}`}
                          className="text-[#000]  button-amber-two font-medium rounded-md hover:text-[#000] flex flex-col items-center justify-center gap-2 px-4 py-2"
                        >
                          {t("bankaccountlink")}{' '}{optionsDestiny.find((d) => d.value === getValues("destiny"))?.label || ''}
                        </Link>*/}
                        {/*temporalemte en pruebasd*/ <button
                          onClick={() => setIsOpenDialogAccount(true)}
                          className="text-[#000]  button-amber-two font-medium rounded-md hover:text-[#000] flex flex-col items-center justify-center gap-2 px-4 py-2"
                        >
                          {t("bankaccountlink")}{' '}{optionsDestiny.find((d) => d.value === getValues("destiny"))?.label || ''}
                      </button>/*fin temporalmente en pruebas.. */}
                      </div>
                    </div>
                  </div>
                </div>
              )}


        {(!isEnableDestiny || !isEnableOrigen) && getValues("processor") && getValues("destiny") && (
          <div className="px-4 py-3 flex flex-col mx-auto text-center justify-center text-white button-green-system font-semibold mb-4 rounded-md w-fit text-base">
              <IoAlertCircleOutline className="text-white mx-auto" size={33} />
            <div className="mb-3 flex mx-auto text-center items-center gap-2">
              {t("origenDestinyDisabled")}{" "}
              {!isEnableOrigen &&
                (processors.find((p) => p?.id === getValues("processor"))
                  ?.name ||
                  "")}
              {!isEnableDestiny && !isEnableOrigen && `, `}{" "}
              {!isEnableDestiny &&
                (processors.find((p) => p?.id === getValues("destiny"))?.name ||
                  "")}
            </div>
            <div className="font-normal text-center">
              {t("origenDestinyDisabledInfo")}
            </div>
            <div className="font-normal text-center mt-1">
              {t("origenDestinyDisabledInfo2")}
            </div>
          </div>
        )}
        
          </div>
          <div className="col-span-2 md:col-span-1 text-black">
            <div className="text-sm font-semibold ">
              {t("willsendneto")} {getFiatName(getValues("processor"))}
            </div>
                          
            <input
              onChange={changeValue}
              type="text"
              max="99999999999999999999"
              maxLength={1000}
              min="1"
              value={money(previewValue)}
              className="text-sm font-semibold py-3 px-2 border border-gray-300 rounded-md w-full lg:w-1/2"
              placeholder={t("enterquantity")}
            />
            {(errors.money || (needValidate && !getValues("money"))) && (
              <FormRequired />
            )}
            {notRange === "failed" && (
              <div className="text-rose-500 px-4 py-2 mt-2 font-semibold text-base-sm border-2 border-rose-400 rounded-md w-full">
                {t("deposits.notrange")} <span className="text-2xl">≠</span> {previewValue}
              </div>
            )}
          </div>

          <div className="col-span-2 md:col-span-1 text-black">
            {!needReCalculate && (
              <>
                <div className="text-sm font-semibold ">
                  {t("get")} {getFiatName(getValues("destiny"))}
                </div>
                {!loadingCalculate ? (
                  <input
                    type="text"
                    value={money(totalToPay)}
                    readOnly
                    className="text-sm font-semibold py-3 px-2 border border-gray-300 rounded-md w-full lg:w-1/2 cursor-default"
                    placeholder="$"
                  />
                ) : (
                  <div className="skeleton-loading w-full">
                    <div
                      className="loading-block-dark"
                      style={{ width: "50%", height: "3rem" }}
                    >
                      &nbsp;
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {accountFind && !needReCalculate && (
            <div className="col-span-2 md:col-span-1 text-black">
              <div className="w-full">
                <div className="text-sm font-semibold">
                  {t("choseaccountforget")}{" "}
                </div>
                <Select
                  ref={selectRef}
                  isSearchable={true}
                  options={optionsAccount()}
                  components={{ Option: SelectAccountsOptions }}
                  styles={selectStyle}
                  onChange={(e: any) => handleSelect("accountsend", e)}
                  placeholder={`${t("withdraw.choosemybank")}`}
                />
                {needValidate && !getValues("accountsend") && <FormRequired />}
                <div>
                  {/*
                  <Link to="/mis-cuentas" className="text-black hover:text-black hover:underline">
                    {t("wannacreateaccount")}{' '} {
                     optionsDestiny.find( (d) => d.value === getValues("destiny") )?.label 
                    }
                  </Link>
                  */}
                  <div
                    onClick={() => setIsOpenDialogAccount(true)}
                    className="text-black hover:text-black hover:underline cursor-pointer"
                  >
                    {t("wannacreateaccount")}{' '}                          {
                      optionsDestiny.find((d) => d.value === getValues("destiny"))?.label}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-span-2 ">
            <div className="grid grid-cols-1">
              {(optionsAccount()?.length || 0) > 0 &&
                enableAccountSend &&
                totalToPay &&
                notRange === "" && (
                  <div className="grid-cols-1">
                    {isEnableDestiny && isEnableOrigen && (
                      <div>
                        {!loading ? (
                          <>
                            {user?.verifyAccount && (
                              <>
                                {canGo ? (
                                  <button
                                    onClick={() => /*handleConfirm()*/ handleCreateDeposit()}
                                    className="flex button-green-system text-white item-center mx-auto md:mx-0 justify-center text-lg md:text-xl rounded-full text-center px-1 md:px-10 w-11/12 md:w-56 py-4  font-bold cursor-pointer"
                                  >
                                    {/*t("createthetransfer")*/ t("continue")}
                                  </button>
                                ) : (
                                  <div
                                    onClick={() => navigate("/perfil/verify")}
                                    className="flex item-center mx-auto md:mx-0 justify-center text-lg md:text-xl rounded-full text-center px-1 md:px-10 w-11/12 md:w-fit py-4 bg-dark-green text-white font-bold cursor-pointer"
                                  >
                                    {t("needverifyidentitiedpress")}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <button className="flex gap-3 item-center mx-auto md:mx-0 justify-center text-lg md:text-xl rounded-full text-center px-1 md:px-10 w-full md:w-56 py-4 bg-dark-green text-white font-bold cursor-pointer">
                            {t("savingtext")}...{" "}
                            <CgSpinner className="animate-spin" size={26} />
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              {!loading && (!totalToPay || !enableAccountSend) ? (
                  <input
                    value={t("calculate")}
                    type="submit"
                    className=" justify-center mx-auto md:mx-0 text-lg rounded-full text-center px-3 py-4 w-11/12 md:w-56  button-amber-two text-black font-bold cursor-pointer"
                  />
              ):
              <div className="w-full h-24">
                </div>
              }
            </div>
          </div>
        </div>
      </form>

      {viewConfirm && <DepositConfirm response={responseConfirm} />}
      <DialogInfoGeneral show={maxTransferReached}
        closeDialog={() => setMaxTransferReached(false)}
        children={
          <div className="text-center mt-10 px-4 md:px-10 text-base">
            <div className="font-bold">
              {t('maxTransferOne')}
            </div>
            <div className="font-medium mt-4 px-4 md:px-10">
              {t('maxTransferTwo')}
            </div>
            <div className="font-medium mt-4 px-4 md:px-10">
              {t('maxTransferThree')}
            </div>                  
          </div>
        } 
      />
      <div className="w-full mt-4">
        <ProcessorCarousel />
      </div>
    </section>
  );
};

export default DepositNew;

export const SelectWithdrawOptions = (option: any) => {
  const { innerProps, innerRef } = option;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="flex items-center flex-wrap justify-between p-2 hover:bg-gray-200  cursor-pointer"
    >
      <div className="font-medium flex items-center gap-x-2 text-sm">
        {<img src={option.data.image} alt="processor" className="w-36" />}
        {option.data.id} {/*option.data.label*/}
      </div>
    </div>
  );
};


export const SelectAccountsOptions = (option: any) => {
  const { innerProps, innerRef } = option;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="flex items-center flex-wrap justify-between p-2 hover:bg-gray-200  cursor-pointer"
    >
      <div className="font-medium flex items-center gap-x-2 text-sm">
        {<img src={option.data.image} alt="processor" className="w-36" />}
        {option.data.label}
      </div>
    </div>
  );
};
