import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UseAuth } from 'src/auth/AuthContext';
import Layout from 'src/Layouts/Main';

interface GuardProps {
  children: ReactNode;
}

const GuardClient: FC<GuardProps> = ({ children }) => {
  const { isAuthenticated } = UseAuth();
  if (!isAuthenticated) { 
    return <Navigate to="/" />
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  /*useEffect(() => {
    setInterval(async() => {
      if (!isAuthenticated) { 
        return <Navigate to="/" />
      }
    }, 70000)
  }, [])*/
  

  return (
    <Layout><>{children}</></Layout>
  )
}

GuardClient.propTypes = {
  children: PropTypes.node
}

export default GuardClient