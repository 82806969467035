import { FaCircleUser } from "react-icons/fa6";
import { IUserCard } from "src/types";
import countries from "./../../data/countries.json";
import { useNavigate } from "react-router-dom";

const UserCard: React.FC<IUserCard> = ({
  id,
  email,
  phone,
  fullname,
  country,
  city,
  address,
  identificationType,
  documentNumber
}) => {
  const navigate = useNavigate();

  const getTypeDoc = (type: string) => {
    switch (type) {
      case "cedula":
        return "cédula";
      case "licenciaconduccion":
        return "licencia conducción";
      default:
        return type;
    }
  };
  return (
    <div
    className="font-medium cursor-pointer w-fit text-sm line leading-7"
    onClick={() => navigate(`/admin/usuario/detalle/${id}`)}
  >
    <div className="font-bold text-green-admin underline mb-2 gap-2 text-base flex items-center">
      <FaCircleUser size={20} />
      {fullname}
    </div>
    {fullname !== email && (
      <div className="font-medium">
        Email: <span className="font-bold">{email}</span>
      </div>
    )}
    <div>
      País: {country} - Ciudad: <span className="font-bold">{city}</span>
    </div>
    <div className="w-full">
      Dirección: <span className="font-bold">{address}</span>
    </div>
    <div>
      Tipo documento:{" "}
      <span className="font-bold">
        {getTypeDoc(identificationType || "Sin asignar")} - {documentNumber}
      </span>
    </div>
    <div>
      Teléfono:{" "}
      <span className="font-bold">
        {countries.find((c) => c.code === (country || "-"))?.dial_code}{" "}
        {phone || "-"}
      </span>
    </div>
  </div>
  );
};

export default UserCard;
