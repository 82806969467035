
import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"
import IconV from "src/assets/images/video-camera.png"
import VideoPresentation from "./../../assets/video/presentation.mp4";
import VideoPlaceholder from "./../../assets/video/isfvideopreview.png"; 
type ViewVideoParams = {
  close(): void
}
const ViewVideoPopup:React.FC<ViewVideoParams> = ({close}) => {
  return (
    <div>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  
                  <div className="flex flex-col items-center py-3">
                    <div className="w-1/5 flex justify-center">
                      <img src={IconV} className="w-full" alt="alerta" />
                    </div>
                    <div className="font-semibold text-center pb-3 text-xl border-b border-[#ccc]  w-full">
                      Video de isf cambios
                    </div>
                    <div className="text-center w-full font-medium py-6 px-6 mt-2">
                    <div className="w-full mx-auto ">
          <video className="w-full" controls poster={VideoPlaceholder}>
            <source src={VideoPresentation} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
                    </div>
                  </div>

                  <div className="mt-4 flex flex-wrap items-center gap-3 px-6 py-6">
                    <button
                      type="button"
                      className="font-semibold inline-flex justify-center rounded-md w-full  bg-green-admin-light text-white text-dark-green border border-dark-green py-2"
                      onClick={() => close()}
                    >
                      Cerrar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default ViewVideoPopup