import { gql } from '@apollo/client';

const FETCH_USER_VALIDATION_DETAIL = gql`
  query UserValidationDetail($userId: String!) {
    UserValidationDetail(userId: $userId) {
    id
    identificationType
    selfiePhoto
    commentFormOne
    commentFormTwo
    commentFormThree
    address
    city
    country
    statusFormOne
    statusIdentification
    statusSelfie
    documentNumber
    user {
        id
        fullname
        email
        phone
        country
        verifiedIdentity
      }
    uservalidationphoto {
        id
        photoUrl
        status
      }
    createdAt
    updatedAt
    }
  }
`;

export default FETCH_USER_VALIDATION_DETAIL;