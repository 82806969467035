import { useTranslation } from "react-i18next";
import { useAppStateContext } from "src/auth/AppStateContext";

type PaginationParams = {
  type: string,
  totalPages: Number
};
const Pagination: React.FC<PaginationParams> = ({
  type,
  totalPages,
}) => {
  const { t } = useTranslation();
  const { setPaginationAN, paginationAN, setPaginationUserTransfer, paginationUserTransfer } = useAppStateContext()
  const handlePag = (pag:number) => {
    //window.scrollTo(0, 100);
    window.scroll({top: 50, left: 0, behavior: 'smooth' })
    if(type === 'notificationAdmin'){
      setPaginationAN(pag as number)
    }else if(type === 'userTransfer'){
      setPaginationUserTransfer(pag as number)
    }
  }
  return (
    <div>
      <label className="flex gap-4 mt-2 bg-white px-2 py-1 w-fit border border-gray-100 mx-auto rounded-sm">
        <div>
          {t("paginate")}
          <select  onChange={(e) => handlePag(Number(e.currentTarget.value))} className="px-4 cursor-pointer py-2 outline-none rounded-sm">
          {type ==='notificationAdmin' && Array.from(new Array(totalPages).keys()).map((p) => (
              <option selected={paginationAN === p} value={p}>{p + 1}</option>
            ))}
            {type ==='userTransfer' && Array.from(new Array(totalPages).keys()).map((p) => (
              <option selected={paginationUserTransfer=== p} value={p}>{p + 1}</option>
            ))}          
          </select>
        </div>
      </label>
    </div>
  );
};

export default Pagination;
