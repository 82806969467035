import { useEffect, useState } from "react"
import logo from "../../assets/images/logoisfnav.png"
import Language from "../Language"
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom"
import { UseAuth } from "../../auth/AuthContext"
import Confirmation from "../Shared/Confirmation"
import Avatar from "./Avatar"
import { IoIosNotificationsOutline } from "react-icons/io"
import { useAppStateContext } from "../../auth/AppStateContext"
import { BiDollar } from "react-icons/bi";
import useQueryListNotification from "src/hooks/useQueryListNotification";
import { money } from "src/helpers/mixins";
import { RiBankCardFill } from "react-icons/ri";
//import { FaGraduationCap } from "react-icons/fa";
import NavUserAdminNotification from "./NavUserAdminNotification";
import APIService from "src/services/Api";
import { MdOutlineLogout } from "react-icons/md";


const NavUser = () => {
  const { t } = useTranslation();
  const { user, logout } = UseAuth()
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const { currentDollar, fetchCurrentDollar, setNotifications } = useAppStateContext()
  const { notificationList } = useQueryListNotification(0,user?.id || '')
   
  
  const navigate = useNavigate()


  const handleNotificationUser = async() => {
      await APIService.userNotificationRead({userId: user?.id || ''})
      navigate('/inicio/notification')

  }
  const closeSession = () => {
    logout()
  }
  useEffect(() => {
    if (!currentDollar || currentDollar === 'no disponible') {
      fetchCurrentDollar()
    }
    //eslint-disable-next-line
  }, [currentDollar])

  useEffect(() => {
    setInterval(async() => {
      (async() => {
        fetchCurrentDollar()
      })(); 
    }, 600000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    setNotifications(notificationList)
    //eslint-disable-next-line
  }, [notificationList])

 
  

  /*const notificationShow = () => {
    return (
      <div onClick={() => navigate('/inicio/notification')} className="relative rounded-full px-2 py-1 hover:bg-gray-300 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-0  ">
      {notificationList.some(n => !n.seen) ?
        <div className="border shadow-md border-dark-green rounded-full">
          <span className="notification-quantity">{notificationList.filter(n => !n.seen).length}</span>
          <IoIosNotificationsOutline className="text-black" size={27} />
        </div>
      :
        <div>
          <IoIosNotificationsOutline className="text-black" size={27} />
       </div>
      }
      
    </div>
    )
  }*/
  return (
    <>
      <div className="h-10">
        <Confirmation show={showConfirmation} cancel={() => setShowConfirmation(false)} confirm={() => closeSession()} />
        <nav className="bg-white px-2 sm:px-4 py-1 w-full border-b border-gray-100 border-1 fixed" style={{ zIndex: "1500" }}>
          <div
            className="container flex flex-row justify-between items-center w-full"
          >
            <a href="inicio" className="flex items-center py-2">
              <img alt="logo" className="w-40 h-auto" src={logo} />
            </a>
            <div className="flex items-center gap-2 lg:hidden ">

            {/*<Link to="/aprender" className="mr-2 border-r border-gray-700 pr-2 sm:pr-10">
                <FaGraduationCap size={28} className='text-gray-700' />
              </Link>*/
            }
                      
              <Link to="/mis-cuentas">
                <RiBankCardFill size={27} className='text-gray-700' />
              </Link>    
              
              <div onClick={() => handleNotificationUser()} className="relative rounded-full px-2 py-1 hover:bg-gray-300 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-0  ">
                {notificationList.some(n => !n.seen) ?
                  <div className="border shadow-md border-dark-green rounded-full">
                    <span className="notification-quantity">{notificationList.filter(n => !n.seen).length}</span>
                    <IoIosNotificationsOutline className="text-black" size={27} />
                  </div>
                :
                  <div>
                    <IoIosNotificationsOutline className="text-black" size={27} />
                  </div>
                }
              </div>         
              {( (user?.role === 'admin' || user?.role === 'cajero') && <NavUserAdminNotification /> )}
            </div>
            <div className="hidden w-full lg:block lg:w-auto" id="mobile-menu">
              <ul
                className="flex flex-col items-center mt-4 md:flex-row md:space-x-2 md:mt-0 md:text-sm md:font-normal"
              >
                <li className="flex">
                  <button
                    className="w-64 rounded-md border border-gray-300 flex  items-center gap-2 text-sm  font-medium  cursor-pointer  px-4 py-1.5"
                  >
                    {t('changetoday')} <BiDollar className="text-green-admin-light" size={22} /> USD ${money(currentDollar)}
                  </button>
                </li>
                <li>
                  <div className="w-20">
                  </div>
                </li>
                <li className="flex gap-x-4 items-center">
                <div onClick={() => handleNotificationUser()} className="relative rounded-full px-2 py-1 hover:bg-gray-300 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-0  ">
                    {notificationList.some(n => !n.seen) ?
                      <div className="border shadow-md border-dark-green rounded-full">
                        <span className="notification-quantity">{notificationList.filter(n => !n.seen).length}</span>
                        <IoIosNotificationsOutline className="text-black" size={27} />
                      </div>
                    :
                      <div>
                        <IoIosNotificationsOutline className="text-black" size={27} />
                     </div>
                    }
                    
                  </div>
                  {(user?.role === 'admin' || user?.role === 'cajero') && <NavUserAdminNotification />}

                  <Link to="/perfil" >
                    <Avatar styles={"w-8 h-8 text-sm p-2 shadow-md hover:border hover:border-black mr-2 cursor-pointer"} name={user?.fullname} />
                    {/*<button
                      className="rounded-md  flex  items-center gap-2 text-sm font-normal cursor-pointer  transform hover:-translate-y-0 hover:scale-105 hover:text-white hover:bg-green-logo px-4 py-2"
                    >
                      {user?.fullname} <AiOutlineUser size={15} />
                    </button>*/}
                  </Link>
                </li>
                <li className="flex items-center gap-x-4 pl-8">
                  <MdOutlineLogout className="cursor-pointer" onClick={() => setShowConfirmation(true)} size={24} />
                  <Language />
                </li>
              </ul>
            </div>

          </div >
        </nav >
      </div >
    </>
  )
}

export default NavUser