import React, { useState, useEffect } from "react"
import { HiOutlineStatusOnline, HiSearchCircle } from "react-icons/hi"
import useQueryChatUserOnline from "src/hooks/chat/useQueryChatUserOnline"
import APIService from "../../services/Api"
import { UserOnline } from "../../types"
import ChatPerson from "../Shared/ChatPerson"
import Avatar from "../System/Avatar"
import { BiSolidMessageRoundedDots } from "react-icons/bi"
import { useTranslation } from "react-i18next"
import { AiOutlineClose } from "react-icons/ai"
import { IoCloseCircleSharp } from "react-icons/io5"
import { CgSpinner } from "react-icons/cg"


type ParamChatAdmin = {
  openChatWithPerson(): void
}

const ChatAdmin: React.FC = () => {
  //@ts-ignore-comment
  const {chatUserOnline } = useQueryChatUserOnline()
  const [showChatList, setShowChatList] = useState<boolean>(false)
  const [selectedPerson, setSelectedPerson] = useState<UserOnline>()
  const [responseSearchList, setResponseSearchList] = useState<UserOnline[]>()
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)
  const [searchTotalRows, setSearchTotalRows] = useState<number>(0)
  const [inputSearchUser, setInputSearchUser] = useState<string>()
  const { t } = useTranslation()
  const handleSelectedPerson = (user:UserOnline) => {
    setSelectedPerson(user)
  }

  const convertDate = (x : any) => {
    return new Date(x)
  }

  /*const onSearch = (
    event:
| React.FormEvent<HTMLInputElement>
      | React.FormEvent<HTMLSelectElement>
  ) => {
    const { value, name } = event.currentTarget;
    setSearch({ ...search, [name]: value });
  };*/
  const onSearch = (e : React.FormEvent<HTMLInputElement> ) => {
    const { value } = e.currentTarget
    setInputSearchUser(value)
  }

  const fetchSearchUser = async () => {
    setLoadingSearch(true)
    const res = await APIService.adminSearchChatUser({search: inputSearchUser})
    console.log('respuestas!!')
    console.log(res.data.data.users)
    setResponseSearchList(res.data.data.users)
    setSearchTotalRows(res.data.data.totalRows || 0)
    setLoadingSearch(false)
  }

  const handleCloseSearch = () => {
    setInputSearchUser("")
    setResponseSearchList(undefined)
  }

  return (
    <div>
      {showChatList ?
        <div className="chat-admin z-10">
          <div
            className="chat-admin-close flex justify-between items-center bg-emerald-600 text-white pl-2 pr-4 py-2 cursor-pointer"
          >
            <div className="bg-white w-full rounded-sm flex items-center mr-4 ">
              <input 
                value={inputSearchUser || ''} 
                onChange={e => onSearch(e)} 
                onKeyUp={(e) => (e.key === "Enter" ? fetchSearchUser() : null)}
                type="text" 
                placeholder="Buscar nombre, email, teléfono" 
                className="w-full px-2 py-1 rounded-lg px-2 text-black"
                />
              <div  className="flex items-center">
                <HiSearchCircle onClick={() => fetchSearchUser()} className="text-stone-400" size={30} />
                {(inputSearchUser?.length || 0) > 0 && 
                  <AiOutlineClose onClick={() => handleCloseSearch() } className="text-stone-500" size={23} />
                }
              </div>
            </div>
            <div onClick={() => setShowChatList(false)} className="font-semibold">
              <IoCloseCircleSharp size={30} />
            </div>
          </div>
          {!responseSearchList ?
            <div className="px-0 py-2 flex flex-col gap-x-2 relative" style={{ overflowY: "scroll", maxHeight: "355px" }}>
            {chatUserOnline.filter((user: any) => user.email !== 'chat@isfcambios.com')
              .sort((a:any,b:any) => Number(convertDate(b.fullname.split('|*|*|*|')[2])) - Number(convertDate(a.fullname.split('|*|*|*|')[2])))
              .map((user: any) => (
                <div className="relative">
                  {user.fullname.split('|*|*|*|')[2] === 'yes' &&
                    <BiSolidMessageRoundedDots className="text-secondary absolute top-1.5 left-5" size={23} />
                  }
                <div className="flex items-center gap-x-2 cursor-pointer hover:bg-gray-200 transition-all px-2 py-1 rounded-mg "
                onClick={() => handleSelectedPerson(user)}>
                  <div>
                    <Avatar name={user.fullname.split('|*|*|*|')[0]}
                      styles={"w-6 h-6 text-xs cursor-pointer"}
                    />
                  </div>
                  {user.isOnline === 'online' ?
                    <div className="bg-green-600 w-2 h-2 rounded-full "></div>
                    :
                    <div className="bg-gray-400 w-2 h-2 rounded-full "></div>
                  }
                  <div title={user.fullname.split('|*|*|*|')[0]}
                    className="text-sm text-gray-600  font-medium cursor-pointer">
                    {user.fullname.split('|*|*|*|')[0].substring(0,23 )}
                    {user.fullname.split('|*|*|*|')[0].length > 23 && `..`}
                    <div
                    title={user.email}
                    className="text-gray-500"
                    style={{fontSize:'.612rem'}}>
                      {user.email.substring(0,40)}
                      {user.email.length > 40 && `..`}
                    </div>
                    <div title={user.fullname.split('|*|*|*|')[1]} className="text-xs text-green-admin-light"> {user.fullname.split('|*|*|*|')[1].substring(0,50)} {user.fullname.split('|*|*|*|')[1].length > 50 && '...'} </div>
                  </div>
                </div>
                
                </div>
              ))}
          </div>
          :
          <div className="px-0 py-2 flex flex-col gap-x-2 relative" style={{ overflowY: "scroll", maxHeight: "355px" }}>
            {loadingSearch ?
              <div>
                <CgSpinner className="animate-spin mx-auto mt-10" size={50} />  
              </div>
            :
              <div className="px-2">
                Resultados de la búsqueda: {searchTotalRows}
              </div>
            }
          {!loadingSearch && responseSearchList.map((user: any,key) => (
              <div className="relative" key={key.toString()}>
                {/*<BiSolidMessageRoundedDots className="text-secondary absolute top-1.5 left-5" size={23} />*/}
              <div className="flex items-center gap-x-2 cursor-pointer hover:bg-gray-200 transition-all px-2 py-1 rounded-mg "
              onClick={() => handleSelectedPerson(user)}>
                <div>
                  <Avatar name={user.fullname}
                    styles={"w-6 h-6 text-xs cursor-pointer"}
                  />
                </div>
                {user.isOnline === 'online' ?
                  <div className="bg-green-600 w-2 h-2 rounded-full "></div>
                  :
                  <div className="bg-gray-400 w-2 h-2 rounded-full "></div>
                }
                <div title={user.fullname}
                  className="text-sm text-gray-600  font-medium cursor-pointer">
                  {user.fullname.substring(0,23 )}
                  {user.fullname.length > 23 && `..`}
                  <div
                  title={user.email}
                  className="text-gray-500"
                  style={{fontSize:'.612rem'}}>
                    {user.email.substring(0,40)}
                    {user.email.length > 40 && `..`}
                  </div>
                  <div title={user.fullname} className="text-xs text-green-admin-light"> {user.fullname.substring(0,50)} {user.fullname.length > 50 && '...'} </div>
                </div>
              </div>
              
              </div>
            ))}
        </div>
          }
        </div>
        :
        <div
          className="chat-admin-closed z-10 flex items-center gap-x-2 px-4 py-5 cursor-pointer"
          onClick={() => setShowChatList(true)}
        >
          <HiOutlineStatusOnline size={24} className="text-green-logo" />
          {t('online')}
        </div>
      }

      <div className="chat-admin-with-user z-10">
        {selectedPerson &&
          <ChatPerson unselectPerson={() => setSelectedPerson(undefined)} selectedPerson={selectedPerson} />
        }
      </div>
    </div>
  )
}
export default ChatAdmin