import { useTranslation } from "react-i18next";

type DialogConfirmParams = {
  show: boolean
  closeDialog(): void 
  children:  JSX.Element | React.ReactNode
}
const DialogConfirm = ({show, closeDialog, children}:DialogConfirmParams) => {
  const { t } = useTranslation();
  return (
    <>
      {show &&
        <div
          className="relative z-[14000]"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-stone-900 bg-opacity-30 transition-opacity"></div>

          <div className="fixed inset-0 z-[14000] w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-11/12 xl:w-1/2">
                <div className="bg-white window-confirm-shadow relative rounded-lg">
                  <div
                    onClick={() => closeDialog()}
                    className="bg-red-500 text-white transition-all flex items-center font-bold justify-center text-xl cursor-pointer w-8 h-8 rounded-full absolute -right-4 -top-4 "
                  >
                    X
                  </div>
    
                  <div>
                  {children}
                  </div>
                  <div className="flex flex-row gap-3 items-center px-4 md:px-10 pb-6 justify-center">
                
                    <button onClick={() => closeDialog()} className="bg-green-logo rounded-lg font-bold text-base w-1/2 mt-10 py-4 text-white text-center">
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default DialogConfirm;
