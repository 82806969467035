import { useTranslation } from "react-i18next";
import { IoPersonCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const NeedVerifyIdentitied: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-green-admin-light text-center rounded-md py-4">
        <div className="flex justify-center mb-2">
          <IoPersonCircle size={40} className="text-white" />
        </div>
        <div
          className="p-2 w-fit mx-auto  uppercase items-center text-white leading-none lg:rounded-full"
          role="alert"
        >
          <span className="font-medium text-lg mr-2 text-left flex-auto">
            {t("needverifyidentitied")}
          </span>
        </div>

        <div className="text-white text-lg mt-2 px-4 font-medium tracking-wide mt-2">
          {t("needverifyidentitiedinfo")}
        </div>
        <div
          onClick={() => navigate("/perfil/verify")}
          className="text-black cursor-pointer bg-white mx-auto w-fit mt-4 px-4 py-2 text-lg font-medium tracking-wide mt-2"
        >
          {t("needverifyidentitiedpress")}
        </div>
      </div>
    </div>
  );
};

export default NeedVerifyIdentitied;
