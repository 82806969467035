//
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import FETCH_NOTIFICATIONS_LIST from 'src/graphql/query/notificationList';
import { INotification } from 'src/types';

const useQueryListNotification = (skip: number,userId:string) => {
  const { data = { }, loading } = useQuery(FETCH_NOTIFICATIONS_LIST, {
    variables: {
      skip,
      userId
    },
    fetchPolicy: "no-cache",
    pollInterval: 10000
  }); 

  const notificationList = useMemo(() => {
      const { notificationList = null } = data
      return (notificationList ? notificationList : [] )as INotification[]
    }, [data])
  return { notificationList, loading }
}

export default useQueryListNotification
