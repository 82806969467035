import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import FETCH_CHAT_LIST_MESSAGE from 'src/graphql/query/chat/chatListMessage';


const useQueryMessageList = (userDestinyId: String,userOrigenId:String) => {
  const { data = {}, loading } = useQuery(FETCH_CHAT_LIST_MESSAGE, {
    variables: {
      userDestinyId,
      userOrigenId
    },
    fetchPolicy: "no-cache",
    pollInterval: 700
  })

  const messageList = useMemo(() => {
    const { chatListMessage = null } = data
    return chatListMessage ? chatListMessage : []
  }, [data])

  return { messageList, loading }
}

export default useQueryMessageList

