import { Dropdown, Menu, Space } from "antd"
import ViewKycStatus from "./ViewKycStatus"
import { CgSpinner } from "react-icons/cg"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"


type KycStatusProps = {
  currentStatus :string
  formNumber: string
  newStatus(status:string,formNumber:string): void
}
const KycStatus = ({currentStatus,newStatus,formNumber}:KycStatusProps) => {
  const [showStatus, setShowStatus] = useState(currentStatus)

  useEffect(() => {
    setShowStatus(currentStatus)
  }, [currentStatus])
  
  const { t } = useTranslation()
  let menu = (
    <Menu
      items={[
        /*{
          key: "2",
          label: (
            <div onClick={() => newStatus('empty',formNumber)}>
              {t("kycstatus.empty")}
            </div>
          ),
        },*/
        /*{
          key: "3",
          label: (
            <div onClick={() => newStatus('waiting', formNumber)}>
              {t("kycstatus.waiting")}
            </div>
          ),
        },*/
        {
          key: "4",
          label: (
            <div onClick={() => newStatus('rejected', formNumber)}>
              {t("kycstatus.rejected")}
            </div>
          ),
        },
        {
          key: "5",
          label: (
            <div onClick={() => newStatus('completed', formNumber)}>
              {t("kycstatus.completed")}
            </div>
          ),
        },
      ]}
    />
  );
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div>
      <Dropdown overlay={menu}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            {!loading ? (
              <ViewKycStatus currentStatus={showStatus} />
            ) : (
              <div className="flex items-center justify-center w-full">
                <CgSpinner className="text-dark-green animate-spin" size={27} />
              </div>
            )}
          </Space>
        </a>
      </Dropdown>
    </div>
  )

}

export default KycStatus