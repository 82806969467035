import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import routes from './routes';


const App: FC = () => {
  const content = useRoutes(routes);
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      {content}
      
    </div>
    
  );
};

export default App;
