import { useEffect } from "react";
import { useState } from "react";
import Whatsapp from "../Shared/Whatsapp";
import CustomerComments from "./CustomerComments";
import Footer from "./Footer";
/* import VideoPresentation from "./../../assets/video/presentation.mp4";
import VideoPlaceholder from "./../../assets/video/isfvideopreview.png"; */
import SimulateMoney from "./SimulateMoney";
import { useTranslation } from "react-i18next";
import InformativeWall from "./InformativeWall";
import Allies from "./Allies";

import { Link } from "react-router-dom";
//import process from "process"
const BodyLanding = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [loading]);

  return (
    <div className="bg-[#f5f5f5]">
      {/*       {loading ? (
        <div className="skeleton-loading w-full">
          &nbsp;
          <div
            className="loading-block-dark"
            style={{ width: "90%", height: "65vh", margin: "0 auto" }}
          >
            &nbsp;
          </div>
        </div>
      ) : (
        <div className="w-11/12 mx-auto ">
          <video className="w-full" controls poster={VideoPlaceholder}>
            <source src={VideoPresentation} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
 */}
      {loading ? (
        <div className="skeleton-loading w-full">
          &nbsp;
          <div
            className="loading-block-dark"
            style={{ width: "90%", height: "65vh", margin: "0 auto" }}
          >
            &nbsp;
          </div>
        </div>
      ) : (
        <SimulateMoney />
      )}

      <InformativeWall />

      {/*loading ? (
        <div className="skeleton-loading w-full">
          &nbsp;
          <div
            className="loading-block-dark"
            style={{ width: "90%", height: "65vh", margin: "0 auto" }}
          >
            &nbsp;
          </div>
        </div>
      ) : (
        <MainCarrousel />
      )*/}

      {/*<div className="mt-14">
        <Community />
      </div>*/}
      <div className="customer-say-header">
        {loading ? (
          <div className="skeleton-loading w-full">
            &nbsp;
            <div
              className="loading-block-dark"
              style={{ width: "90%", height: "65vh", margin: "0 auto" }}
            >
              &nbsp;
            </div>
          </div>
        ) : (
          <CustomerComments />
        )}
      </div>
      <Allies />
      <Whatsapp />
      <Footer />
    </div>
  );
};

export default BodyLanding;
