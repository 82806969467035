import { Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useQueryListDeposit from "src/hooks/useQueryListDeposit";
import { IAccountForm, IDeposit } from "src/types";
import { getFiatName, getHourDate, money } from "../../../helpers/mixins";
import DepositStatus from "../../Shared/DepositStatus";
import { useAppStateContext } from "src/auth/AppStateContext";
import { UseAuth } from "src/auth/AuthContext";
import Pagination from "src/components/Shared/Pagination";
import DepositListSearch from "./DepositListSearch";

const AdminDepositListBolivares = () => {
  const {
    processors,
    fetchProccesors,
    deposits,
    fetchDeposits,
    setPaginationUserTransfer,
    paginationUserTransfer,
  } = useAppStateContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = UseAuth();
  const { depositList, loading } = useQueryListDeposit(
    user?.id || "",
    paginationUserTransfer,
    "cajerobolivares"
  );
  const [deposit, setDeposit] = useState<any[]>([]);

  const calcPages = () => {
    const q = depositList.find((d: IDeposit) => d.code.includes("PagesAdmin"));
    const quantity = Number(q.code.replace("PagesAdmin:", ""));
    return Number(quantity);
  };

  useEffect(() => {
    if (!processors.length) {
      fetchProccesors();
    }
    //eslint-disable-next-line
  }, [processors]);

  useEffect(() => {
    setDeposit(depositList);
  }, [depositList]);

  useEffect(() => {
    if ((deposits?.length || 0) === 0) {
      fetchDeposits();
      setDeposit(deposits);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deposits]);

  useEffect(() => {
    setPaginationUserTransfer(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDetailDeposit = (deposit: any) => {
    navigate(`/admin/depositos/detalle/${deposit?.id}`, {
      state: {
        ...deposit,
      },
    });
  };
  const columns = [
    {
      title: t("codetext"),
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any) => (
        <div>
          <div
            onClick={() => handleDetailDeposit(record)}
            className="underline text-green-admin-light font-bold cursor-pointer"
          >
            {record.code}
          </div>
          <div className="text-xs">
            
          {moment
            .utc(parseInt(record.createdAt, 10))
            .local()
            .format("YYYY-MM-DD")}{' '}
            <div className="font-semibold">
            {getHourDate(record.createdAt)}

            </div>
        </div>
        </div>
      ),
    },
    {
      title: t("table.willget"),
      dataIndex: "money",
      key: "money",
      render: (text: any, record: any) => (
        <div className="font-medium">
          <span className="font-bold">
            ${money(record.money)}{" "}
            {getFiatName(record.systemProcessor.id, processors)}{" "}
          </span>
          <div>
            {t("paymethod")}:{" "}
            <div className="font-semibold">{record.systemProcessor.name}</div>
          </div>
        </div>
      ),
    },
    {
      title: t("table.totaltopay"),
      dataIndex: "totaltopay",
      key: "totaltopay",
      render: (text: any, record: any) => (
        <div className="font-medium">
          <span className="font-bold">
            ${money(record.totaltopay)}{" "}
            {getFiatName(record.destinyProcessor.id, processors)}
          </span>
          <div>
            {/*t("paymethod")*/}
            <div className="font-semibold text-xs">
              <div>
                {
                  (JSON.parse(record?.accountsendstringify) as IAccountForm)
                    ?.systemProcessor?.name
                }
              </div>
              <div>
                {
                  (JSON.parse(record?.accountsendstringify) as IAccountForm)
                    ?.number
                }
              </div>
              {(JSON.parse(record?.accountsendstringify) as IAccountForm)
                ?.accounttype && (
                <div>
                  {t(
                    `bankaccounttype.${
                      (JSON.parse(record?.accountsendstringify) as IAccountForm)
                        ?.accounttype
                    }`
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },

    {
      title: t("table.user"),
      dataIndex: "user.fullname",
      key: "user.fullname",
      render: (text: any, record: any) => (
        <div className="font-medium underline text-green-admin-light cursor-pointer" 
        onClick={() => navigate(`/admin/usuario/detalle/${record.user.id}`)}
        >
          {record.user.email}
          {record.user.fullname !== record.user.email && (
            <div>{record.user.fullname}</div>
          )}
        </div>
      ),
    },
    {
      title: t("table.status"),
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => (
        <div className="font-small-son">
          <DepositStatus
            userId={record.user.id}
            depositId={record.id}
            depositCode={record.code}
            currentStatus={record.status}
          />
        </div>
      ),
    }
 
  ];

  /*const handleCreateAccount = (record?: any) => {
    navigate("/admin/cuentas-bancarias/guardar", {
      state: {
        ...record,
      },
    });
  };*/
  return (
    <section>
      <div className="grid grid-cols-1 items-center justify-between w-full mx-auto mb-1">
        <div className="w-full ">
          <div className="text-xl">
            <span className="font-bold"> {t("admin.transferlist")} </span>{" "}
            <span className="font-bold text-secondary">/</span>{" "}
            Gestiona las transferencias
          </div>
        </div>
        <DepositListSearch type={'cajerobolivares'} />
      </div>
      {processors.length > 0 && (
        <div>
          <Table
            pagination={false}
            dataSource={[
              ...depositList.filter((d: IDeposit) => !d.code.includes("Pages")),
            ]}
            loading={loading}
            columns={columns}
          />
        </div>
      )}
      {processors.length > 0 && (
        <>
          {depositList && depositList.length && (
            <Pagination type={"userTransfer"} totalPages={calcPages()} />
          )}
        </>
      )}
    </section>
  );
};
export default AdminDepositListBolivares;
