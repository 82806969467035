//
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import FETCH_USER_VALIDATION_DETAIL from 'src/graphql/query/userValidationDetail';
import { UserValidation } from 'src/types';
//import { UserValidation } from 'src/types';

const useQueryUserValidationDetail = (userId: string | undefined ) => {
  const { data = { }, loading } = useQuery(FETCH_USER_VALIDATION_DETAIL, {
    //variables: { depositId: 'ca485bfd-3cd9-4704-bb3a-8272e9e7fa81' ? parseInt('ca485bfd-3cd9-4704-bb3a-8272e9e7fa81', 10) : 10},
    variables: { userId: userId},
    fetchPolicy: "no-cache",
    pollInterval: 10000
  });
  
  const UserValidationDetail:UserValidation = useMemo(() => {
    /*const { UserValidationDetail = null } = (data as any)?.UserValidationDetail || data
    return UserValidationDetail ? UserValidationDetail : null*/
    return data.UserValidationDetail || null
  }, [data])

  return { UserValidationDetail, loading }
}

export default useQueryUserValidationDetail
