import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CgSpinner } from "react-icons/cg";
import { ToastContainer } from "react-toastify";
import CerifyIcon from "src/assets/images/certify.png";
import APIService from "src/services/Api";
import Notify from "src/toast/Notify";
import { IUserFields } from "src/types";
type iTyp = {
  verifiedIdentity: boolean;
  userId: string;
  change(): void
};
const UserValidationCompleted: React.FC<iTyp> = ({
  userId,
  verifiedIdentity,
  change
}) => {

  const { t } = useTranslation();
  const [showVerifiedIdentity, setShowVerifiedIdentity] = useState<boolean>(verifiedIdentity)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setShowVerifiedIdentity(verifiedIdentity)
  }, [verifiedIdentity])
  

  const handleForm = async() => {
    setLoading(true)
    const currentStatus = showVerifiedIdentity
    const update = await APIService.adminUpdateVerifiedIdentity({
      verifiedIdentity: !currentStatus,
      userid: userId as string,
    });
    if (update && update.data && update?.data?.status && update?.data?.data?.updated?.affected === 1) {
      Notify(t("updatedSuccess"));
      setShowVerifiedIdentity(!currentStatus)
      change()
    } else {
      if (update?.data?.message) {
        Notify(update.data.message);
      }
      Notify(t("updatedFailed"));
    }setLoading(true)
    setLoading(false)
  };
  return (
    <div className="py-4">
      <ToastContainer
        position="bottom-right"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="flex gap-2 items-center mb-2 ">
        {!loading 
        ? <img src={CerifyIcon} className="w-14" alt="certify" />
        : <CgSpinner size={62} className="animate-spin ml-10 text-green-admin-light" />
      }
        <div>
          <div className="text-xl">Validación de documentos</div>
          {showVerifiedIdentity 
          ? <div className="rounded-full  text-center px-2 py-2 text-white bg-green-admin-light">Validado con éxito</div> 
          : <div className="rounded-full  text-center px-2 py-2 text-white bg-secondary">No ha sido validado</div>}
        </div>
      </div>
       {!loading &&
        <div className="flex w-full text-center items-center justify-center font-mediun text-black">
          {!showVerifiedIdentity ? (
            <div onClick={!loading ? () => handleForm() : () => {}} className="cursor-pointer bg-gray-200 border border-black text-black font-medium border-rounded-sm px-6 py-4">Presione para marcar la identidad como completada</div>
          ) : (
            <div onClick={!loading ? () => handleForm() : () => {}} className="cursor-pointer bg-gray-200 border border-black text-black font-medium border-rounded-sm px-6 py-4">Presione para marcar la identidad como Incompleta</div>
          )}
        </div>
     
      }
    </div>
  );
};

export default UserValidationCompleted;
