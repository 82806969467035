
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import esLang from "./es.json"
import enLang from "./en.json"
const resources = {
    en: {
      translation: enLang,
    },
    es: {
      translation: esLang,
    },
  };
  
  //i18N Initialization
  const lang = localStorage.getItem('lang') || 'es'
  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: lang, //default language
      keySeparator: ".",
      interpolation: {
        escapeValue: false,
      },
    });
  
  export default i18n;