import type { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UseAuth } from 'src/auth/AuthContext';
import { validateRouteRole } from 'src/helpers/mixins';

interface GuardProps {
  children: ReactNode;
}

const GuardAdmin: FC<GuardProps> = ({ children }) => {

  const { isAuthenticated, user } = UseAuth();
  const location = useLocation()
  const role = user?.role || 'user'

  if (!isAuthenticated) { 
    return <Navigate to="/inicio" />
  }
  
  const enableRoutes = validateRouteRole(role)
  const { pathname } = location
  //if( !enableRoutes.includes('*') && !enableRoutes.includes(pathname)){
  if( !enableRoutes.includes('*') && !enableRoutes.some(r => pathname.includes(r))){
    return <Navigate to="/inicio" />
  }


  return (
    <>{children}</>
  )
}


GuardAdmin.propTypes = {
  children: PropTypes.node
};

export default GuardAdmin