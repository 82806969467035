
import React from "react";
import { AiFillNotification, AiOutlineNotification } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAppStateContext } from "src/auth/AppStateContext";
import useQueryListAdminNotification from "src/hooks/useQueryListAdminNotification";
import APIService from "src/services/Api";

const NavUserAdminNotification: React.FC = () => {
  const { paginationAN } = useAppStateContext()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { notificationAdminList } = useQueryListAdminNotification(paginationAN);
  const { adminNotifications,setPaginationAN } = useAppStateContext()
  const navigate = useNavigate()
 
  const handleRead =async() => {
    //await APIService.adminNotificationRead({})
    setPaginationAN(0)
    navigate('/admin')
  }
  return (
    <div>
      {adminNotifications && adminNotifications.length > 0 &&
        <div
          onClick={() => handleRead()}
          className="relative rounded-full px-2 py-1 hover:bg-gray-300 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-0  "
        >
          {adminNotifications.length === 0 && 
          
          <div>
            <AiOutlineNotification className="text-black" size={27} />
          </div>
          }
          {adminNotifications.some((n) => n.type === 'Alert') ? (
            <div className="rounded-full">
              <span className="notification-quantity border border-gray-100">
                {adminNotifications.filter((n) => !n.seen ).length || 1}
              </span>
              <AiFillNotification className="text-red-400" size={27} />
            </div>
          ) : (
            <div>
              <AiOutlineNotification className="text-black" size={27} />
            </div>
          )}
        </div>
      
      }
    </div>
  );
};

export default NavUserAdminNotification;
