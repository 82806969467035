type ParamCardService = {
  title: string;
  image: string;
  list: any;
};
const CardService: React.FC<ParamCardService> = ({ title, image, list }) => {
  return (
    <div className="max-w-sm mx-auto rounded bg-white overflow-hidden shadow-lg">
      <img className="w-full mx-auto" src={image} alt={title} />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{title} </div>
        {list}
      </div>
    </div>
  );
};

export default CardService;
