import { gql } from '@apollo/client';

const FETCH_NOTIFICATIONS_ADMIN_LIST = gql`
  query AdminNotificationList($skip: Int) {
    adminNotificationList(skip: $skip) {
      id
      message
      type
      link
      typeid
      seen
      channel
      status
      createdAt
      updatedAt
    }
  }
`;

export default FETCH_NOTIFICATIONS_ADMIN_LIST;
