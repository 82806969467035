import { useEffect } from "react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiMailSend } from "react-icons/bi";
import { CgSpinner } from "react-icons/cg";
import { GiCheckMark } from "react-icons/gi";
import APIService from "../../services/Api";
import { UseAuth } from "src/auth/AuthContext";
import { IoAlertCircleOutline } from "react-icons/io5";

type INeedVerifyAccount = {
  userId: string
} 
const NeedVerifyAccount = ({userId}:INeedVerifyAccount) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { user, setUserData } = UseAuth();
  const [verifiedAccount, setVerifiedAccount] = useState<boolean>(user?.verifyAccount || false)
  const sendEmail = async() => {
    setLoading(true);
    await APIService.sendEmailVerifyAccount({
      userId
    })
    setLoading(false)
    setSuccess(true)
  };

  useEffect(() => {
    window.setTimeout(() => {
      setSuccess(false)
    },5000)
  }, [success])
  
  useEffect(() => {
    if(!verifiedAccount){
      setInterval(async() => {
        (async() => {
          const query = await APIService.getUserInfo({userId})
          const user = query.data.data.user
          setVerifiedAccount(user.verifyAccount)
          setUserData(user?.id as string,user?.email || '',
          user?.fullname || '',
          user?.phone || '',
          user?.country || '',
          user?.birthday || '',
          user.verifyAccount,
          user.role,
          user.verifiedIdentity,
          user?.termsAndCondition || false)
        })(); 
        if(user?.verifyAccount || false){
          window.location.reload()
        }
      }, 7000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    {!verifiedAccount &&
      <div className="bg-green-admin text-center rounded-md py-4">
        <div className="flex justify-center mb-2">
          <IoAlertCircleOutline size={43} className="text-white" />
        </div>
        <div
          className="p-2 w-fit mx-auto bg-green-admin-light items-center text-white leading-none lg:rounded-full"
          role="alert"
        >
          <div className="font-semibold mr-2 text-center leading-6">
            {t("needverifyaccount")}
          </div>
        </div>

        <div className="text-white text-base font-medium tracking-wide mt-2">
          {t("verifyaccountinfo")}
        </div>
        <div className="text-white text-base font-medium tracking-wide mt-2">
          {t('spam')}
        </div>
        {!success ? (
          <Fragment>
            {!loading ? (
              <div
                onClick={() => sendEmail()}
                className="flex button-amber-two items-center gap-x-2 w-fit mx-auto cursor-pointer mt-4 text-black rounded-full uppercase px-2 py-1 text-xs font-bold"
              >
                {t("resendverify")} <BiMailSend size={33} />
              </div>
            ) : (
              <div className="flex items-center gap-x-2 w-fit mx-auto cursor-pointer mt-4 text-white rounded-full bg-green-admin-light uppercase px-2 py-1 text-xs font-bold">
                {t("sending")} <CgSpinner size={33} className="animate-spin" />
              </div>
            )}
          </Fragment>
        ) : (
          <div>
            <div
              onClick={() => sendEmail()}
              className="flex items-center gap-x-2 w-fit mx-auto cursor-pointer mt-4 text-white rounded-full bg-green-admin-light uppercase px-2 py-1 text-xs font-bold"
            >
              {t("emailsent")} <GiCheckMark size={23} />
            </div>
          </div>
        )}
      </div>
    }
    </>
  );
};
export default NeedVerifyAccount;
