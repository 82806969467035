
import Slider from "react-slick";
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import { useAppStateContext } from "src/auth/AppStateContext";
import { Proccesor } from "src/types";
import process from "process"
const Allies: React.FC = () => {

  const [allies, setAllies] = useState<Proccesor[]>([])
  const {processors,fetchProccesors } = useAppStateContext()

  const settings = {
    dots: false,
    arrows: true,
    edgeFriction: 0.35,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    slidesToShow: 7,
    slidesToScroll: 2,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1014,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  }
  useEffect(() => {
    setAllies(processors?.filter(p => p?.status ==='active'))
  
    if(!processors?.length){
      fetchProccesors()
    }
  }, [processors])
  
  const {t} = useTranslation()
  return (
    <div className="w-11/12 mx-auto my-24">
      <div className="text-lg md:text-xl xl:text-3xl w-fit mx-auto transition-all border-green-admin-light px-6 py-4 mb-6 md:mb-10 font-semibold tracking-wider text-center text-green-admin-light uppercase ">
        {t('processorsAvailable')}
      </div>
      <Slider {...settings}>
          {allies?.map((allie, key) =>
            <div
              className="mt-4 px-2 py-2"
              key={key}
            >
              <img src={`${process?.env?.REACT_APP_PROCESSOR_IMAGE || ''}${allie?.photoUrl}`} />
            </div>
          )}
        </Slider >
    </div>
  );
};

export default Allies;