import { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import LogoScreen from "./../assets/images/logoisf2.png"

const LoadingScreen: FC = () => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return (
    <div className="loading-screen-suspense" style={{ minHeight: '100%',width:'100%' }}> 
      <img src={LogoScreen} alt="loading"
        className="w-24 animate-pulse"
      />
    </div>
  );
};

export default LoadingScreen;
