import { useNavigate } from 'react-router-dom';


import { useEffect } from "react"
import { UseAuth } from "../auth/AuthContext"
import NavUser from "../components/System/NavUser"
import SidebarAdmin from '../components/Admin/SidebarAdmin';
import ChatAdmin from '../components/Admin/ChatAdmin';
import ChatClient from 'src/components/Chat/ChatClient';
import { getLogout } from 'src/helpers/mixins';

interface LayoutOptions {
  children: React.ReactElement
}
const LayoutAdmin = ({ children }: LayoutOptions) => {
  const { isAuthenticated, logout, user } = UseAuth()
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, [isAuthenticated])


  useEffect(() => {
    // 60 seg * 10 = 600 seg => 10 min
    const TIME_INACTIVO = 2400
    let n = TIME_INACTIVO
    const interval = setInterval(() => {
      window.onmousemove = function () {
        n = TIME_INACTIVO
      }
      n--
      if (n < 0) { 
        getLogout()
        return () => clearInterval(interval);
      }
    }, 1000)
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <NavUser />
      {(user?.role || 'user') === 'admin' ? <ChatAdmin /> : <ChatClient />}
      <div className="flex flex-row bg-gray-100 ">
        <div className="w-fit bg-green-admin-dark">
          <SidebarAdmin />
        </div>
        <div className="w-full bg-gray-100 mb-64">
          <div className=''>
            <div className='w-11/12 mx-auto flex'>
              {children}
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default LayoutAdmin