import { gql } from '@apollo/client';

const FETCH_NOTIFICATIONS_LIST = gql`
  query NotificationList($skip: Int) {
    notificationList(skip: $skip) {
      id
      message
      type
      link
      typeid
      seen
      channel
      status
      user {
        id
        fullname
        email
        photoUrl
        phone
        country
        verifyAccount
        isOnline
        loginAttemp
        birthday
        type
        options {
          status
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export default FETCH_NOTIFICATIONS_LIST;
