import React, {  Fragment, useEffect, useRef, useState } from "react"
import { CgSpinner } from 'react-icons/cg';
import { IoIosSend } from 'react-icons/io';
import { useTranslation } from "react-i18next";
import APIService from '../../services/Api';
import { UseAuth } from "../../auth/AuthContext"
import useQueryMessageList from "src/hooks/chat/useQueryMessageList";
import moment from 'moment';
import MyNameBubble from "../Shared/MyNameBubble";
import { message } from "antd";
import { getHourDate } from "src/helpers/mixins";

const Line = ({ divRef, setClose }: any) => {
  const { user } = UseAuth()
  const { messageList, loading } = useQueryMessageList("admin",user?.id || '')
  const { t } = useTranslation();
  const [inputMessage, setInputMessage] = useState<string>('')
  //const [localLoading, setLocalLoading] = useState<boolean>(false)

  useEffect(() => {
    divRef?.current?.scrollIntoView({ behavior: 'smooth' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageList])
    
 
  const sendMessage = async () => {
    if (inputMessage && inputMessage.trim().length > 0) {
      setInputMessage('')
      //setLocalLoading(true)
      const res =  await APIService.chatCreateMessage({
        userorigen: user?.id,
        userdestiny: 'admin',
        message: inputMessage
      });
      // const tempMsg = [...messageList]
      // tempMsg.push(created.data.data.created)
      // setMessageList(tempMsg)
      
      if(res.data.status){
        divRef?.current?.scrollIntoView({ behavior: 'smooth' });

      }
      //setLocalLoading(false)
      // const res = JSON.stringify({message:inputMessage,origen:user?.id,destiny:'admin'})
      //socket.emit('messages',res)
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setInputMessage(value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key==='Enter') {
      sendMessage()
    }
  };

  return (
    <div className="chatcontainer md:bottom-0 bottom-16 right-5">
      <div
        onClick={setClose}
        className='chatcontainer-head bg-emerald-600 text-white flex items-center justify-between px-4 cursor-pointer'>
        Agente de pagos  <span className='font-semibold'>X</span>
      </div>
      <div className="chatcontainer-body text-sm">
        {!loading ?
            <>
        {messageList.map((m: any, key: number) => (
          <div>
            {m.userDestinyId === "admin" ? (
              <div className='msg-user pb-1 flex text-left justify-end '>
                <div className="msg bg-gray-300 px-1 py-1 rounded-lg">
                  <div className="relative ml-2 mb-2">
                    <MyNameBubble name={user?.fullname || '- -'} />
                  </div>
                  <div className="ml-6" style={{ marginTop: "-5px"}}>{m.message}</div>
                </div>
              </div>
            ) : (
              <div className='msg-user pb-1 flex justify-start '>
                <div className="msg bg-gray-300 px-1 py-1 rounded-lg">
                  <div className="relative ml-2 mb-2">
                    <MyNameBubble name={'AGENTE PAGOS'} />
                  </div>
                  <div className="ml-6" style={{ marginTop: "-5px"}}>{m.message}</div>
                </div>
              </div>
            )}
            {key+1 === messageList.length &&
              <div className={`msg-leader pb-1 flex flex-col text-${m.userDestinyId !== "admin" ? "letf" : "right"}`}>
                <span className='msg-date'>{moment.utc(parseInt(m.createdAt, 10)).local().format("YYYY-MM-DD")}{" "} {getHourDate(m.createdAt)} {/*moment.utc(parseInt(m.createdAt, 10)).local().format("HH:MM:SS")*/}</span>
              </div>
            
            }
          </div>
        ))}
            </>
          :

          <Fragment>
          <div className="msg-leader w-full  pb-1 flex flex-col text-left justify-start  animate-pulse">
            {Array.from(new Array(Math.floor(Math.random() * 4))).map(
              (i) => (
                <div className="px-1 py-1 rounded-lg w-full">
                  <div className="h-7 bg-gray-200 rounded-lg dark:bg-gray-400 w-8/12"></div>
                </div>
              )
            )}
          </div>
          <div className="msg-user w-full pb-1 flex flex-col text-left justify-end  animate-pulse">
            {Array.from(new Array(Math.floor(Math.random() * 4))).map(
              (i) => (
                <div className="px-1 flex justify-end py-1 rounded-lg">
                  <div className="h-7 bg-gray-200 rounded-lg dark:bg-gray-400 w-8/12"></div>
                </div>
              )
            )}
          </div>
          <div className="msg-leader w-full  pb-1 flex flex-col text-left justify-start  animate-pulse">
            {Array.from(new Array(Math.floor(Math.random() * 4))).map(
              (i) => (
                <div className="px-1 py-1 rounded-lg">
                  <div className="h-7 bg-gray-200 rounded-lg dark:bg-gray-400 w-8/12"></div>
                </div>
              )
            )}
          </div>
        </Fragment>
        }

        <div ref={divRef} />
        {/* {<div className='msg-user pb-1 flex text-left justify-end '>
          <div className="msg bg-gray-300 px-1 py-1 rounded-lg">
            Gracias por la respuesta
          </div>
        </div>} */}
      </div>
      <div className="chatcontainer-input flex items-center">
        <input type="text"
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          value={inputMessage}
          placeholder={t('chatplaceholder')} />
                    <button onClick={() => sendMessage()} className='bg-gray-300 cursor-pointer flex items-center justify-center'>
            <IoIosSend size={27} />
          </button>
        {/*!localLoading ?
          <button onClick={() => sendMessage()} className='bg-gray-300 cursor-pointer flex items-center justify-center'>
            <IoIosSend size={27} />
          </button>
          :
          <button className='bg-gray-300 cursor-pointer flex items-center justify-center'>
            <CgSpinner size={27} className="animate-spin" />
          </button>
      */}
      </div>
    </div>
  )
}

export default Line