import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import LayoutAdmin from "src/Layouts/Admin";
import Avatar from "src/components/System/Avatar";
import { classNames, getDollarOneImg, getHourDate } from "src/helpers/mixins";
import useQueryUserValidationDetail from "src/hooks/useQueryUserValidationDetail";
import identificationIcon from "src/assets/images/identification.png";
import countries from "./../../data/countries.json";
import SelfieIcon from "src/assets/images/selfieBlack.png";
import ConfirmDelete from "src/components/Shared/ConfirmDelete";
import Notify from "src/toast/Notify";
import { ToastContainer } from "react-toastify";
import APIService from "src/services/Api";
import PreviewImage from "src/components/Shared/PreviewImage";
import SkeletonDepositDetail from "src/components/Shared/Skeletons/SkeletonDepositDetail";
import { UserValidation } from "src/types";
import { CgSpinner } from "react-icons/cg";
import UserValidationCompleted from "src/components/Admin/UserValidationCompleted";
import UserCard from "src/components/Shared/UserCard";
import KycStatus from "src/components/Shared/KycStatus";

/*type optionStatus = {
  value: string;
  label: string;
};*/
type FormOne = {
  statusFormOne: string;
  commentFormOne?: string;
};
type FormTwo = {
  statusIdentification: string;
  commentFormTwo?: string;
};
type FormThree = {
  statusSelfie: string;
  commentFormThree?: string;
};
type iDelete = {
  type: string;
  id: string;
};
const AdminUserVerificationDetail: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [touchedForm, setTouchedForm] = useState<boolean>(false)
  const [enablePreview, setEnablePreview] = useState<boolean>(false);
  const [currentPreview, setCurrentPreview] = useState<string>();
  const { UserValidationDetail, loading } = useQueryUserValidationDetail(id);
  const [loadingFormOne, setLoadingFormOne] = useState<boolean>(false);
  const [loadingFormTwo, setLoadingFormTwo] = useState<boolean>(false);
  const [loadingFormThree, setLoadingFormThree] = useState<boolean>(false);
  const [loadingDispatch, setLoadingDispatch] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [readyForDelete, setReadyForDelete] = useState<iDelete>();
  const [formOne, setFormOne] = useState<FormOne>();
  const [formTwo, setFormTwo] = useState<FormTwo>();
  const [FormThree, setFormThree] = useState<FormThree>();
  const [validationData, setValidationData] =
    useState<UserValidation>(UserValidationDetail);
  useEffect(() => {
    setFormOne({
      statusFormOne: UserValidationDetail?.statusFormOne,
      commentFormOne: UserValidationDetail?.commentFormOne,
    });
    setFormTwo({
      statusIdentification: UserValidationDetail?.statusIdentification,
      commentFormTwo: UserValidationDetail?.commentFormTwo,
    });
    setFormThree({
      statusSelfie: UserValidationDetail?.statusSelfie,
      commentFormThree: UserValidationDetail?.commentFormThree,
    });
    setValidationData(UserValidationDetail);

  }, [UserValidationDetail]);

  /* const [optionStatuses] = useState<optionStatus[]>([
    { value: "empty", label: "vacio" },
    { value: "waiting", label: "en verificación" },
    { value: "rejected", label: "rechazado" },
    { value: "completed", label: "completado" },
  ]);*/

  const handleQuestion = (type: string, id: string) => {
    setShowConfirmDelete(true);
    setReadyForDelete({ type, id });
  };
  const handleSubmitForm = async (type: string) => {
    const data = { id: validationData.id } as any;
    switch (type) {
      case "formOne":
        setLoadingFormOne(true);
        data.type = "formOne";
        data.statusFormOne = formOne?.statusFormOne;
        data.commentFormOne = formOne?.commentFormOne;
        break;
      case "formTwo":
        setLoadingFormTwo(true);
        data.type = "formTwo";
        data.statusIdentification = formTwo?.statusIdentification;
        data.commentFormTwo = formTwo?.commentFormTwo;
        break;
      case "formThree":
        setLoadingFormThree(true);
        data.statusSelfie = FormThree?.statusSelfie;
        data.commentFormThree = FormThree?.commentFormThree;
        data.type = "formThree";
    }
    if (validationData.id) {
      const update = await APIService.adminUpdateVerification(data);
      if (update.data.status) {
        setTouchedForm(true)
        Notify("Actualizado.");
      } else {
        Notify("No fue posible actualizar.");
      }
    }
    setLoadingFormThree(false);
    setLoadingFormTwo(false);
    setLoadingFormOne(false);
  };

  const dispatchEmailNotify = async() => {
    setLoadingDispatch(true)
    await APIService.kycEmailNotification({validationId:UserValidationDetail.id})
    setLoadingDispatch(false)
    setTouchedForm(false)
  }
  const handleRemovePicture = async () => {
    setShowConfirmDelete(false);
    const Idel = await APIService.adminRemoveSomePic({ ...readyForDelete });

    if (Idel.data.status) {
      Notify("Eliminada");
      if (readyForDelete?.type === "formTwo") {
        const tempPho = validationData.uservalidationphoto.filter(
          (p) => p.id !== readyForDelete.id
        );
        setValidationData({ ...validationData, uservalidationphoto: tempPho });
      } else if (readyForDelete?.type === "formThree") {
        setValidationData({ ...validationData, selfiePhoto: "" });
      }
    } else {
      Notify("No es posible eliminar");
    }
    setReadyForDelete({ type: "", id: " 0" });
  };

  const handleTextArea = (value: string, type: string) => {
    switch (type) {
      case "formOne":
        setFormOne({
          commentFormOne: value,
          statusFormOne: formOne?.statusFormOne || "empty",
        });
        break;
      case "formTwo":
        setFormTwo({
          commentFormTwo: value,
          statusIdentification: formTwo?.statusIdentification || "empty",
        });
        break;
      case "formThree":
        setFormThree({
          commentFormThree: value,
          statusSelfie: FormThree?.statusSelfie || "empty",
        });
        break;
    }
  };

  const handleSelect = (e: string, type: string) => {
    switch (type) {
      case "formOne":
        setFormOne({ ...formOne, statusFormOne: e });
        break;
      case "formTwo":
        setFormTwo({ ...formOne, statusIdentification: e });
        break;
      case "formThree":
        setFormThree({ ...formOne, statusSelfie: e });
        break;
    }
  };

  const handlePreview = (photo: string) => {
    setCurrentPreview(photo);
    setEnablePreview(true);
  };
  return (
    <LayoutAdmin>
      <section className="w-full pt-20">
        <div className={classNames(touchedForm ? '-right-4':'-right-[30rem]',  "fixed-notificate-kyc transition-all  font-medium bg-green-admin-light text-white")}>
          <div>
            ¿Terminaste de actualizar los estados de KYC?
          </div>
          No olvides disparar email de notificación
          {loadingFormOne || loadingFormThree || loadingFormTwo || loadingDispatch ?
            <div  
            className="font-semibold bg-white px-2 py-1 text-black w-fit mt-2 cursor-pointer flex items-center gap-2"
            onClick={() => dispatchEmailNotify()} >
            Cargando... <CgSpinner className="animate-spin" size={22} />
          </div>
          :
          <div className="flex items-center justify-between">
            <div  
              className="font-semibold bg-white px-2 py-1 text-black w-fit mt-2 cursor-pointer"
              onClick={() => dispatchEmailNotify()} >
              Disparar
            </div>

            <div  
              className="font-semibold bg-white px-2 py-1 text-black w-fit mt-2 cursor-pointer"
              onClick={() => setTouchedForm(false)} >
              Cerrar
            </div>
          </div>
          }
        </div>
        {enablePreview && (
          <PreviewImage
            isOpen={enablePreview}
            closeModal={() => setEnablePreview(false)}
            img={currentPreview || "-"}
          />
        )}
        {showConfirmDelete && (
          <ConfirmDelete
            closeModal={() => setShowConfirmDelete(false)}
            handleRemove={() => handleRemovePicture()}
          />
        )}
        <ToastContainer
          position="bottom-right"
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div
          className="h-64 w-full bg-cover rounded-md"
          style={{ backgroundImage: `url(${getDollarOneImg()})` }}
        ></div>
        <div className=""></div>

        {!loading && validationData ? (
          <section className="relative -top-48 bg-white rounded-md px-4 py-4 w-full md:w-10/12 mx-auto rounded-t-xl">
            <div className="grid grid-cols-1 md:grid-cols-2 border-b border-gray-300 border-dashed pb-2  mb-4 ">
              <div className="text-lg  grid-col-1 font-medium flex items-center gap-x-2">
                <div
                  onClick={() => navigate(-1)}
                  className="font-semibold cursor-pointer"
                >
                  <BsFillArrowLeftSquareFill
                    size={29}
                    className="text-dark-green"
                  />
                </div>
                Certificado de cuenta / {validationData.user.fullname}
              </div>
              <div className="font-medium grid-col-1 flex items-center justify-between">
                <div>
                  {t("date")} {t("creation")}
                </div>
                <div>
                  {moment
                    .utc(parseInt(validationData?.createdAt, 10))
                    .local()
                    .format("YYYY-MM-DD")}{" "}
                </div>
                <div className="font-medium">
                  {t("hour")}: {getHourDate(validationData?.createdAt)}
                  {/*moment
                .utc(parseInt(depositDetail?.createdAt, 10))
                .local()
              .format("HH:MM:SS")*/}{" "}
                </div>
              </div>
             
            </div>
            {validationData && (
              <>
                <div className="grid grid-cols-1 gap-y-2">
                  <div className="w-full border border-gray-300 flex items-center py-2 px-2 rounded-md bg-white">
                    <div>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 w-full">
                      <div className="col-span-2 gap-5  er">
                        <div className="flex items-center py-2 justify-center">
                          <UserCard 
                            country={validationData?.country|| '-'}
                            city={validationData?.city || '-'}
                            address={validationData?.address || '-'}
                            identificationType={validationData?.identificationType || '-' }
                            id={validationData?.user.id || '-'}
                            email={validationData?.user.email || '-'}
                            fullname={validationData?.user?.fullname}
                            phone={ countries.find( (c) => c.code === (validationData?.user.country || "-"))?.dial_code+' '+validationData?.user.phone || '-'}
                            verifyAccount={validationData?.user?.verifyAccount || false} 
                            verifiedIdentity={validationData?.user?.verifiedIdentity || false} 
                            documentNumber={validationData?.documentNumber || 'Sin asignar'}
                          />
                        </div>
                      </div>
                      <div className="col-span-2 flex flex-col gap-2 ">
                        <div>
                          <div className="flex items-center gap-4">
                            Asignar un estado
                            <KycStatus formNumber="formOne" newStatus={handleSelect} currentStatus={formOne?.statusFormOne || 'empty'} />
                          </div>
                          {/*<select
                            value={formOne?.statusFormOne}
                            className="bg-stone-200 font-medium border w-11/12 border-stone-200 rounded-lg px-2 py-2"
                            onChange={(e) =>
                              handleSelect(e.target.value, "formOne")
                            }
                          >
                            {optionStatuses.map((o, key) => (
                              <option key={key.toString()} value={o.value}>
                                {o.label}
                              </option>
                            ))}
                          </select>*/}
                        </div>
                        <div>
                          Mensaje (opcional)
                          <textarea
                            onChange={(e) =>
                              handleTextArea(e.target.value, "formOne")
                            }
                            className="bg-stone-200 border w-11/12 border-stone-200 rounded-lg px-2 py-2"
                            rows={2}
                            defaultValue={validationData.commentFormOne}
                            cols={10}
                          ></textarea>
                        </div>
                        <div>
                          <button
                            onClick={() =>
                              loadingFormOne
                                ? null
                                : handleSubmitForm("formOne")
                            }
                            className="px-5 py-2 rounded-lg mt-2 text-sm text-white bg-green-admin-light "
                          >
                            {loadingFormOne ? (
                              <div className="flex gap-2">
                                Cargando..{" "}
                                <CgSpinner size={20} className="animate-spin" />
                              </div>
                            ) : (
                              "Actualizar"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full border border-gray-300 flex items-center py-2 px-2 rounded-md bg-white">
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 w-full">
                      <div className="col-span-2 gap-5 flex justify-center items-center">
                        <div>
                          <img
                            src={identificationIcon}
                            alt="certificate"
                            className="w-20"
                          />
                        </div>
                        <div>
                          <div className="font-medium text-base">
                            Tipo de documento:{" "}
                            {validationData?.identificationType || "-"}
                          </div>
                          <div className="font-medium"></div>
                          <div>
                            Imagenes recibidas:{" "}
                            {validationData?.uservalidationphoto?.length || 0}{" "}
                          </div>
                          <div className="text-xs tracking-wide py-2">
                            {(validationData?.uservalidationphoto?.length ||
                              0) > 0
                              ? `Presione para ver en grande`
                              : `El usuario no ha enviado documentación hasta el momento.`}
                          </div>
                          <div className="flex gap-2">
                            {validationData?.uservalidationphoto?.length > 0 &&
                              validationData?.uservalidationphoto?.map(
                                (photo, key) => (
                                  <div key={key.toString()}>
                                    <div
                                      onClick={() =>
                                        handlePreview(
                                          `${process.env.REACT_APP_USERVALIDATION}${photo.photoUrl}`
                                        )
                                      }
                                      className="w-20 cursor-pointer bg-cover h-20 bg-gray-200"
                                      style={{
                                        backgroundImage: `url(${process.env.REACT_APP_USERVALIDATION}${photo.photoUrl})`,
                                      }}
                                    ></div>
                                    <button
                                      className="w-20 py-2 rounded-lg mt-2 text-xs text-white bg-green-admin-light "
                                      onClick={() =>
                                        handleQuestion("formTwo", photo.id)
                                      }
                                    >
                                      Eliminar
                                    </button>
                                  </div>
                                )
                              )}
                            {/*<div className="w-20 cursor-pointer h-20 bg-gray-200 "></div>*/}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 flex flex-col gap-2 ">
                        <div>
                          <div className="flex items-center gap-4">
                            Asignar un estado
                            <KycStatus formNumber="formTwo" newStatus={handleSelect}  currentStatus={formTwo?.statusIdentification || 'empty'} />
                          </div>
                          {/*<select
                            value={formTwo?.statusIdentification}
                            className="bg-stone-200 border w-11/12 border-stone-200 rounded-lg px-2 py-2"
                            onChange={(e) =>
                              handleSelect(e.target.value, "formTwo")
                            }
                          >
                            {optionStatuses.map((o, key) => (
                              <option key={key.toString()} value={o.value}>
                                {o.label}
                              </option>
                            ))}
                          </select>*/}
                        </div>
                        <div>
                          Mensaje (opcional)
                          <textarea
                            onChange={(e) =>
                              handleTextArea(e.target.value, "formTwo")
                            }
                            className="bg-stone-200 border w-11/12 border-stone-200 rounded-lg px-2 py-2"
                            rows={2}
                            cols={10}
                            defaultValue={validationData.commentFormTwo}
                          ></textarea>
                        </div>
                        <div>
                          <button
                            onClick={() =>
                              loadingFormTwo
                                ? null
                                : handleSubmitForm("formTwo")
                            }
                            className="px-5 py-2 rounded-lg mt-2 text-sm text-white bg-green-admin-light "
                          >
                            {loadingFormTwo ? (
                              <div className="flex gap-2">
                                Cargando..{" "}
                                <CgSpinner size={20} className="animate-spin" />
                              </div>
                            ) : (
                              "Actualizar"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full border border-gray-300 flex items-center py-2 px-2 rounded-md bg-white">
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 w-full">
                      <div className="col-span-2 gap-5 flex justify-center items-center">
                        <div>
                          <img src={SelfieIcon} alt="selfie" className="w-16" />
                        </div>
                        <div>
                          {validationData?.selfiePhoto &&
                          validationData?.selfiePhoto?.length > 0 ? (
                            <>
                              <div className="font-normal text-base">
                                Selfie recibida
                              </div>
                              <div className="font-medium"></div>
                              <div>Imagenes recibidas: 1</div>
                              <div className="text-xs tracking-wide py-2">
                                Presione para ver en grande
                              </div>
                              <div className="flex gap-2">
                                <div>
                                  <div>
                                    <div
                                      onClick={() =>
                                        handlePreview(
                                          `${process.env.REACT_APP_USERVALIDATION}${validationData.selfiePhoto}`
                                        )
                                      }
                                      className="w-20 cursor-pointer bg-cover h-20 bg-gray-200"
                                      style={{
                                        backgroundImage: `url(${process.env.REACT_APP_USERVALIDATION}${validationData.selfiePhoto})`,
                                      }}
                                    ></div>
                                    <button
                                      className="w-20 py-2 rounded-lg mt-2 text-xs text-white bg-green-admin-light "
                                      onClick={() =>
                                        handleQuestion(
                                          "formThree",
                                          validationData?.id || "-"
                                        )
                                      }
                                    >
                                      Eliminar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="font-normal text-base">
                                Selfie recibida
                              </div>
                              <div className="font-medium"></div>
                              <div>Imagenes recibidas: 0</div>
                              <div className="text-xs tracking-wide py-2">
                                El usuario no ha enviado selfie hasta el
                                momento.
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-span-2 flex flex-col gap-2 ">
                        <div>
                          <div className="flex items-center gap-4">
                            Asignar un estado
                            <KycStatus formNumber="formThree" newStatus={handleSelect} currentStatus={FormThree?.statusSelfie || 'empty'} />
                          </div>
                          {/*<select 
                            value={FormThree?.statusSelfie}
                            className="bg-stone-200 border w-11/12 border-stone-200 rounded-lg px-2 py-2"
                            onChange={(e) =>
                              handleSelect(e.target.value, "formThree")
                            }
                          >
                            {optionStatuses.map((o, key) => (
                              <option key={key.toString()} value={o.value}>
                                {o.label}
                              </option>
                            ))}
                          </select>*/}
                        </div>
                        <div>
                          Mensaje (opcional)
                          <textarea
                            onChange={(e) =>
                              handleTextArea(e.target.value, "formThree")
                            }
                            className="bg-stone-200 border w-11/12 border-stone-200 rounded-lg px-2 py-2"
                            rows={2}
                            cols={10}
                            defaultValue={validationData?.commentFormThree}
                          ></textarea>
                        </div>
                        <div>
                          <button
                            onClick={() =>
                              loadingFormThree
                                ? null
                                : handleSubmitForm("formThree")
                            }
                            className="px-5 py-2 rounded-lg mt-2 text-sm text-white bg-green-admin-light "
                          >
                            {loadingFormThree ? (
                              <div className="flex gap-2">
                                Cargando..{" "}
                                <CgSpinner size={20} className="animate-spin" />
                              </div>
                            ) : (
                              "Actualizar"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full border border-gray-300 flex items-center py-2 px-2 rounded-md bg-white">
                    <div className="grid grid-cols-2  gap-2">
                      <div className="col-span-1 flex items-center">
                        <div className="mr-2">
                          <Avatar
                            styles={"w-14 h-14 text-lg "}
                            name={validationData?.user?.fullname}
                          />
                        </div>
                        <div>
                          <div>Presione el nombre o email para visitar</div>
                          <div
                            onClick={() =>
                              navigate(
                                `/admin/usuario/detalle/${validationData?.user.id}`
                              )
                            }
                            className="font-semibold cursor-pointer underline text-sky-600 mb-1"
                          >
                            Nombre: {validationData?.user.fullname}
                          </div>
                          <div
                            onClick={() =>
                              navigate(
                                `/admin/usuario/detalle/${validationData?.user.id}`
                              )
                            }
                            className="font-normal text-sky-600 cursor-pointer"
                          >
                            Email: {validationData?.user.email}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 flex flex-col items-center">
                        <UserValidationCompleted
                          userId={validationData.user.id}
                          verifiedIdentity={
                            validationData.user.verifiedIdentity
                          }
                          change={ () => setTouchedForm(true)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        ) : (
          <div className="relative -top-32">
            <SkeletonDepositDetail />
          </div>
        )}
      </section>
    </LayoutAdmin>
  );
};

export default AdminUserVerificationDetail;
