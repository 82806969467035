import Navbar from "../components/Landing/Navbar"
import { Fragment } from "react"
import AcademyList from "src/components/Academy/AcademyList"
const AcademyHomePage = () => {

  return (
    <Fragment>
      <Navbar />
      <div style={{background:"linear-gradient(45deg, white,#f2f2f2)"}} className="w-full">
        <section className="pt-0 pb-28 mx-auto ">
          <AcademyList type={'home'} />
          {/*<div className="grid grid-cols-1 md:grid-cols-3 gap-5">
            {courses?.map((course,index) => (
              <div 
              className={classNames( getTypeClass(index+1),"col-span-1 py-10 px-4 w-full border border-gray-200 rounded-lg relative flex flex-col")}>
                <div>
                  <div className="text-white text-center font-semibold text-xs mb-5">
                    {course.position}
                  </div>
                  <h1 className="text-white font-bold text-xl text-center">
                    {course.name}
                  </h1>
                  <img 
                    src={shadowLight}
                    className="shadow-offert-card" 
                  />
                  <img 
                    src={bookIcon} 
                    className="w-16 mx-auto" 
                  />
                  <Link to={"/academia/detalle"} >
                    <div className="mx-auto my-2 border border-white rounded-md w-fit px-2 cursor-pointer bg-white text-black">
                      ENTRAR
                    </div>
                  </Link>
                </div>
                <div className="flex flex-wrap items-center gap-2 mt-3">
                  {course.lesson.map(l => (
                    <div className="rounded-full px-2 text-white py-2 border border-white text-center font-semibold"
                      style={{ fontSize: "10px" }}>
                      {l.name}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>*/}
        </section>
      </div>
    </Fragment>
  )
}
export default AcademyHomePage