import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import deleteIcon from "src/assets/images/deletePink.png";
type ConfirmProps = {
  closeModal(): void;
  handleRemove(): void;
};

const ConfirmDelete:React.FC<ConfirmProps> = ({closeModal,handleRemove}) => {
  return (
    <div>
    <div>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                 className="w-full mt-16 max-w-3xl transform overflow-hidden rounded-2xl bg-gray-200 pt-6 text-left align-middle shadow-xl transition-all"
                 >
                  <div className="relative mt-2 border-t border-gray-200">
                     
                    <div className={`max-h-96 overflow-auto bg-white`} >
                      <div className="w-full grid grid-cols-2 py-10">
                        <div className="grid-col-1 flex items-center justify-center">
                          <img src={deleteIcon} alt="deleteicon" className="w-20"/>
                        </div>
                        <div className="grid-col-1 border-l-4 border-gray-200 border-dashed flex flex-col items-center justify-center">
                          ¿Confirma eliminar?
                          <div className="mb-2 text-xs py-4">
                            Esta acción no se podra revertir
                          </div>
                          <div className="flex gap-10">
                            <div onClick={() => handleRemove()} className="bg-gray-200 px-1 py-1 rounded-md w-10 text-center cursor-pointer hover:bg-gray-300">
                              Si
                            </div>
                            <div onClick={() => closeModal()}className="bg-gray-200 px-1 py-1 rounded-md w-10 text-center cursor-pointer hover:bg-gray-300">
                              No
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
    </div>
  );
};

export default ConfirmDelete