import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import FETCH_CHAT_USER_ONLINE from 'src/graphql/query/chat/chatUserOnline';

const useQueryChatUserOnline = () => {
  const { data = {}, loading } = useQuery(FETCH_CHAT_USER_ONLINE, {
    fetchPolicy: "no-cache",
    pollInterval: 10000
  })

  const chatUserOnline = useMemo(() => {
    const { ChatUserOnline = null } = data
    return ChatUserOnline ? ChatUserOnline : []
  }, [data])

  return { chatUserOnline, loading }
}

export default useQueryChatUserOnline

