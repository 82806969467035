import { useTranslation } from 'react-i18next';
import { FiAlertTriangle } from 'react-icons/fi'
const FormRequired = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center text-red-600 text-sm">
      <FiAlertTriangle className='mr-2' /> {t('requiredfield')}
    </div>
  )
}
export default FormRequired