import { useTranslation } from "react-i18next";
import facebook from "../../assets/images/socials/facebook.png"
import instagram from "../../assets/images/socials/instagram.png"
import whatsapp from "../../assets/images/socials/whatsapp.png"
import telegram from "../../assets/images/socials/telegram.png"
//import logoFooter from "../../assets/images/logoisfnav.png"
import { Link } from "react-router-dom";
import { visitWhatsapp } from "../Shared/Whatsapp";

const Footer:React.FC = () => {
  const { t } = useTranslation();
 
  return (
    <footer className="bg-gradient-green border-t border-gray-300">
      <div
        className="p-2 w-full shadow grid grid-cols-1 lg:grid-cols-3 md:items-center justify-center md:justify-between md:p-6"
      >
        <div
          className="text-base w-full items-center text-center flex-wrap flex justify-center flex-col md:flex-row"
        >
          {/*<img alt="logofooter" className="w-56 md:w-72 mx-auto" src={logoFooter} />*/}
          <div className="text-sm lg:text-base  font-semibold text-green-admin-light w-full lg:w-fit">
            <i className="fa-solid fa-copyright mr-2"></i>
            Copyright © {new Date().getFullYear()}
            <div className="px-2">
              {t("footer.allrights")} {/*isfcambios.*/}
            </div>
          </div>
        </div>

        <div
        style={{textShadow:"0px 0.5px 1px white"}}
          className="text-sm lg:text-base  flex flex-col text-center justify-center items-center text-black font-normal mt-4 lg:mt-0 px-4 text-left w-full"
        >
          <h1 className="font-semibold text-black">{t('footer.openinghours')}</h1>
          <div className="font-semibold mt-2">
            {t('footer.mondaysaturday')}
          </div>
          {t('footer.of')} 8:00 am {t('footer.to')} 12:00 pm & {t('footer.of')} 2:00 pm {t('footer.to')} 6:00 pm
          <div className="font-semibold mt-4">
          {t('footer.sunday')}
          </div>
          {t('footer.of')} 8:00 am {t('footer.to')} 2:00 pm
          {/*t("footer.staff1")*/}
        </div>

        <div className="w-full mt-4 lg:mt-0 ">
          <ul
            className="flex flex-row justify-center md:justify-center items-center mt-3 text-5xl text-gray-d sm:mt-0 gap-4"
          >
            <li>
              <a className="cursor-pointer" href="https://www.facebook.com/ISFCAMBIOS" target="_blank">
                <img alt="facebook" src={facebook} className="w-10 h-10" />
              </a>
            </li>
            <li>
              <a className="cursor-pointer" href="https://www.instagram.com/isfcambios/?hl=es" target="_blank">
                <img alt="instagram" src={instagram} className="w-10 h-10" />
              </a>
            </li>
            <li>
              <img alt="telegram" onClick={() => visitWhatsapp()} src={whatsapp} className="w-10 h-10 cursor-pointer" />
            </li>
            <li>
              <a className="cursor-pointer" href="https://t.me/ISFcambiosadmin" target="_blank">
                <img alt="telegram" src={telegram} className="w-10 h-10 cursor-pointer" />
              </a>  
            </li>
          </ul>
            <div className="text-sm lg:text-base  flex flex-col items-center justify-center font-semibold gap-4">
              <div className="link-text-black text-center">
              <Link to='/terminos-y-condiciones'>
                {t("footer.terms")}.
              </Link>
            </div>
            <div className="link-text-black text-center">
              <Link to='/politica-de-privacidad'>
                {t("footer.policiy")}.
              </Link>
            </div>

            </div>

        </div>
      </div>
    </footer>
  )
}
export default Footer