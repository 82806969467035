
import LayoutAdmin from "../../Layouts/Admin";
import AdminDepositListBolivares from "src/components/Admin/Deposit/DepositListBolivares";
const AdminDepositBolivares = () => {
  return (
    <LayoutAdmin>
      <section className="w-full">
        <div className="mt-20">
          <AdminDepositListBolivares />
        </div>
      </section>
    </LayoutAdmin>
  )
}
export default AdminDepositBolivares


 