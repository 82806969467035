import { useParams } from "react-router-dom"
import AcademyDetail from "src/components/Academy/AcademyDetail"


const AcademyDetailPage: React.FC = () => {
    const { id } = useParams()
  return (
      <section  className="pt-10 pb-28 mx-auto w-11/12 md:w-10/12 mx-auto">
        {id &&
          <AcademyDetail id={id} type={'userlogged'} />
        }
      </section>
  )
}
export default AcademyDetailPage