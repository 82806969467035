import { ApolloClient, ApolloLink, InMemoryCache, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client'
import { endPoint } from 'src/services/Api';
//---------------Apollo Client variables set-up-------------------

const baseURL = process.env.REACT_APP_API || `http://132.148.74.56:5555`


const authToken = () => localStorage.getItem("authToken") || ''

const activityMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-ISF-auth-token': authToken(),
    }
  }));
  return forward(operation);
})


export const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: true,
  link: from([
    activityMiddleware,
    createUploadLink({
      uri: `${endPoint}/graphql`,
      fetch
    })
  ]),
});
//---------------End Apollo Client set-up-------------------

export { baseURL }