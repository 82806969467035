import { BiSupport } from "react-icons/bi";
import { Link } from "react-router-dom";
import { SiTelegram } from "react-icons/si";
import { visitWhatsapp } from "../Shared/Whatsapp";
import whatsapp from "../../assets/images/whatsapp.png";
import { useTranslation } from "react-i18next";
const TournamentInvitation: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="bg-tournament bg-fresh text-white text-shadow flex flex-col items-start px-10 justify-center">
        <div className="bg-black-tournament font-bold py-8 px-8 rounded-md text-center my-10 w-11/12 md:w-10/12 mx-auto">
          <div className="text-4xl text-shadow-general">
            ¡Inscríbete en el Torneo de Trading y gana parte de $10,000 USD!
          </div>
          <div className="font-bold text-center">
            Demuestra tu talento, compite y asegura tus ganancias.
            ¡No te quedes fuera!
          </div>
          <div className="mt-4 text-left font-medium text-base text-shadow-general">
            Estimados traders, Nos complace anunciar el Gran Torneo de Trading,
            una emocionante oportunidad para poner a prueba tus habilidades y
            competir con los mejores en el mundo del trading.
          </div>
          <div className="mt-4 text-left font-medium text-base text-shadow-general">
            Únete a nosotros para una jornada llena de adrenalina, aprendizaje y
            la posibilidad de ganar increíbles premios. Este torneo está abierto
            a traders de todos los niveles, desde principiantes hasta expertos.
            No importa si operas en Forex, acciones o criptomonedas, ¡todos son
            bienvenidos!
          </div>

          <div className="mt-10">
            <Link
              to="https://torneosinfronteras.com/"
              target="_blank"
              className="bg-red-700 hover:text-white text-white w-fit mx-auto text-2xl font-bold py-2 px-6 rounded-md shadow-md "
            >
              Quiero participar
            </Link>
          </div>
        </div>

        <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-4 py-2 w-11/12 mx-auto">
          <div className="text-center rounded-md py-8">
            <div className="flex justify-center items-center ">
              <BiSupport className="text-black" size={44} />
            </div>
            <div className="font-normal text-white-with-shadow bg-[#0000003b] w-fit mx-auto rounded-md px-4 mt-2 text-xl mb-7">
              {t("telegramjoin")}
            </div>
            <a
              target="_blank"
              href="https://t.me/ISFcambiosadmin"
              className="border border-stone-300 bg-white text-black px-4 py-1 font-medium hover:bg-dark-green hover:text-white rounded-sm transition-none text-base"
              rel="noreferrer"
            >
              {t("enter")}
            </a>
          </div>
          <div className="text-center rounded-md py-8">
            <div className="flex justify-center items-center text-black">
              <SiTelegram size={44} />
            </div>
            <div className="font-normal text-white-with-shadow bg-[#0000003b] w-fit mx-auto rounded-md px-4 mt-2   text-xl mb-7">
              {" "}
              {t("forexjoin")}{" "} 
            </div>
            <a
              target="_blank"
              href="https://t.me/KForexDeriv"
              className="border border-stone-300 bg-white text-black px-4 py-1 font-medium hover:bg-dark-green hover:text-white rounded-sm transition-none text-base"
              rel="noreferrer"
            >
              {t("enter")}
            </a>
          </div>
          <div className="text-center rounded-md py-8">
            <div className="flex justify-center items-center">
              
              <img src={whatsapp} className="w-11 whatsapp-bordered" alt="whatsapp" />
            </div>
            <div className="font-normal text-white-with-shadow bg-[#0000003b] w-fit mx-auto rounded-md px-4 mt-2  text-xl mb-6 cursor-pointer">
              {t("comercialasesor")}
            </div>
            <span
              onClick={() => visitWhatsapp()}
              className="border border-stone-300 bg-white text-black px-4 py-1 font-medium hover:bg-dark-green hover:text-white rounded-sm transition-none text-base cursor-pointer"
            >
              {t("chatwithasesor")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentInvitation;
