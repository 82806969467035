import { useTranslation } from "react-i18next";
import { GoHome } from "react-icons/go";
import { BiBook, BiVideo } from "react-icons/bi";
import { BsBank, BsCashCoin } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";
import { GiAlarmClock, GiMoneyStack } from "react-icons/gi";
import { ImUsers } from "react-icons/im";
import { MdAttachMoney, MdSupervisedUserCircle } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { SiCashapp } from "react-icons/si";
import { UseAuth } from "src/auth/AuthContext";
import { validateRouteRole } from "src/helpers/mixins";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const SideBarAdmin = () => {
  const { t } = useTranslation();
  const navigation = useNavigate()
  const { user } = UseAuth();
  const location = useLocation()
  let { pathname } = location
  const role = user?.role || "user";
  const enableRoutes = validateRouteRole(role);

  useEffect(() => {
    //if(!enableRoutes.includes(pathname)){
      console.log('enable Routes es ',enableRoutes)
      console.log('pathname ',pathname)


if(pathname.includes('detalle/') && user?.role === 'cajerobolivares'){
    const find = pathname.indexOf('/detalle/')
      console.log('find',find)
      pathname = pathname.substring(0,find+8)
      console.log('nuevo pathname', pathname)
    }
    if(!enableRoutes.includes(pathname) && user?.role === 'cajerobolivares'){
      navigation('/admin')
    }
  }, [pathname])
  
  return (
    <div className="sidebar text-sm pt-10 text-white">
      <ul>
        <li className="flex flex-row bg-green-admin-dark items-center border-b border-black">
          <div className="flex flex-row text-white text-center font-bold items-center justify-center text-sm w-full py-4">
            ADMIN
            <div>Rol: {role} </div>
          </div>
        </li>
        {(enableRoutes.includes("*") || enableRoutes.includes("/admin")) && (
          <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
            <div className="flex items-center text-[0.8rem]">
              <Link
                className="flex flex-row text-white text-center font-normal items-center py-3 px-3 hover:text-white"
                to="/admin"
              >
                <GoHome size={14} className="mr-2 text-white" />{" "}
                {t("sidebar.home")}
              </Link>
            </div>
          </li>
        )}
        {(enableRoutes.includes("*") ||
          enableRoutes.includes("/admin/depositos")) && (
          <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
            <div className="flex items-center text-[0.8rem]">
              <Link
                className="flex flex-row text-white text-center font-normal items-center py-3 px-3 hover:text-white"
                to="/admin/depositos"
              >
                <BsCashCoin size={14} className="mr-2 text-white" />{" "}
                {t("sidebar.deposits")}
              </Link>
            </div>
          </li>
        )}
                {(enableRoutes.includes("*") ||
          enableRoutes.includes("/admin/depositos-bolivares")) && (
          <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
            <div className="flex items-center text-[0.8rem]">
              <Link
                className="flex flex-row text-white text-left font-light items-center py-3 px-3 hover:text-white"
                to="/admin/depositos-bolivares"
              >
                <SiCashapp size={14} className="mr-2 text-white" />{" "}
                {t("sidebar.depositsbolivares")}
              </Link>
            </div>
          </li>
        )}

        {(enableRoutes.includes("*") ||
          enableRoutes.includes("/admin/fiat")) && (
          <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
            <div className="flex items-center text-[0.8rem]">
              <Link
                className="flex flex-row text-white text-center font-normal items-center py-3 px-3 hover:text-white"
                to="/admin/fiat"
              >
                <MdAttachMoney size={14} className="mr-2 text-white" />{" "}
                {t("sidebar.fiat")}
              </Link>
            </div>
          </li>
        )}

        {(enableRoutes.includes("*") ||
          enableRoutes.includes("/admin/procesadores")) && (
          <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
            <div className="flex items-center text-[0.8rem]">
              <Link
                className="flex flex-row text-white text-center font-normal items-center py-3 px-3 hover:text-white"
                to="/admin/procesadores"
              >
                <GiMoneyStack size={14} className="mr-2 text-white" />{" "}
                {t("sidebar.process")}
              </Link>
            </div>
          </li>
        )}

        {(enableRoutes.includes("*") ||
          enableRoutes.includes("/admin/usuario/lista")) && (
          <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
            <div className="flex items-center text-[0.8rem]">
              <Link
                className="flex flex-row text-white text-center font-normal items-center py-3 px-3 hover:text-white"
                to="/admin/usuario/lista"
              >
                <ImUsers size={14} className="mr-2 text-white" />{" "}
                {t("sidebar.users")}
              </Link>
            </div>
          </li>
        )}

        {(enableRoutes.includes("*") ||
          enableRoutes.includes("/admin/verificaciones")) && (
          <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
            <div className="flex items-center text-[0.8rem]">
              <Link
                className="flex flex-row text-white text-center font-normal items-center py-3 px-3 hover:text-white"
                to="/admin/verificaciones"
              >
                <HiOutlineCheckCircle size={14} className="mr-2 text-white" />{" "}
                {t("sidebar.verification")}
              </Link>
            </div>
          </li>
        )}        

        {(enableRoutes.includes("*") ||
          enableRoutes.includes("/admin/cuentas-bancarias")) && (
          <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
            <div className="flex items-center text-[0.8rem]">
              <Link
                className="flex flex-row text-white text-center font-normal items-center py-3 px-3 hover:text-white"
                to="/admin/cuentas-bancarias"
              >
                <BsBank size={14} className="mr-2 text-white" />{" "}
                {t("sidebar.bankaccountdeposit")}
              </Link>
            </div>
          </li>
        )}

        {(enableRoutes.includes("*") ||
          enableRoutes.includes("/admin/depositos/tiempo")) && (
          <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
            <div className="flex items-center text-[0.8rem]">
              <Link
                className="flex flex-row text-white text-center font-normal items-center py-3 px-3 hover:text-white"
                to="/admin/depositos/tiempo"
              >
                <GiAlarmClock size={14} className="mr-2 text-white" />{" "}
                {t("sidebar.deposittime")}
              </Link>
            </div>
          </li>
        )}



        {(enableRoutes.includes("*") ||
          enableRoutes.includes("/admin/roles")) && (
          <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
            <div className="flex items-center text-[0.8rem]">
              <Link
                className="flex flex-row text-white text-center font-normal items-center py-3 px-3 hover:text-white"
                to="/admin/roles"
              >
                <MdSupervisedUserCircle size={14} className="mr-2 text-white" />{" "}
                {t("sidebar.roles")}
              </Link>
            </div>
          </li>
        )}

{(enableRoutes.includes("*") ||
          enableRoutes.includes("/admin/blog")) && (
          <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
            <div className="flex items-center text-[0.8rem]">
              <Link
                className="flex flex-row text-white text-center font-normal items-center py-3 px-3 hover:text-white"
                to="/admin/blog"
              >
                <BiBook size={14} className="mr-2 text-white" />{" "}
                {t("sidebar.blog")}
              </Link>
            </div>
          </li>
        )}
        {(enableRoutes.includes("*") ||
          enableRoutes.includes("/admin/videos")) && (
          <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
            <div className="flex items-center text-[0.8rem]">
              <Link
                className="flex flex-row text-white text-center font-normal items-center py-3 px-3 hover:text-white"
                to="/admin/videos"
              >
                <BiVideo size={14} className="mr-2 text-white" />{" "}
                {t("Video")}
              </Link>
            </div>
          </li>
        )}        

        <li className="flex flex-row bg-green-admin-dark hover:bg-green-admin transition-all justify-start items-center border-b border-black">
          <div className="flex items-center text-[0.8rem]">
            <Link
              className="flex flex-row text-white text-center font-normal items-center py-3 px-3 hover:text-white"
              to="/inicio"
            >
              <CgWebsite size={14} className="mr-2 text-white" /> {t("website")}
            </Link>
          </div>
        </li>
      </ul>
    </div>
  );
};
export default SideBarAdmin;
