import { Table } from "antd";
import { SetStateAction, forwardRef, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HiSearchCircle } from "react-icons/hi";
import { useAppStateContext } from "src/auth/AppStateContext";
import DepositStatus from "src/components/Shared/DepositStatus";
import { getFiatName, getHourDate, money } from "src/helpers/mixins";
import { IAccountForm } from "src/types";
import moment from "moment";
import APIService from "src/services/Api";
import DatePicker, {registerLocale} from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);
type SearchDepositList = {
  query: string;
  status: string;
  page: number;
  startDate: any;
  endDate: any;
};

type iDepositListSearchParams = {
  type: string 
}

const DepositListSearch = ({type}:iDepositListSearchParams) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [depositList, setDepositList] = useState<any>([]);
  const [pages, setPages] = useState<number>(1);
  const { processors } = useAppStateContext();
  const [totalRows, setTotalRows] = useState<number>()
    
  const divRefScroll = useRef<null | HTMLDivElement>(null);
  const handleDetailDeposit = (deposit: any) => {
    navigate(`/admin/depositos/detalle/${deposit?.id}`, {
      state: {
        ...deposit,
      },
    });
  };
  const [search, setSearch] = useState<SearchDepositList>({
    query: "",
    status: "",
    page: 0,
    startDate: '',
    endDate: ''
  });
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const onSearch = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.FormEvent<HTMLSelectElement>
  ) => {
    const { value, name } = event.currentTarget;
    setSearch({ ...search, [name]: value });
    if(name ==='page'){
      divRefScroll?.current?.scrollIntoView({ block:"start",behavior: "smooth" });
      fetchList()
    }
  };

  const handleClose = () => {
    setShowDialog(false)
    setSearch({...search,page:0})
  }
  const fetchList = async (action?:string | undefined) => {
    if(action && action === 'clearPage'){
      setSearch({...search, page: 0})
    }

    setLoading(true);
    setShowDialog(true);
    const list = await APIService.adminDepositListSearch({
      search: search.query,
      status: search.status,
      take: 10,
      page: search.page,
      startDate: search.startDate,
      endDate: search.endDate,
      type
    });

    setDepositList(list.data.data.deposits);
    setPages(list.data.data.pages);
    setTotalRows(list.data.data.totalRows)
    setLoading(false);

  };

  const columns = [
    {
      title: t("codetext"),
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any) => (
        <div>
          <div
            onClick={() => handleDetailDeposit(record)}
            className="underline text-sm text-green-admin-light font-bold cursor-pointer"
          >
            {record.code}
          </div>
          <div className="text-xs font-semibold">
            {moment(record.createdAt).local().format('YYYY-MM-DD')}
            {/*moment
              .utc(parseInt(record.createdAt, 10))
              .local()
      .format("YYYY-MM-DD")*/}{" "}
            <div className="font-semibold">
              {moment(record.createdAt).local().format('HH:MM:SS')} 
            </div>
          </div>
        </div>
      ),
    },
    {
      title: t("table.willget"),
      dataIndex: "money",
      key: "money",
      render: (text: any, record: any) => (
        <div className="font-medium">
          <span className="font-bold">
            ${money(record.money)}{" "}
            {getFiatName(record.systemProcessor.id, processors)}{" "}
          </span>
          <div>
            {t("paymethod")}:{" "}
            <div className="font-semibold">{record.systemProcessor.name}</div>
          </div>
        </div>
      ),
    },
    {
      title: t("table.totaltopay"),
      dataIndex: "totaltopay",
      key: "totaltopay",
      render: (text: any, record: any) => (
        <div className="font-medium">
          <span className="font-bold">
            ${money(record.totaltopay)}{" "}
            {getFiatName(record.destinyProcessor.id, processors)}
          </span>
          <div>
            {/*t("paymethod")*/}
            <div className="font-semibold text-xs">
              <div>
                {
                  (JSON.parse(record?.accountsendstringify) as IAccountForm)
                    ?.systemProcessor?.name
                }
              </div>
              <div>
                {
                  (JSON.parse(record?.accountsendstringify) as IAccountForm)
                    ?.number
                }
              </div>
              {(JSON.parse(record?.accountsendstringify) as IAccountForm)
                ?.accounttype && (
                <div>
                  {t(
                    `bankaccounttype.${
                      (JSON.parse(record?.accountsendstringify) as IAccountForm)
                        ?.accounttype
                    }`
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: t("table.user"),
      dataIndex: "user.fullname",
      key: "user.fullname",
      render: (text: any, record: any) => (
        <div className="font-medium">
          {record.user.email}
          {record.user.fullname !== record.user.email && (
            <div>{record.user.fullname}</div>
          )}
        </div>
      ),
    },
    {
      title: t("table.status"),
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => (
        <div className="font-small-son">
          <DepositStatus
            userId={record.user.id}
            depositId={record.id}
            depositCode={record.code}
            currentStatus={record.status}
          />
        </div>
      ),
    },
  ];
  return (
    <div className="w-full">
      <div className="flex flex-col xl:flex-row items-center w-full  justify-between gap-1">
        <div className="relative p-3 rounded-lg w-full xl:w-1/3">
          <input
            type="text"
            onChange={onSearch}
            onKeyUp={(e) => (e.key === "Enter" ? fetchList() : null)}
            name="query"
            className="rounded-full p-3 w-full pr-10 placeholder-stone-400  border-b border-stone-300"
            placeholder="Buscar código, email, nombre, teléfono"
          />

          <button type="submit" className="absolute right-6 top-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </button>
        </div>
        <div className="flex w-full xl:w-fit gap-2 items-center">
          Desde
        <DatePicker
          locale="es"
        selected={search.startDate}
        /*onChange={(date) => setStartDate(date)}*/
        onChange={(date) => setSearch({ ...search, ['startDate']: date })}
        selectsStart
        startDate={search.startDate}
        endDate={search.endDate}
        dateFormat="YYYY/MM/dd"
        placeholderText="Elige fecha inicio"
        monthsShown={2}
      />
      Hasta
      <DatePicker
        locale="es"
        selected={search.endDate}
        //onChange={(date) => setEndDate(date)}
        onChange={(date) => setSearch({ ...search, ['endDate']: date })}
        selectsEnd
        startDate={search.startDate}
        endDate={search.endDate}
        dateFormat="YYYY/MM/dd"
        placeholderText="Elige fin"
        monthsShown={2}
      />
        </div>
        <div className="flex w-full xl:w-fit items-center gap-2">
          Estado
          <select
            onChange={onSearch}
            name="status"
            className="rounded-full py-3 text-center placeholder-stone-400  border-b border-stone-300"
          >
            <option value="">Todos</option>
            <option value="waitpay">Esperando pago</option>
            <option value="moneyreceived">Dinero recibido</option>
            <option value="checking">En verificación</option>
            <option value="cancel">Cancelado</option>
            <option value="refused">Rechazado</option>
            <option value="completed">Completado</option>
          </select>
        </div>
        <div
          className="py-2.5 mx-auto md:mx-0 md:w-fit font-medium bg-green-admin-light text-white rounded-full px-8 my-4 xl:my-0 cursor-pointer hover:bg-green-admin transition-all"
          onClick={() => fetchList('clearPage')}
        >
          Buscar
        </div>
      </div>
      {showDialog && (
        <div
          className="relative z-[14000]"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-[14000] w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-11/12 lg:w-9/12">
                <div className="bg-white px-4 pt-5 ">
                  <div className="w-full flex flex-row">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-stone-400 sm:mx-0 sm:h-10 sm:w-10">
                      <HiSearchCircle size={29} className="text-white" />
                    </div>
                   <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full relative ">
                      <h3
                        className="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Resultados de la búsqueda en transferencias {totalRows && totalRows}
                      </h3>
                      <div
                        style={{ maxHeight: "70vh", overflow: "auto" }}
                        ref={divRefScroll}
                      >
                        <div>
                          <Table
                            pagination={false}
                            dataSource={[...depositList]}
                            loading={loading}
                            columns={columns}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-stone-50 px-4 py-3 flex items-center justify-center gap-2 border-t border-stone-300">
                  <div>
                    <select
                    onChange={onSearch}
                    name="page"
                    disabled={loading}
                    className="bg-stone-300 text-black px-4 py-2 rounded-md font-semibold ">
                      {Array.from(new Array(pages).keys()).map((p) => (
                        <option
                          key={p.toString()}
                          value={p}
                        >
                          {p + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DepositListSearch;
