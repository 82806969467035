import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

type PreviewImageProps = {
  isOpen: boolean;
  closeModal(): void;
  img: string;
};
const PreviewImage = ({ isOpen, closeModal, img }: PreviewImageProps) => {
  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative" onClose={closeModal}
          style={{zIndex: '100000000'}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-stone-900 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-start justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full mt-0 max-w-100 transform overflow-hidden rounded-2xl bg-transparent text-left align-middle shadow-xl transition-all">
                  <div className="relative ">
                    <span
                      onClick={() => closeModal()}
                      className="close-dialog-button"
                    >
                      x
                    </span>
                    <img
                      alt="close"
                      src={img}
                      className="w-auto mx-auto"
                      style={{ height: "100vh" }}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
export default PreviewImage;
