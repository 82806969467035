import React from 'react';
import ReactDOM from 'react-dom/client';
//import '../src/assets/css/index.scss';
//import './../src/assets/css/index.scss'
import './index.scss'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import "./i18n/i18n"
import { AuthProvider } from './auth/AuthContext';
import { AppStateProvider } from './auth/AppStateContext';
import { ApolloProvider } from '@apollo/client';
import App from './App';
import 'antd/dist/antd.css';
import { client } from './graphql';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
    <AuthProvider>
      <AppStateProvider>
        <BrowserRouter>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </BrowserRouter>
      </AppStateProvider>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
