import axios from "axios";
import { IAccountBank, IAccountForm, IProcessorFiatRange } from "../types";
import { getLogout } from "src/helpers/mixins";

//export const endPoint =  "http://132.148.74.56:5555"
export const endPoint =
  process.env.REACT_APP_API || "http://132.148.74.56:5555";
export interface IOptionalActivityRequest {
  activity: string;
  traveler: string;
  booking: string;
}
export interface IUserRegisterBody {
  email: string;
  phone: string;
  password: string;
  country: string;
}
export interface IUserLoginBody {
  email: string;
  password: string;
}
export interface IAccountList {
  user: string;
}
export interface IAccountRemove {
  account: string;
}
export interface IRemoveById {
  id: string | number;
}
export interface IDetailById {
  id: string | number;
}
export interface IUserEdit {
  userid: string;
  fullname: string;
  email: string;
  birthday: string;
  phone: string;
  country: string;
}
export interface IFiatCreate {
  name: string;
  price: string;
  symbol: string;
}
export interface IFiatEdit {
  id: string;
  name: string;
  price: string;
  symbol: string;
}
export interface IProcessorForm {
  id?: string;
  name: string;
  paymentmethod: boolean;
  type: string;
  photoUrl?: string;
  isMobileBankAccount: boolean;
}
export interface IDerivCalculate {
  money: string;
  processor: string | number;
}
export interface IDerivCreateDeposit {
  user: string;
  money: string;
  percenttodeposit: string;
  totaltopay: string | undefined;
  currency: string;
  paymentPhoto?: File | undefined;
  derivtitular: string;
  derivcr: string;
  processor?: string | number | undefined;
}

export interface IUpdateStatus {
  id: string;
  status: string;
}

export interface IUserUploadPayment {
  id: string;
  file: File;
}

export interface ICreateNotification {
  message: string;
  userId: string | number;
  type?: string;
  typeId?: string | number;
  link?: string;
}
export interface IListNotification {
  userId: string | number | undefined;
  skip: number;
}
interface IAPIService {
  //getToken(body: IOptionalActivityRequest[]): Promise<any | undefined>
  getToken(): Promise<any | undefined>;
  userRegister(body: IUserRegisterBody): Promise<any | undefined>;
  userLogin(body: IUserLoginBody): Promise<any | undefined>;
  userRecoverAccount(body: any): Promise<any | undefined>;
  userVerifyRecoverAccountCode(body: any): Promise<any | undefined>;
  accountList(body: IAccountList): Promise<any | undefined>;
  accountCreate(body: IAccountForm): Promise<any | undefined>;
  accountRemove(body: IAccountRemove): Promise<any | undefined>;
  accountEdit(body: IAccountForm): Promise<any | undefined>;
  userEdit(body: IUserEdit ): Promise<any | undefined>;
  processorCreate(body: IProcessorForm): Promise<any | undefined>;
  processorEdit(body: IProcessorForm): Promise<any | undefined>;
  processorList(body: any): Promise<any | undefined>;
  processorRemove(body: IRemoveById): Promise<any | undefined>;
  processorUpdateStatus(body: IUpdateStatus): Promise<any | undefined>;
  processorUpdatePayWithdrawStatus(body: any): Promise<any | undefined>;
  fiatCreate(body: IFiatCreate): Promise<any | undefined>;
  fiatList(body: any): Promise<any | undefined>;
  fiatEdit(body: IFiatEdit): Promise<any | undefined>;
  fiatRemove(body: IRemoveById): Promise<any | undefined>;
  processorFiatRangeCreate(body: IProcessorFiatRange): Promise<any | undefined>;
  processorFiatRangeEdit(body: IProcessorFiatRange): Promise<any | undefined>;
  processorFiatRangeRemove(body: IRemoveById): Promise<any | undefined>;
  processorFiatRangeList(body: any): Promise<any | undefined>;
  derivCalculate(body: IDerivCalculate): Promise<any | undefined>;
  derivCreateDeposit(body: IDerivCreateDeposit): Promise<any | undefined>;
  derivListDeposit(body: IAccountList): Promise<any | undefined>;
  derivListDepositWait(body: IAccountList): Promise<any | undefined>;
  depositDetail(body: any): Promise<any | undefined>;
  derivCheckAccount(body: any): Promise<any | undefined>;
  accountBankList(body: any): Promise<any | undefined>;
  accountBankCreateEdit(body: IAccountBank): Promise<any | undefined>;
  accountBankUpdateStatus(body: IUpdateStatus): Promise<any | undefined>;
  accountBankUpdateRemove(body: IRemoveById): Promise<any | undefined>;
  adminDepositList(body: any): Promise<any | undefined>;
  adminDepositListSearch(body: any): Promise<any | undefined>;
  adminDepositUpdateStatus(body: any): Promise<any | undefined>;
  adminDepositDetail(body: IDetailById): Promise<any | undefined>;
  adminDepositTimeGet(body: any): Promise<any | undefined>;
  adminDepositTimeSave(body: any): Promise<any | undefined>;
  adminSaveVideo(body: any): Promise<any | undefined>;
  videoList(body: any): Promise<any | undefined>;
  videoUpdateStatus(body: any): Promise<any | undefined>;
  videoUpdateName(body: any): Promise<any | undefined>;
  videoUpdateViews(body: any): Promise<any | undefined>;
  videoLike(body: any): Promise<any | undefined>;
  videoDelete(body: any): Promise<any | undefined>;
  adminUserList(body: any): Promise<any | undefined>;
  adminUserListVerification(body: any): Promise<any | undefined>;
  adminUserDetail(body: any): Promise<any | undefined>;
  adminUserUpdateStatus(body: any): Promise<any | undefined>;
  userUploadPayment(body: IUserUploadPayment): Promise<any | undefined>;
  userDetailVerification(body: any): Promise<any | undefined>;
  chatCreateMessage(body: any): Promise<any | undefined>;
  chatListMessage(body: any): Promise<any | undefined>;
  notificationCreate(body: ICreateNotification): Promise<any | undefined>;
  notificationList(body: IListNotification): Promise<any | undefined>;
  activateVerifyAccount(body: any): Promise<any | undefined>;
  sendEmailVerifyAccount(body: any): Promise<any | undefined>;
  userUpdatePassword(body: any): Promise<any | undefined>;
  adminBlogCreateEdit(body: any): Promise<any | undefined>;
  adminBlogList(body: any): Promise<any | undefined>;
  adminBlogUpdateStatus(body: IUpdateStatus): Promise<any | undefined>;
  adminBlogRemove(body: IRemoveById): Promise<any | undefined>;
  webBlogList(body: any): Promise<any | undefined>;
  rangeShowList(body: any): Promise<any | undefined>;
  processorListEnableWithdraw(body: any): Promise<any | undefined>;
  depositUploadResponse(body: any): Promise<any | undefined>;
  updateIsOnline(body: any): Promise<any | undefined>;
  listUserChat(body: any): Promise<any | undefined>;
  fetchCurrentDollar(body: any): Promise<any | undefined>;
  /*userNotificationSeen(body:any):Promise<any | undefined>*/
  userRoleList(body: any): Promise<any | undefined>;
  userRoleUpdate(body: any): Promise<any | undefined>;
  getUserInfo(body: any): Promise<any | undefined>;
  transferResume(body: any): Promise<any | undefined>;
  weltradeLoginSecureUser(body: any): Promise<any | undefined>;
  completeFastRegister(body: any): Promise<any | undefined>;
  weltradeBankList(body: any): Promise<any | undefined>;
  //adminNotificationRead(body: any): Promise<any | undefined>;
  userNotificationRead(body: any): Promise<any | undefined>;
  userCheckTermsnCondition(body: any): Promise<any | undefined>;
  userAddUpdateValidation(body: any): Promise<any | undefined>; 
  adminReports(body: any): Promise<any | undefined>;
  adminUpdateVerification(body: any): Promise<any | undefined>;
  adminRemoveSomePic(body: any): Promise<any | undefined>;
  adminUpdateVerifiedIdentity(body: any): Promise<any | undefined>
  userCheckVerifiedIdentity(body: any): Promise<any | undefined>
  kycEmailNotification(body: any): Promise<any | undefined>
  transferAccelerate(body: any): Promise<any | undefined>
  adminSearchChatUser(body: any): Promise<any | undefined>
  adminReadNotification(body: any): Promise<any | undefined>
  weltradeTransferCalculateTest(body: any): Promise<any | undefined>
}

const accessToken = () => localStorage.getItem("accessToken") || "";
const authToken = () => localStorage.getItem("authToken") || "";

const APIService: IAPIService = {
  getToken: async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        url: `${endPoint}/v1/isf/generate-access-token`,
        //data: body
      })
        .then((res) => {
          const {
            data: { data },
          } = res;
          localStorage.setItem("accessToken", data?.accessToken);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  userRegister: async (body: IUserRegisterBody): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/user/register`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  userLogin: async (body: IUserLoginBody): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/user/login`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  accountList: async (body: IAccountList): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/account-list`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  accountCreate: async (body: IAccountForm): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/account-create`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  accountRemove: async (body: IAccountRemove): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/account-remove`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  accountEdit: async (body: IAccountForm): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/account-edit`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  userEdit: async (body: IUserEdit): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/user-edit`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  processorCreate: async (body: IProcessorForm): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/processor-create`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  processorEdit: async (body: IProcessorForm): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/processor-edit`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  processorList: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/admin/processor-list`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  processorRemove: async (body: IRemoveById): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/processor-remove`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  processorUpdateStatus: async (body: IRemoveById): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/processor-update-status`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  processorUpdatePayWithdrawStatus: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/processor-update-paywithdraw-status `,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  fiatList: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/fiat-list`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  fiatCreate: async (body: IFiatCreate): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/fiat-create`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  fiatEdit: async (body: IFiatEdit): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/fiat-edit`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  fiatRemove: async (body: IRemoveById): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/fiat-remove`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  processorFiatRangeCreate: async (body: IProcessorFiatRange): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/processor-fiat-range-create`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  processorFiatRangeEdit: async (body: IProcessorFiatRange): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/processor-fiat-range-edit`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  processorFiatRangeRemove: async (body: IRemoveById): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/processor-fiat-range-remove`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  processorFiatRangeList: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/processor-fiat-range-list`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  derivCalculate: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/system/deriv-calculate`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  derivCreateDeposit: async (body: IDerivCreateDeposit): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/deriv-create-deposit`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  derivListDeposit: async (body: IAccountList): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/deriv-list-deposit`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  depositDetail: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/deposit-detail`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  derivListDepositWait: async (body: IAccountList): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/deriv-list-deposit-wait`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  accountBankList: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/bank-account-list`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  accountBankCreateEdit: async (body: IAccountBank): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/bank-account-create-edit`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  accountBankUpdateStatus: async (body: IUpdateStatus): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/bank-account-update-status`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  accountBankUpdateRemove: async (body: IUpdateStatus): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/bank-account-delete`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminDepositList: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/deposit-list`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminDepositListSearch: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/deposit-list-search`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },  
  adminDepositUpdateStatus: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/deposit-update-status`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminDepositDetail: async (body: IDetailById): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/deposit-detail`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  derivCheckAccount: async (body: IDetailById): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/deriv-check-account`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminDepositTimeGet: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/deposit-time-get`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminDepositTimeSave: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/deposit-time-save`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminSaveVideo: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/admin-save-video`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  videoList: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/system/video-list`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  videoUpdateStatus: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-token": authToken(),
        },
        url: `${endPoint}/v1/admin/video-update-status`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  videoUpdateName: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-token": authToken(),
        },
        url: `${endPoint}/v1/admin/video-update-name`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  videoUpdateViews: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/system/video-update-views`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },  
  videoLike: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken()
        },
        url: `${endPoint}/v1/system/video-like`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  videoDelete: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-token": authToken(),
        },
        url: `${endPoint}/v1/admin/video-delete`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },  
  userDetailVerification: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/detail-verification`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  userUploadPayment: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/upload-payment`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminUserList: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/user-list`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminUserListVerification: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/user-list-verification`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },  
  adminUserDetail: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/user-detail`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },  
  adminUserUpdateStatus: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/user-update-status`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },  
  chatCreateMessage: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/chat-create-message`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  chatListMessage: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/chat-list-message`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  depositUploadResponse: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/deposit-upload-response`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  notificationCreate: async (body: ICreateNotification): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/notification-create`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  notificationList: async (body: IListNotification): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/notification-list`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  activateVerifyAccount: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/user/activate-verify-account`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  sendEmailVerifyAccount: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/send-email-verify-account`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  userRecoverAccount: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken()
        },
        url: `${endPoint}/v1/user/recover-account`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  userVerifyRecoverAccountCode: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken()
        },
        url: `${endPoint}/v1/user/verify-recover-account-code`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  userUpdatePassword: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken()
        },
        url: `${endPoint}/v1/user/update-password`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminBlogCreateEdit: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/blog-create-edit`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminBlogList: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/blog-list`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminBlogUpdateStatus: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/blog-update-status`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminBlogRemove: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/blog-remove`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  webBlogList: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/system/blog-list-website`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
 
  
  rangeShowList: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/system/range-show-list`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  

  userRoleList: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/user-role-list`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  userRoleUpdate: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/user-role-update`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  processorListEnableWithdraw: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/processor-list-enable-withdraw`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  updateIsOnline: async (body: IRemoveById): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/update-is-online`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  listUserChat: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/list-user-chat`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  fetchCurrentDollar: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/system/get-current-dollar`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  /*userNotificationSeen  : async (body:any): Promise<any> => { 
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-ISF-Auth-Token': authToken()
        },
        url: `${endPoint}/v1/user/user-notification-seen`,
        data: body
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        console.log(err)
        reject(err)
      })
    })
  },*/

  getUserInfo: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/user/get-user-info`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  transferResume: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/weltrade/transfer-resume/${body.transferId}`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  weltradeLoginSecureUser: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/weltrade/login-secure`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  completeFastRegister: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/weltrade/complete-fast-register`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  weltradeBankList: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-token": accessToken(),
        },
        url: `${endPoint}/v1/weltrade/bank-list`,
        data: body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  /*adminNotificationRead: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/admin-notification-read`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },*/
  userAddUpdateValidation: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/add-update-validation`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  userNotificationRead: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/user-notification-read`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  userCheckTermsnCondition: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/check-termsncondition`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  adminReports: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/admin-reports`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  adminUpdateVerification: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/user-update-verification`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },  

  adminRemoveSomePic: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/admin-remove-somepic`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }, 
  
  adminUpdateVerifiedIdentity: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/admin-update-verifiedIdentity`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }, 

  userCheckVerifiedIdentity: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/user-check-verifiedIdentity`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }, 
  kycEmailNotification: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/kyc-email-notification`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }, 
  transferAccelerate: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/user/transfer-accelerate`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }, 
  adminSearchChatUser: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/search-chat-user`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }, 
  adminReadNotification: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-Auth-Token": authToken(),
        },
        url: `${endPoint}/v1/admin/admin-read-notification`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }, 
  weltradeTransferCalculateTest: async (body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-ISF-access-Token": accessToken(),
        },
        url: `${endPoint}/v1/weltrade/transfer-calculate-test`,
        data: body,
      })
        .then((res) => {
          if (res.data.message === "Error en el token" || res.data.message === 'Token no encontrado') {
            getLogout();
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }, 

  /*emailReminderFromPeriod: async (body: IEmailReminderFromPeriod): Promise<any> => {
      let endpoint = `${serverlessEndpoint[env]}/v1/different/booking/notification/period/${body.periodId}`
      if (body.sendPercent) {
        endpoint = endpoint + '/' + body.percent
      }
      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          url: endpoint
        }).then(res => {
          resolve(res.data)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },*/
};

export default APIService;
