import Select from "react-select";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStateContext } from "src/auth/AppStateContext";
import { selectStyle } from "src/helpers/mixins";
import { useForm } from "react-hook-form";
import { IAccountForm } from "src/types";
import identification from "../../data/identification.json";
import FormRequired from "../Shared/FormRequired";
import { CgSpinner } from "react-icons/cg";
import APIService from "src/services/Api";
import { GiTakeMyMoney } from "react-icons/gi";
import { FaSave } from "react-icons/fa";
//import DialogConfirm from "../Shared/DialogConfirm";
import AccountCreateConfirm from "../AccountCreateConfirm";
import { UseAuth } from "src/auth/AuthContext";
import Notify from "src/toast/Notify";
import { ToastContainer } from "react-toastify";

type processorSelectedForm = {
  image: string;
  label: string;
  value: number;
  type: string;
  isMobileAccount: boolean;
};
type DialogCreateAccountParams = {
  isOpen: boolean;
  handleClose(action:string, newAccount:any): void;
  preSelected?: any
};
const DialogCreateAccount = ({
  isOpen,
  handleClose,
  preSelected
}: DialogCreateAccountParams) => {
  const { t } = useTranslation();
  const [dataForm, setDataForm] = useState<IAccountForm>();
  //const [processorList, setProcessorList] = useState<Proccesor[]>([]);
  //const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = UseAuth();
  const [viewConfirm, setViewConfirm] = useState<boolean>(false);
  const identificationList = identification;
  
  /*const fetchProcessators = async () => {
    //setLoadingList(true);
    const list = await APIService.processorList({});
    setProcessorList(list?.data?.data?.list);
    //setLoadingList(false);
  };*/

  const dispatchHandleClose = (action:string,values:any) => {
    reset()
    handleClose(action,values)
  }

  const responseConfirm = (r: boolean) => {
    if (r && dataForm) {
      createMyAccount(dataForm);
    }
    setViewConfirm(false);
  };

  useEffect(() => {
    if (preSelected) {
      
      const find = processors.find(
        (p) => p.id?.toString() === preSelected.toString()
      );
      if (find && find.id) {
        //setTypeProcess(find.type);
        setProcessorSelected({
          value: Number(find.id),
          label: find.name,
          image: `${process.env.REACT_APP_PROCESSOR_IMAGE}${find.photoUrl}`,
          type: find.type,
          isMobileAccount: find.isMobileBankAccount,
        })
        /*window.setTimeout(() => {
          handleSelectBank(proccessorId);
        }, 1000);*/
        //setValue('bank',proccessorId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelected, isOpen]);

  const createMyAccount = async (data: IAccountForm) => {
    if (processorSelected?.label?.toLocaleLowerCase() === "deriv") {
      data.number = `CR-${data.number}`;
    }
    /*console.log('datos para grabar en la bd !!!!!!')
    console.log(data)*/
    if(processorSelected){
      setLoading(true)
      const create = await APIService.accountCreate({
        ...data,
        bank: processorSelected?.value.toString() || '',
        user: user?.id || "",
      });
      /*console.log('RECIEN CREADO Y LA IFORMACION ES ESTA')
      console.log(create.data)*/
      if (create.data.status) {
        //const newFormAccount = create.data.data.create as IAccountForm
        fetchBankAccounts();
        dispatchHandleClose('refetch_success_saved',{ value:Number(create?.data?.data?.create?.systemProcessor?.id || '000')})
        //setActivities((prev) => [...prev, activity.activity])
        Notify(t("createdSuccess"));
        //setSuccess(true);
      } else {
        Notify(t("createdFailed"));
      }
      setLoading(false)
    }
  };

  const [processorSelected, setProcessorSelected] =
    useState<processorSelectedForm>();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm<IAccountForm>({
    defaultValues: {
      accounttype: null,
      identificationtype: null,
      bank: undefined,
    },
  });

  const handleFormAccount = async (data: IAccountForm) => {
    setDataForm(data);
    setViewConfirm(true);
  };

  const handleSelectIdentification = (e: string) => {
    setValue("identificationtype", e);
  };

  const handleSelectAccountType = (e: string) => {
    setValue("accounttype", e);
  };

  const handleSelectTypeProcess = (e: any) => {
    if(processorSelected?.type !== e.type){
      reset()
    }else if(processorSelected?.isMobileAccount !== e.isMobileAccount){
      reset()
    }
    setProcessorSelected(e);
  };

  const { processors, fetchBankAccounts } = useAppStateContext();
  
  const optionsPay = () => {
    //return processors?.filter(p => p.id !== getValues('destiny')).map(p => ({
    return processors
      ?.filter((p) => p.status === "active")
      ?.map((p) => ({
        value: p.id,
        label: p.name,
        image: `${process.env.REACT_APP_PROCESSOR_IMAGE}${p.photoUrl}`,
        type: p.type,
        isMobileAccount: p.isMobileBankAccount,
      }));
  };

  return (
    <div>
      {isOpen && (
        <>
          <div
            className="relative z-[14000]"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-[14000] w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:w-11/12 xl:w-9/12">
                  <div className="bg-white px-2 py-2 border-white relative rounded-lg">
                    <div
                      onClick={() => dispatchHandleClose('',undefined)}
                      className="bg-red-500 text-white transition-all flex items-center font-bold justify-center text-xl cursor-pointer w-8 h-8 rounded-full absolute -right-4 -top-4 "
                    >
                      X
                    </div>
                    <div className="w-full flex flex-row  px-0 md:px-4 pt-5">
                      <div className="mx-auto hidden md:flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-green-admin-light sm:mx-0">
                        <GiTakeMyMoney size={50} className="text-white" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full relative ">
                        <div className="text-xl font-bold leading-6 text-gray-900">
                          {t("accountssavenew")}
                        </div>
                        <div className="font-medium mb-10">
                          Crea una cuenta receptora para poder recibir el
                          dinero.
                        </div>
                        <form onSubmit={handleSubmit(handleFormAccount)}>
                          <section
                            style={{
                              minHeight: "70vh",
                              maxHeight: "70vh",
                              overflow: "auto",
                            }}
                          >
                            <div className="flex items-center flex-col sm:flex-row justify-center sm:gap-6 ">
                              {processorSelected && (
                                <img
                                  src={processorSelected?.image}
                                  alt="processor"
                                  className="h-auto"
                                  style={{ maxWidth: "10rem" }}
                                />
                              )}
                              <Select
                                isSearchable={true}
                                options={optionsPay()}
                                defaultValue={processorSelected}
                                components={{ Option: SelectWithdrawOptions }}
                                styles={selectStyle}
                                onChange={(e: any) =>
                                  handleSelectTypeProcess(e)
                                }
                                placeholder={`${t("selectlist")}`}
                              />
                            </div>
                            <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 pt-6">
                              <div className="col-span-2 lg:col-span-1">
                                {processorSelected?.type === "wallet" && (
                                  <div>
                                    {processorSelected.label &&
                                    processorSelected.label.toLocaleLowerCase() ===
                                      "deriv" ? (
                                      <label className="font-semibold">
                                        {t("accounts.derivcr")}*
                                      </label>
                                    ) : (
                                      <label className="font-semibold">
                                        {t("accounts.emailwallet")}*
                                      </label>
                                    )}
                                  </div>
                                )}
                                {processorSelected?.type === "bank" && (
                                  <label className="font-semibold">
                                    {t("accounts.numbercomplete")}*
                                  </label>
                                )}
                                {processorSelected?.type === "crypto" && (
                                  <label className="font-semibold">
                                    {t("accounts.publickey")}*
                                  </label>
                                )}
                                {(processorSelected?.type?.length || 0) > 0 && (
                                  <input
                                    placeholder={t("placeholder")}
                                    disabled={isSubmitting && loading}
                                    {...register("number", {
                                      required: true,
                                      maxLength: 50,
                                    })}
                                    type={
                                      processorSelected?.label?.toLocaleLowerCase() ===
                                      "deriv"
                                        ? "number"
                                        : "text"
                                    }
                                    className="bg-stone-200 placeholder-stone-600 font-medium w-full shadow-inset-stone-400 rounded-lg px-2 py-2"
                                  />
                                  
                                )}
                                {errors.number && <FormRequired />}
                              </div>

                              {processorSelected?.type !== "crypto" &&
                                (processorSelected?.type?.length || 0) > 0 && (
                                  <div className="col-span-2 lg:col-span-1">
                                    <label className="font-semibold">
                                      {t("accounts.nametitular")}*
                                    </label>
                                    <input
                                      placeholder={t("placeholder")}
                                      disabled={isSubmitting && loading}
                                      {...register("nametitular", {
                                        required: true,
                                      })}
                                      type="text"
                                      className="bg-stone-200 placeholder-stone-600 font-medium w-full shadow-inset-stone-400 rounded-lg px-2 py-2"
                                    />
                                    {errors.nametitular && <FormRequired />}
                                  </div>
                                )}

                              {processorSelected?.type === "bank" && (
                                <>
                                  {
                                    /*!processorList.find(
                                  (p) =>
                                    (p?.id || "--").toString() ===
                                    (processorSelected.value || "").toString()
                                )?.isMobileBankAccount && */
                                    !processorSelected.isMobileAccount && (
                                      <div className="col-span-2 lg:col-span-1">
                                        <>
                                          <label className="font-semibold">
                                            {t("accounts.typecc")}*
                                          </label>
                                          <select
                                            disabled={isSubmitting && loading}
                                            {...register("identificationtype", {
                                              required: true,
                                            })}
                                            onChange={(e) =>
                                              handleSelectIdentification(
                                                e.target.value
                                              )
                                            }
                                            className="bg-stone-200 placeholder-stone-600 font-medium w-full shadow-inset-stone-400 rounded-lg px-2 py-2"
                                            name=""
                                            id=""
                                          >
                                            <option value="" selected>
                                              {t("form.chooseoption")}
                                            </option>
                                            {identificationList?.map(
                                              (identification, key) => (
                                                <option
                                                  key={key.toString()}
                                                  value={identification.value}
                                                >
                                                  {t(
                                                    `identification.` +
                                                      identification.value
                                                  )}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          {!getValues("identificationtype") && (
                                            <FormRequired />
                                          )}
                                        </>
                                      </div>
                                    )
                                  }

                                  {processorSelected.type === "bank" && (
                                    <>
                                      {!processorSelected.isMobileAccount && (
                                        <>
                                          <div className="col-span-2 lg:col-span-1">
                                            <label className="font-semibold">
                                              {t("accounts.typeaccount")}*
                                            </label>

                                            <select
                                              disabled={isSubmitting && loading}
                                              {...register("accounttype", {
                                                required: true,
                                              })}
                                              onChange={(e) =>
                                                handleSelectAccountType(
                                                  e.target.value
                                                )
                                              }
                                              className="bg-stone-200 placeholder-stone-600 font-medium w-full shadow-inset-stone-400 rounded-lg px-2 py-2"
                                              name=""
                                              id=""
                                            >
                                              <option value="" selected>
                                                {t("form.chooseoption")}
                                              </option>
                                              <option value="saving">
                                                {t("accounts.saved")}
                                              </option>
                                              <option value="checking">
                                                {t("accounts.checking")}
                                              </option>
                                            </select>
                                            {!getValues("accounttype") && (
                                              <FormRequired />
                                            )}
                                          </div>
                                        </>
                                      )}

                                      <div className="col-span-2 lg:col-span-1">
                                        <label className="font-semibold">
                                          {t("accounts.cc")}*
                                        </label>
                                        <input
                                          disabled={isSubmitting && loading}
                                          {...register("identification", {
                                            required: true,
                                            maxLength: 50,
                                          })}
                                          type="text"
                                          className="bg-stone-200 placeholder-stone-600 font-medium w-full shadow-inset-stone-400 rounded-lg px-2 py-2"
                                        />
                                        {errors.identification && (
                                          <FormRequired />
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )}

                              {processorSelected?.type === "crypto" && (
                                <div className="col-span-2 lg:col-span-1">
                                  <label className="font-semibold">
                                    {t("accounts.typered")}*
                                  </label>
                                  <input
                                    disabled={isSubmitting && loading}
                                    {...register("typered", {
                                      required: true,
                                    })}
                                    type="text"
                                    className="bg-stone-200 placeholder-stone-600 font-medium w-full shadow-inset-stone-400 rounded-lg px-2 py-2"
                                  />
                                  {errors.typered && <FormRequired />}
                                </div>
                              )}

                              {processorSelected?.type !== "" &&
                                (processorSelected?.type?.length || 0) > 0 && (
                                  <div className="col-span-2">
                                    {(!isSubmitting && !loading) ? (
                                      <button
                                        type="submit"
                                        className="px-4 mt-6 py-2 bg-green-admin-light rounded-lg w-full flex items-center justify-center gap-2 text-lg font-semibold tracking-wider text-white"
                                      >
                                        <div>
                                          <FaSave size={30} />
                                        </div>
                                        {t("save")}
                                      </button>
                                    ) : (
                                      <button
                                        disabled
                                        className="px-4 mt-6 py-2 bg-green-admin-light rounded-lg w-full flex items-center justify-center gap-2 text-lg font-semibold tracking-wider text-white"
                                      >
                                        {t("savingtext")}..
                                        <div>
                                          <CgSpinner
                                            size={30}
                                            className="animate-spin mx-auto "
                                          />
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                )}
                            </div>
                          </section>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {viewConfirm && <AccountCreateConfirm response={responseConfirm} />}
        </>
      )}
    </div>
  );
};
export default DialogCreateAccount;

export const SelectWithdrawOptions = (option: any) => {
  const { innerProps, innerRef } = option;
  return (
    <div>
      <ToastContainer
        position="bottom-right"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        ref={innerRef}
        {...innerProps}
        className="flex items-center flex-wrap justify-between p-2 hover:bg-gray-200  cursor-pointer"
      >
        <div className="font-medium flex items-center gap-x-2 text-sm">
          {<img src={option.data.image} alt="processor" className="w-36" />}
        </div>
      </div>
    </div>
  );
};
