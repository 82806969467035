

//
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type KycStatusProps = {
  currentStatus: string
}


/*const [optionStatuses] = useState<optionStatus[]>([
  { value: "empty", label: "vacio" },
  { value: "waiting", label: "en verificación" },
  { value: "rejected", label: "rechazado" },
  { value: "completed", label: "completado" },
]);
*/
const STATUS_CURRENT: any = {
  empty: "bg-cyan-500 text-white",
  waiting: "bg-amber-500 text-white",
  rejected: "bg-red-700 text-white",
  completed: "bg-emerald-700 text-white",
}

const ViewKycStatus = ({ currentStatus }: KycStatusProps) => {
  const { t } = useTranslation();
  const [showStatus, setShowStatus] = useState(currentStatus)

  useEffect(() => {
    setShowStatus(currentStatus)
  }, [currentStatus])
  
  const styleclass = STATUS_CURRENT[showStatus] ? STATUS_CURRENT[showStatus] : 'bg-cyan-500 text-white'
  return (
    <div className={`${styleclass} rounded-xl font-semibold text-center w-full text-xs md:text-sm px-1`}>
      {t(`kycstatus.${showStatus}`)}
    </div>
  )
}

export default ViewKycStatus