import { gql } from '@apollo/client'


const FETCH_CHAT_LIST_MESSAGE = gql`
  query ChatListMessage($userDestinyId: String!,$userOrigenId: String!) {
    chatListMessage(userDestinyId: $userDestinyId, userOrigenId: $userOrigenId) {
      id
      message
      seen
      status
      createdAt
      updatedAt
      userDestiny {
        id
        fullname
        email
      }
      userDestinyId
      userOrigen {
        id
      }
      userOrigenId
    }
  }
`

export default FETCH_CHAT_LIST_MESSAGE