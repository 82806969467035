import Navbar from "../components/Landing/Navbar"
import { Fragment } from "react"
import { useParams } from "react-router-dom"
import AcademyDetail from "src/components/Academy/AcademyDetail"

const AcademyHomeDetailPage:React.FC = () => {
  const { id } = useParams()

  return (
    <Fragment>
      <Navbar />
      <div className="w-full" style={{ background: "linear-gradient(45deg, white,#f2f2f2)" }} >
        <section className="pt-28 pb-28 mx-auto w-11/12 md:w-9/12 mx-auto">
          {id &&
            <AcademyDetail id={id} type={'home'} />
          }
        </section>
      </div>
    </Fragment>
  )
}
export default AcademyHomeDetailPage